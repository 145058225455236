import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewConsultationSuccessDialogComponent } from './new-consultation-success-dialog.component';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    IonicModule,
    MatDialogModule
  ],
  declarations: [NewConsultationSuccessDialogComponent],
  exports: [NewConsultationSuccessDialogComponent]
})
export class NewConsultationSuccessDialogModule {}
