import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatDialogModule } from '@angular/material/dialog';
import { IonicModule } from '@ionic/angular';
import { DynamicIconDirectiveModule } from '@nai-libs/directives/dynamic-icon';
import { DialogSuccessComponent } from './dialog-success.component';

@NgModule({
  imports: [
    CommonModule,
    DynamicIconDirectiveModule,
    MatDialogModule,
    IonicModule,
  ],
  declarations: [DialogSuccessComponent],
  exports: [DialogSuccessComponent],
})
export class DialogSuccessModule {}
