import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, filter, first, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { UserActions, UserSelectors, UserService } from '@nai-libs/user/data-access';
import { User } from '@nai-libs/data-access';

@Injectable({ providedIn: 'root' })
export class LegalGuard implements CanActivate {
  userLoaded$ = this.store.select(UserSelectors.isUserLoaded);

  constructor(
    private store: Store,
    private router: Router,
    private userService: UserService
  ) {}

  canActivate() {

    return this.userService.getToken().pipe(
      switchMap(token => {
        if (token) {
          return this.userLoaded$.pipe(
            tap((loaded) => {
              if (!loaded) {
                this.store.dispatch(UserActions.loadUserData({ isLogin: false }));
              }
            }),
            filter((loaded) => !!loaded),
            mergeMap(() => this.store.select(UserSelectors.selectUser)),
            map((user: User | undefined) => {
              if (user && user['valid-token?']) {
                return true;
              }
              this.userService.logout();
              return this.router.createUrlTree(['/auth/login']);
            }),
            first()
          );
        }
        this.userService.logout();
        return of(this.router.createUrlTree(['/auth/login']));
      }),
      catchError(() => {
        return of(this.router.createUrlTree(['/auth/login']));
      })
    )
  }
}
