import {
  ArticleResource,
  FormativeArea,
  FormativeArticle,
  GlossaryWord,
  SpecificArticle,
  SpecificFormativeArea,
} from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

export const loadFormativeArticles = createAction(
  '[Article] Load formative articles'
);
export const loadFormativeArticlesSuccess = createAction(
  '[Article] Load formative articles success',
  props<{ articles: FormativeArticle[] }>()
);
export const loadFormativeArticlesFailure = createAction(
  '[Article] Load formative articles error'
);

export const loadFormativeAreas = createAction(
  '[Article] Load formative areas',
  props<{ forceFetch?: boolean }>()
);
export const loadFormativeAreasSuccess = createAction(
  '[Article] Load formative areas success',
  props<{ formativeAreas: FormativeArea[] }>()
);
export const loadFormativeAreasFailure = createAction(
  '[Article] Load formative areas error',
  props<{ error: string }>()
);

export const loadArea = createAction(
  '[Article] Load Area',
  props<{ id: string }>()
);
export const loadAreaSuccess = createAction(
  '[Article] Load Area Success',
  props<{ area: SpecificFormativeArea }>()
);
export const loadAreaFailure = createAction('[Article] Load Area Failure');

export const loadArticle = createAction(
  '[Article] Load Article',
  props<{ id: string; area: string }>()
);
export const loadArticleSuccess = createAction(
  '[Article] Load Article Success',
  props<{ article: SpecificArticle }>()
);
export const loadArticleFailure = createAction(
  '[Article] Load Article Failure'
);

export const loadArticleResources = createAction(
  '[Article] Load Article resources',
  props<{ id: string; area: string }>()
);
export const loadArticleResourcesSuccess = createAction(
  '[Article] Load Article resources Success',
  props<{ resources: ArticleResource[] }>()
);
export const loadArticleResourcesFailure = createAction(
  '[Article] Load Article resources Failure'
);

export const loadGlosaryWord = createAction(
  '[Article] Load glossary word',
  props<{ NANDACode: string; articleCode: string; word: string }>()
);
export const loadGlosaryWordSuccess = createAction(
  '[Article] Load glossary word Success',
  props<{ glossaryWord: GlossaryWord }>()
);
export const loadGlosaryWordFailure = createAction(
  '[Article] Load glossary word Failure'
);

export const markArticleAsRead = createAction(
  '[Article] Mark article as read',
  props<{ NANDACode: string; articleCode: string }>()
);
export const markArticleAsReadSuccess = createAction(
  '[Article] Mark article as read Success'
);
export const markArticleAsReadFailure = createAction(
  '[Article] Mark article as read Failure',
  props<{ error: string }>()
);
