import { Token } from '@capacitor/push-notifications';
import { createAction, props } from '@ngrx/store';

export const requestPermission = createAction(
  '[Push Notifications] Request Permission'
);
export const requestPermissionSuccess = createAction(
  '[Push Notifications] Request Permission Success'
);
export const requestPermissionRejected = createAction(
  '[Push Notifications] Request Permission Rejected'
);

export const registerToken = createAction(
  '[Push Notifications] Register Token',
  props<{ token: Token }>()
);
export const registerTokenSuccess = createAction(
  '[Push Notifications] Register Token Success',
  props<{ token: Token }>()
);

export const registerTokenFailure = createAction(
  '[Push Notifications] Register Token Failure'
);

export const skipTokenRegistration = createAction(
  '[Push Notifications] Skip Token Registration (Web)'
);
