import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appointmentReducer, APPOINTMENT_REDUCER_KEY } from './+state/appointment.reducer';
import { AppointmentEffects } from './+state/appointment.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(APPOINTMENT_REDUCER_KEY, appointmentReducer),
    EffectsModule.forFeature([AppointmentEffects]),
  ]
})
export class AppointmentDataAccessModule {}
