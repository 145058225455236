import { Injectable } from '@angular/core';
import { TaskMap, TaskStatsItem } from '@nai-libs/data-access';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import * as TaskActions from './task.actions';
import { TaskService } from './task.service';

import { TaskCompletedDialogComponent } from '@nai-libs/standalone/task-completed-dialog-component';

import { MatDialog } from '@angular/material/dialog';
import {
  DateUtilsService,
  TaskUtilsService,
  TimeslotService,
} from '@nai-libs/utility';
import * as TaskSelectors from './task.selectors';

@Injectable()
export class TaskEffects {
  constructor(
    private actions$: Actions,
    private taskService: TaskService,
    private taskUtils: TaskUtilsService,
    private timeslotUtils: TimeslotService,
    private store: Store,
    private dateUtils: DateUtilsService,
    private dialog: MatDialog
  ) {}

  // Tasks
  loadDailyTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.loadDailyTasks),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { date, checkTaskCompletion },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser)
            return of(UserActions.logout());
          return this.taskService
            .getDailyTasks(date, user, serviceReceiver, selectedUser)
            .pipe(
              map((tasks: TaskMap) =>
                TaskActions.loadDailyTasksSuccess({
                  tasks,
                  checkTaskCompletion,
                })
              ),
              catchError(() => of(TaskActions.loadDailyTasksFailure()))
            );
        }
      )
    )
  );

  loadDailyTasksSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaskActions.loadDailyTasksSuccess),
        tap(({ tasks, checkTaskCompletion }) => {
          if (checkTaskCompletion) {
            const taskList = this.timeslotUtils.taskMapToTasks(tasks);
            if (
              this.taskUtils.filterCompletedTasks(taskList).length ==
              taskList.length
            ) {
              this.dialog.open(TaskCompletedDialogComponent, {
                maxWidth: 'var(--dialog-max-width)',
                width: 'var(--dialog-width)',
                maxHeight: 'var(--dialog-height)',
                panelClass: ['dialog-panel'],
              });
            }
          }
        })
      ),
    { dispatch: false }
  );

  loadMonthlyTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.loadMonthlyTasks),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectUser),
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(TaskSelectors.selectAllTasks)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(
        ([
          { date, forceFetch },
          user,
          serviceReceiver,
          selectedUser,
          tasks,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser)
            return of(UserActions.logout());
          if (!forceFetch && tasks) {
            if (
              tasks?.[serviceReceiver['client-phone']].findIndex((taskMap) => {
                return (
                  taskMap.date.date ===
                  this.dateUtils.dateToYearMonthDayHyphen(date)
                );
              }) !== -1
            ) {
              return of(TaskActions.loadMonthlyTasksAlreadyFetched());
            }
          }
          return this.taskService
            .getMonthlyTasks(date, user, serviceReceiver, selectedUser)
            .pipe(
              map((tasks: TaskMap[]) =>
                TaskActions.loadMonthlyTasksSuccess({ tasks })
              ),
              catchError(() => of(TaskActions.loadMonthlyTasksFailure()))
            );
        }
      )
    )
  );

  loadTaskStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.loadTaskStats),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { date, range, carerEmail, forceFetch },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser)
            return of(UserActions.logout());
          return this.taskService
            .fetchTaskStats(
              date,
              range,
              carerEmail ? carerEmail : user['logged-user']['e-mail'],
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((tasksStats: TaskStatsItem[]) =>
                TaskActions.loadTaskStatsSuccess({ tasksStats, range })
              ),
              catchError((error) => {
                console.error(error);
                return of(TaskActions.loadTaskStatsFailure());
              })
            );
        }
      )
    )
  );

  loadThreeMonthTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.loadThreeMonthTasks),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());

        const current = new Date();
        current.setDate(1); // Avoid wrong dates in last month days
        const past = new Date(current);
        past.setMonth(past.getMonth() - 1);
        const next = new Date(current);
        next.setMonth(next.getMonth() + 1);

        return forkJoin([
          this.taskService.getMonthlyTasks(
            current,
            user,
            serviceReceiver,
            selectedUser
          ),
          this.taskService.getMonthlyTasks(
            past,
            user,
            serviceReceiver,
            selectedUser
          ),
          this.taskService.getMonthlyTasks(
            next,
            user,
            serviceReceiver,
            selectedUser
          ),
        ]).pipe(
          map((tasks: TaskMap[][]) =>
            TaskActions.loadThreeMonthTasksSuccess({
              tasks: tasks.reduce((acc, current) => acc.concat(current)),
            })
          ),
          catchError(() => of(TaskActions.loadThreeMonthTasksFailure()))
        );
      })
    )
  );

  markTaskAsDone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.markTaskAsDone),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { id, code, done, moment, hour },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser)
            return of(UserActions.logout());

          return this.taskService
            .markTaskAsDone(
              id,
              code,
              done,
              user,
              serviceReceiver,
              selectedUser,
              moment,
              hour
            )
            .pipe(
              map((_) => TaskActions.markTaskAsDoneSuccess({ id })),
              catchError(() => of(TaskActions.markTaskAsDoneFailure()))
            );
        }
      )
    )
  );

  markTaskADoneSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.markTaskAsDoneSuccess),
      map(({ id }) =>
        TaskActions.loadDailyTasks({
          date: new Date(id.split('_')[0]),
          checkTaskCompletion: true,
        })
      )
    )
  );

  loadTechnicalSheets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.loadTechnicalSheets),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ activityCode }, sr, selectedUser]) => {
        if (!sr || !selectedUser) return of(UserActions.logout());
        return this.taskService
          .loadTechnicalSheets(activityCode, sr, selectedUser)
          .pipe(
            map((technicalSheetByActivity) =>
              TaskActions.loadTechnicalSheetsSuccess({
                technicalSheetByActivity,
              })
            ),
            catchError(() => of(TaskActions.loadTechnicalSheetsFailure()))
          );
      })
    )
  );

  // TODO
  loadTaskFormNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.loadTaskFormNotification),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }

        return this.taskService
          .fetchTaskFormNotification(user, serviceReceiver, selectedUser)
          .pipe(
            map((taskFormNotification) =>
              TaskActions.loadTaskFormNotificationSuccess({
                taskFormNotification,
              })
            ),
            catchError(() => of(TaskActions.loadTaskFormNotificationFailure()))
          );
      })
    )
  );

  // TODO
  saveReadTaskFormNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.saveReadTaskFormNotification),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ date }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }

        return this.taskService
          .saveReadTaskFormNotification(
            user,
            serviceReceiver,
            selectedUser,
            date
          )
          .pipe(
            map((_) => TaskActions.saveReadTaskFormNotificationSuccess()),
            catchError(() =>
              of(TaskActions.saveReadTaskFormNotificationFailure())
            )
          );
      })
    )
  );
}
