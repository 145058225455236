import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { Http, HttpDownloadFileOptions, HttpDownloadFileResult } from '@capacitor-community/http';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig } from '@nai-libs/data-access';
import { catchError, delay, from, map, Observable, of, switchMap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}


  getFileByLink(link: string): Observable<Blob> {
    const url = this.getFileUrl(link); 
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }

  getFileName(path: string) {
    return path.replace(/^.*[\\\/]/, '');
  }

  getFileUrl(link: string) {
    if(link.indexOf('https://') !== -1 || link.indexOf('http://') !== -1) {
      return link;
    }
    else {
      return `${this.env.baseUrl}${link}`;
    }
  }

  openFile(link: string, name?: string): Observable<void> {
    if(Capacitor.getPlatform() === 'web') {
      window.open(`${this.getFileUrl(link)}`, '_blank');
      return of(undefined);
    }
    else {
      return this.openFileApp(link, name);
    }
  }

  downloadFile(link: string, name?: string): Observable<void> {
    if(Capacitor.getPlatform() === 'web') {
      return this.getFileByLink(link).pipe(
        map((blob: Blob) => {
          this.downloadFileBrowser(blob, link);
          return;
        })
      )
    }
    else {
      return this.openFileApp(link, name);
    }
  }

  downloadFileBrowser(file: Blob, link: string) {
    const linkEl = document.createElement('a');
    linkEl.href = window.URL.createObjectURL(file);
    linkEl.download = link.split('/').pop() || 'dgi.pdf';
    document.body.appendChild(linkEl);
    linkEl.click();
    document.body.removeChild(linkEl);
  }

  openFileApp(link: string, name?: string) {
    const url = this.getFileUrl(link);
    const filePath = name ?? this.getFileName(url);
    const fileOptions: HttpDownloadFileOptions = {
      url,
      filePath,
      fileDirectory: Directory.Data,
      method: 'GET'
    } as HttpDownloadFileOptions;

    

    const downloadAndOpen = () => from(Http.downloadFile(fileOptions)).pipe(
      switchMap((r: HttpDownloadFileResult) => {
        if(!!r.path) {
          return from(FileOpener.open({ filePath: `${r.path}`, contentType: 'application/pdf' }))
        }
        return throwError(() => ({ downloadResponse: r, fileOptions }))
      })
    )

    return from(Filesystem.deleteFile({ path: filePath, directory: Directory.Data })).pipe(
      switchMap(() => downloadAndOpen()),
      catchError(() => downloadAndOpen())
    )
  }
}
