import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotificationEffects } from './+state/notification.effects';
import {
  NOTIFICATION_REDUCER_KEY,
  notificationReducer,
} from './+state/notification.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(NOTIFICATION_REDUCER_KEY, notificationReducer),
    EffectsModule.forFeature([NotificationEffects]),
  ],
})
export class NotificationDataAccessModule {}
