import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  Caregiver,
  CaregiverResponse,
  SelectedUser,
} from '@nai-libs/data-access';

import { ServiceReceiver } from '@nai-libs/data-access';
import { catchError, map, Observable, throwError } from 'rxjs';

import { Professional, ProfessionalResponse } from '@nai-libs/data-access';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalService {
  constructor(
    @Inject(APP_CONFIG) private env: AppConfig,
    private http: HttpClient
  ) {}

  fetchProfessionals(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Professional[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };
    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<ProfessionalResponse>(
        `${this.env.apiUrl}services/fetch-all-service-givers?${params}`
      )
      .pipe(
        map((res) => (res['service-givers'] ? res['service-givers'] : [])),
        catchError((err) => throwError(() => err))
      );
  }

  fetchCaregivers(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Caregiver[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };
    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<CaregiverResponse>(
        `${this.env.apiUrl}services/fetch-all-formal-caregivers?${params}`
      )
      .pipe(
        map((res) =>
          res['formal-caregivers']
            ? res['formal-caregivers'].map((caregiver) => ({
                ...caregiver,
                identifier: caregiver.indentifier,
              }))
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }
}
