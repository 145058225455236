/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { USER_REDUCER_KEY, userReducer } from './+state/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './+state/user.effects';
import { AlertFeatureModule } from '@nai-libs/alert/features/alert-feature';
import { PasswordRecoverySuccessPageModule } from '@nai-libs/standalone/password-recovery-success-page'
import { PasswordResetSuccessPageModule } from '@nai-libs/standalone/password-reset-success-page';
import { PasswordChangeSuccessPageModule } from '@nai-libs/user/features/change-password-feature/src/lib/standalone/password-change-success-page/password-change-success-page.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USER_REDUCER_KEY, userReducer),
    EffectsModule.forFeature([UserEffects]),
    AlertFeatureModule,
    PasswordRecoverySuccessPageModule,
    PasswordResetSuccessPageModule,
    PasswordChangeSuccessPageModule,
    HttpClientModule,
  ]
})
export class UserDataAccessModule {}
