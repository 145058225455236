import { AppConfig } from '@nai-libs/shared/data-access/src';

export const environment: AppConfig = {
  production: false,
  appName: 'etxetic',
  domain: 'undefined',
  baseUrl: 'http://naiha_test.barritic.eus:3005',
  apiUrl: 'http://naiha_test.barritic.eus:3005/api-nf/',
  availableLanguages: ['es','eu',],
  defaultLanguage: 'es',
  features: {
    passwordRecovery: true,
    changePassword: true,
    changeLanguage: false,
    faq: true,
    videoconsultations: true,
  },
};

