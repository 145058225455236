import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AppConfig,
  MarketplaceProduct,
  MarketplaceProductsResult
} from '@nai-libs/data-access';
import { catchError, map, Observable, throwError } from 'rxjs';

import { APP_CONFIG } from '@nai-libs/app-config';
import { DateUtilsService } from '@nai-libs/shared/utility/src';

@Injectable({ providedIn: 'root' })
export class MarketplaceService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig,
    private dateUtils: DateUtilsService
  ) {}

  fetchProducts(): Observable<MarketplaceProduct[]> {
    const paramsObj = {
      date: this.dateUtils.dateToYearMonthDayHyphen(new Date()),
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<MarketplaceProductsResult>(
        `${this.env.apiUrl}services/marketplace/product-list?${params}`
      )
      .pipe(
        map((response) => response['result']),
        catchError((err) => throwError(() => err))
      );
  }

  fetchProduct(id: string): Observable<MarketplaceProduct> {
    const paramsObj = {
      id,
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<MarketplaceProduct>(
        `${this.env.apiUrl}services/marketplace/product-info?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }
}
