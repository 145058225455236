import {
    createReducer,
    on,
  } from '@ngrx/store';
  import { ArticleResource, FormativeArea, GlossaryWord, RequestStatus, SpecificArticle, SpecificFormativeArea } from '@nai-libs/data-access';
  import * as ArticleActions from './article.actions';
  import { FormativeArticle } from '@nai-libs/data-access';
  
  export const ARTICLE_REDUCER_KEY = 'article';
  
  export interface ArticleState {
    articles?: FormativeArticle[];
    articlesStatus: RequestStatus;
    formativeAreas?: FormativeArea[];
    formativeAreasStatus: RequestStatus;
    selectedArea?: SpecificFormativeArea;
    areaStatus: RequestStatus;
    selectedArticle?: SpecificArticle;
    articleStatus: RequestStatus;
    resources?: ArticleResource[];
    resourcesStatus: RequestStatus;
    glossaryWord?: GlossaryWord;
    glossaryWordStatus: RequestStatus;
    markArticleAsReadStatus: RequestStatus;
  }
  
  const initialState: ArticleState = {
    articlesStatus: { pending: false },
    formativeAreasStatus: { pending: false },
    areaStatus: { pending: false },
    articleStatus: { pending: false },
    resourcesStatus: { pending: false },
    glossaryWordStatus: { pending: false },
    markArticleAsReadStatus: { pending: false }
  };
  
  export const articleReducer = createReducer(
    initialState,
    on(ArticleActions.loadFormativeArticles, (state) => ({
      ...state,
      articlesStatus: { pending: true },
    })),
    on(ArticleActions.loadFormativeArticlesSuccess, (state, { articles }) => ({
      ...state,
      articles,
      articlesStatus: { pending: false },
    })),
    on(ArticleActions.loadFormativeArticlesFailure, (state) => ({
      ...state,
      articles: undefined,
      articlesStatus: { pending: false },
    })),
    on(ArticleActions.loadFormativeAreas, (state) => ({
      ...state,
      formativeAreasStatus: { pending: true },
    })),
    on(ArticleActions.loadFormativeAreasSuccess, (state, { formativeAreas }) => ({
      ...state,
      formativeAreas,
      formativeAreasStatus: { pending: false },
    })),
    on(ArticleActions.loadFormativeAreasFailure, (state) => ({
      ...state,
      articles: undefined,
      formativeAreasStatus: { pending: false },
    })),
    on(ArticleActions.loadArea, state => ({ ...state, selectedArea: undefined, areaStatus: { pending: true } })),
    on(ArticleActions.loadAreaSuccess, (state, { area }) => ({ ...state, selectedArea: area, areaStatus: { pending: false } })),
    on(ArticleActions.loadAreaFailure, (state) => ({ ...state, selectedArea: undefined, areaStatus: { pending: false } })),
  
    on(ArticleActions.loadArticle, state => ({ ...state, selectedArticle: undefined, articleStatus: { pending: true } })),
    on(ArticleActions.loadArticleSuccess, (state, { article }) => ({ ...state, selectedArticle: article, articleStatus: { pending: false } })),
    on(ArticleActions.loadArticleFailure, (state) => ({ ...state, selectedArticle: undefined, articleStatus: { pending: false } })),
  
    on(ArticleActions.loadArticleResources, state => ({ ...state, resources: undefined, resourcesStatus: { pending: true } })),
    on(ArticleActions.loadArticleResourcesSuccess, (state, { resources }) => ({ ...state, resources: resources, resourcesStatus: { pending: false } })),
    on(ArticleActions.loadArticleResourcesFailure, (state) => ({ ...state, resources: undefined, resourcesStatus: { pending: false } })),
  
    on(ArticleActions.loadGlosaryWord, state => ({ ...state, glossaryWord: undefined, glossaryWordStatus: { pending: true } })),
    on(ArticleActions.loadGlosaryWordSuccess, (state, { glossaryWord }) => ({ ...state, glossaryWord, glossaryWordStatus: { pending: false } })),
    on(ArticleActions.loadGlosaryWordFailure, (state) => ({ ...state, glossaryWord: undefined, glossaryWordStatus: { pending: false } })),
  
    on(ArticleActions.markArticleAsRead, state => ({ ...state, markArticleAsReadStatus: { pending: true } })),
    on(ArticleActions.markArticleAsReadSuccess, (state) => ({ ...state, markArticleAsReadStatus: { pending: false } })),
    on(ArticleActions.markArticleAsReadFailure, (state, error) => ({ ...state, markArticleAsReadStatus: { pending: false, error } })),
  );
  
