import { Form, FormStatus, FormSubmissionAnswer, FormSummary } from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

export const loadForm = createAction(
  '[Form] Load form',
  props<{ id: string }>()
);
export const loadFormSuccess = createAction(
  '[Form] Load form success',
  props<{ form: Form }>()
);
export const loadFormFailure = createAction('[Form] Load form failure');

export const saveForm = createAction(
  '[Form] Save Form',
  props<{
    id: string;
    formAnswers: FormSubmissionAnswer[];
    redirection: string;
  }>()
);
export const saveFormSuccess = createAction(
  '[Form] Save form success',
  props<{ redirection: string }>()
);
export const saveFormFailure = createAction('[Form] Save form failure');

export const loadFormSummary = createAction(
  '[Form] Load form summary',
  props<{ id: string }>()
);
export const loadFormSummarySuccess = createAction(
  '[Form] Load form summary success',
  props<{ form: FormSummary }>()
);
export const loadFormSummaryFailure = createAction(
  '[Form] Load form summary failure'
);


export const loadFormStatus = createAction(
  '[Form] Load form status',
  props<{ id: string }>()
);
export const loadFormStatusSuccess = createAction(
  '[Form] Load form status success',
  props<{ status: FormStatus }>()
);
export const loadFormStatusFailure = createAction(
  '[Form] Load form status failure'
);
