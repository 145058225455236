import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig } from '@nai-libs/shared/data-access/src';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';

@Component({
  selector: 'nai-new-password-page',
  template: `
    <div class="page page-change-pass">
      <div class="wrapper-fluid wrapper-fluid--extra-narrow">
        <figure class="login-brand">
          <img [src]="'app-assets/images/logo-login.svg'" />
        </figure>
        <header class="header-page">
          <h2 class="font-headline-5">Introduce tu nueva contraseña</h2>
        </header>
        <nai-new-password-form
          [newPasswordError]="(newPasswordError$ | async)?.text"
          [newPasswordPending]="newPasswordPending$ | async"
          (formSubmit)="newPassword($event)"
        ></nai-new-password-form>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./new-password-page.component.scss'],
})
export class NewPasswordPageComponent {
  newPasswordPending$ = this.store.select(
    UserSelectors.selectNewPasswordPending
  );
  newPasswordError$ = this.store.select(UserSelectors.selectNewPasswordError);

  constructor(
    private store: Store,
    private router: Router,
    @Inject(APP_CONFIG) public env: AppConfig
  ) {}

  newPassword(password: string) {
    const urlParams = window.location.href
      .split('/')
      .pop()
      ?.split('?')
      .pop()
      ?.split('&');

    if (urlParams?.length === 2) {
      const email = urlParams[0].split('=').pop() || '';
      const tempPassword = urlParams?.pop()?.split('=').pop() || '';
      if (email && tempPassword) {
        this.store.dispatch(
          UserActions.saveNewPassword({
            email,
            password: password,
            tempPassword,
          })
        );
      }
    }
  }

  handleBack() {
    this.router.navigate(['auth/login']);
  }
}
