import { Inject } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, TranslationSelector } from '@nai-libs/data-access';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, from, Observable } from 'rxjs';

export class CustomTranslateLoader implements TranslateLoader {
  constructor(@Inject(APP_CONFIG) private env: AppConfig) {}

  getTranslation(selector: TranslationSelector): Observable<any> {
    return from(
      import(
        `apps/${this.env.appName}/src/app-assets/translations/${selector}.json`
      )
    ).pipe(
      catchError(() => {
        return from(
          import(
            `apps/${this.env.appName}/src/app-assets/translations/${selector}.json`
          )
        );
      })
    );
  }
}
