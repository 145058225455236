import { Caregiver, Professional } from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

export const loadProfessionals = createAction(
  '[Professional] Load Professionals'
);
export const loadProfessionalsSuccess = createAction(
  '[Professional] Load Professionals Success',
  props<{ professionals: Professional[] }>()
);
export const loadProfessionalsFailure = createAction(
  '[Professional] Load Professionals Failure',
  props<{ error: string }>()
);

export const loadCaregivers = createAction('[Professional] Load Caregivers');
export const loadCaregiversSuccess = createAction(
  '[Professional] Load Caregivers Success',
  props<{ caregivers: Caregiver[] }>()
);
export const loadCaregiversFailure = createAction(
  '[Professional] Load Caregivers Failure',
  props<{ error: string }>()
);
