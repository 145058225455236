import { Intervention, InterventionStatus } from "@nai-libs/shared/data-access/src";
import { createAction, props } from "@ngrx/store";

export const loadActiveIntervention = createAction('[ClockIn] Load active intervention');
export const loadActiveInterventionSuccess = createAction('[ClockIn] Load active intervention Success', props<{ intervention?: Intervention, status?: InterventionStatus }>());
export const loadActiveInterventionFailure = createAction('[ClockIn] Load active intervention Failure');

export const sendInterventionClockIn = createAction('[ClockIn] Send intervention clock in', props<{ NFC_id?: string }>());
export const sendInterventionClockInSuccess = createAction('[ClockIn] Send intervention clock in Success');
export const sendInterventionClockInFailure = createAction('[ClockIn] Send intervention clock in Failure');

export const sendInterventionClockOut = createAction('[ClockIn] Send intervention clock out', props<{ NFC_id?: string }>());
export const sendInterventionClockOutSuccess = createAction('[ClockIn] Send intervention clock out Success');
export const sendInterventionClockOutFailure = createAction('[ClockIn] Send intervention clock out Failure');