/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '@nai-libs/shared/utility/src';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private userService: UserService, private storage: StorageService) {}

  needsToken(req: HttpRequest<any>): boolean {
    const reqWithoutToken = ['login-nf'];
    return reqWithoutToken.findIndex((r) => req.url.includes(r)) === -1;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const token: string | undefined = this.user.getToken();

    if (this.needsToken(req)) {
      return this.storage.getItem('auth_token').pipe(
        switchMap((token) => {
          return next
            .handle(
              req.clone({
                setHeaders: {
                  authorization: `Token ${token}`,
                  'Content-Type': 'application/json',
                },
              })
            )
            .pipe(
              catchError((err) => {
                if (!token) this.userService.logout();
                return throwError(() => err);
              })
            );
        }),
        catchError((err) => {
          return throwError(() => err);
        })
      );
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.userService.logout();
        }
        return throwError(() => err);
      })
    );
  }
}
