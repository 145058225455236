import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { MembersService } from './members.service';
import { of } from 'rxjs';
import * as MembersActions from '../+state/members.actions';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access/src';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { CreateMemberSuccessDialogComponent } from '@nai-libs/standalone/create-member-success-dialog-component';
import { DeleteMemberSuccessDialogComponent } from '@nai-libs/standalone/delete-member-success-dialog-component';

import { AlertService } from '@nai-libs/alert/features/alert-feature/src';
@Injectable()
export class MembersEffects {
  constructor(
    private actions$: Actions,
    private membersService: MembersService,
    private store: Store,
    private dialog: MatDialog,
    private alertService: AlertService
  ) {}

  loadMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembersActions.loadMembers),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        return this.membersService
          .getFamiliarMembers(serviceReceiver, selectedUser)
          .pipe(
            map((members) => MembersActions.loadMembersSuccess({ members })),
            catchError(() => of(MembersActions.loadMembersFailure()))
          );
      })
    )
  );

  createMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembersActions.createMember),
      withLatestFrom(this.store.select(UserSelectors.selectServiceReceiver)),
      switchMap(([{ member }, serviceReceiver]) => {
        if (!serviceReceiver) return of(UserActions.logout());
        return this.membersService.createMember(serviceReceiver, member).pipe(
          map((_) => MembersActions.createMemberSuccess({ member })),
          catchError((error) => {
            return of(MembersActions.createMemberFailure({ error }));
          })
        );
      })
    )
  );

  createMemberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembersActions.createMemberSuccess),
      map(({ member }) => {
        this.dialog.open(CreateMemberSuccessDialogComponent, {
          data: { member },
          panelClass: 'dialog-panel',
        });
        return MembersActions.loadMembers();
      })
    )
  );

  createMemberFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembersActions.createMemberFailure),
        tap(({ error }) => {
          this.alertService.showAlert('error', error, 10000);
        })
      ),
    { dispatch: false }
  );

  deleteMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembersActions.deleteMember),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ member }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        return this.membersService.deleteMember(serviceReceiver, member).pipe(
          map((_) => MembersActions.deleteMemberSuccess({ member })),
          catchError((error) =>
            of(MembersActions.deleteMemberFailure({ error }))
          )
        );
      })
    )
  );

  deleteMemberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MembersActions.deleteMemberSuccess),
      map(({ member }) => {
        this.dialog.open(DeleteMemberSuccessDialogComponent, {
          data: { member },
          panelClass: 'dialog-panel',
        });
        return MembersActions.loadMembers();
      })
    )
  );

  deleteMemberFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembersActions.deleteMemberFailure),
        tap(({ error }) => {
          this.alertService.showAlert('error', error, 10000);
        })
      ),
    { dispatch: false }
  );
}
