import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PasswordChangeSuccessPageComponent } from './password-change-success-page.component';
import { DynamicIconDirectiveModule } from '@nai-libs/directives/dynamic-icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, RouterModule, DynamicIconDirectiveModule, MatDialogModule],
  declarations: [PasswordChangeSuccessPageComponent]
})
export class PasswordChangeSuccessPageModule {}
