import { ChangeDetectionStrategy, Component } from '@angular/core';
@Component({
  selector: 'nai-password-recovery-success',
  template: `
    <div class="page">
      <div class="wrapper-fluid">
        <span
          naiDynIcon
          [overrideColor]="false"
          [path]="'assets/icons/ok-check.svg'"
        ></span>
        <h1>¡Genial!</h1>
        <p>
          Hemos enviado un email para recuperar tu contraseña. Revisa tu bandeja
          de entrada y sigue las instrucciones.
        </p>
        <a
          [routerLink]="['/auth/login']"
          [mat-dialog-close]
          class="button button--outline button--primary"
          >Volver a iniciar sesión</a
        >
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./password-recovery-success-page.component.scss'],
})
export class PasswordRecoverySuccessPageComponent {}
