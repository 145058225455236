import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'nai-task-completed-dialog',
  template: `
    <img src="assets/images/congratulations-background.svg" />
    <div class="content">
      <button [mat-dialog-close] class="button button--icon-close">
        <span naiDynIcon [path]="'assets/icons/close.svg'"></span>
      </button>
      <mat-dialog-content>
        <header class="dialog-appointment-header">
          <h1 class="font-headline-4">
            {{ 'TASK.TASK_COMPLETED_DIALOG.TITLE' | translate }}
          </h1>
          <p class="font-body-1">
            {{ 'TASK.TASK_COMPLETED_DIALOG.DESCRIPTION' | translate }}
          </p>
        </header>
      </mat-dialog-content>
    </div>
  `,
  styleUrls: ['./task-completed-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCompletedDialogComponent {}
