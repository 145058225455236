import {
  Appointment,
  AppointmentsByMonth,
  RequestStatus,
} from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';
import * as AppointmentActions from './appointment.actions';

export const APPOINTMENT_REDUCER_KEY = 'appointment';

export interface AppointmentState {
  upcomingAppointments?: Appointment[];
  upcomingAppointmentsStatus: RequestStatus;

  appointmentsByMonth?: AppointmentsByMonth[];
  appointmentsByMonthStatus: RequestStatus;

  appointmentsByDayStatus: RequestStatus;

  threeMonthAppointmentsStatus: RequestStatus;

  videocalls?: Appointment[];
  videocallsStatus: RequestStatus;

  modifyAppointmentStatus: RequestStatus;
}

const initalState: AppointmentState = {
  upcomingAppointmentsStatus: { pending: false },
  threeMonthAppointmentsStatus: { pending: false },
  videocallsStatus: { pending: false },
  modifyAppointmentStatus: { pending: false },
  appointmentsByMonthStatus: { pending: false },
  appointmentsByDayStatus: { pending: false },
};

export const appointmentReducer = createReducer(
  initalState,
  //Videocalls
  on(AppointmentActions.loadVideocalls, (state) => ({
    ...state,
    videocallsStatus: { pending: true },
  })),
  on(AppointmentActions.loadVideocallsSuccess, (state, { videocalls }) => ({
    ...state,
    videocalls,
    videocallsStatus: { pending: false },
  })),
  on(AppointmentActions.loadVideocallsFailure, (state) => ({
    ...state,
    videocallsStatus: { pending: false },
  })),
  // Upcoming appointments
  on(AppointmentActions.loadUpcomingAppointments, (state) => ({
    ...state,
    upcomingAppointmentsStatus: { pending: true },
  })),
  on(
    AppointmentActions.loadUpcomingAppointmentsSuccess,
    (state, { appointments }) => ({
      ...state,
      upcomingAppointments: appointments,
      upcomingAppointmentsStatus: { pending: false },
    })
  ),
  on(AppointmentActions.loadUpcomingAppointmentsFailure, (state) => ({
    ...state,
    upcomingAppointments: undefined,
    upcomingAppointmentsStatus: { pending: false },
  })),

  // Three month appointments
  on(AppointmentActions.loadThreeMonthAppointments, (state) => ({
    ...state,
    threeMonthAppointmentsStatus: { pending: true },
  })),
  on(
    AppointmentActions.loadThreeMonthAppointmentsSuccess,
    (state, { appointmentsByMonth }) => ({
      ...state,
      appointmentsByMonth,
      threeMonthAppointmentsStatus: { pending: false },
    })
  ),
  on(AppointmentActions.loadThreeMonthAppointmentsFailure, (state) => ({
    ...state,
    threeMonthAppointmentsStatus: { pending: false },
  })),

  // One month appointments
  on(AppointmentActions.loadAppointmentsByMonth, (state) => ({
    ...state,
    appointmentsByMonthStatus: { pending: true },
  })),
  on(
    AppointmentActions.loadAppointmentsByMonthSuccess,
    (state, { appointmentsByMonth }) => {
      if (state.appointmentsByMonth) {
        const index = state.appointmentsByMonth.findIndex(
          (monthAppointments) =>
            monthAppointments.year === appointmentsByMonth.year &&
            monthAppointments.month === appointmentsByMonth.month
        );
        if (index !== -1) {
          const newAppointmentsByMonth = [...state.appointmentsByMonth].splice(
            index,
            1,
            appointmentsByMonth
          );
          return {
            ...state,
            appointmentsByMonth: newAppointmentsByMonth,
            appointmentsByMonthStatus: { pending: false },
          };
        }
      }
      return {
        ...state,
        appointmentsByMonth: [
          ...(state.appointmentsByMonth ?? []),
          appointmentsByMonth,
        ],
        appointmentsByMonthStatus: { pending: false },
      };
    }
  ),
  on(AppointmentActions.loadAppointmentsByMonthFailure, (state) => ({
    ...state,
    appointmentsByMonthStatus: { pending: false },
  })),

  //One day appointments
  on(AppointmentActions.loadAppointmentsByDay, (state) => ({
    ...state,
    appointmentsByDayStatus: { pending: true },
  })),
  on(
    AppointmentActions.loadAppointmentsByDaySuccess,
    (state, { appointments }) => {
      const newAppointments = [...(state.appointmentsByMonth ?? [])];
      if (appointments.length > 0) {
        const date = new Date(appointments[0].start);
        if (state.appointmentsByMonth) {
          const index = state.appointmentsByMonth.findIndex(
            (monthAppointments) =>
              monthAppointments.year === date.getFullYear().toString() &&
              monthAppointments.month === (date.getMonth() + 1).toString()
          );
          if (index !== -1) {
            const filteredAppointments = state.appointmentsByMonth[index][
              'scheduled-appointments'
            ].filter(
              (appointment) =>
                new Date(appointment.start).getDate() !== date.getDate()
            );

            newAppointments[index] = {
              ...newAppointments[index],
              'scheduled-appointments':
                filteredAppointments.concat(appointments),
            };
          }
        } else {
          newAppointments.push({
            year: date.getFullYear().toString(),
            month: date.getMonth().toString(),
            'scheduled-appointments': appointments,
          });
        }
      }
      return {
        ...state,
        appointmentsByMonth: newAppointments,
        appointmentsByDayStatus: { pending: false },
      };
    }
  ),
  on(AppointmentActions.loadAppointmentsByMonthFailure, (state) => ({
    ...state,
    appointmentsByMonthStatus: { pending: false },
  })),

  // Appointment attendance
  on(AppointmentActions.modifyAppointmentAttendance, (state) => ({
    ...state,
    modifyAppointmentStatus: { pending: true },
  })),
  on(AppointmentActions.modifyAppointmentAttendanceSuccess, (state) => ({
    ...state,
    modifyAppointmentStatus: { pending: false },
  })),
  on(
    AppointmentActions.modifyAppointmentAttendanceFailure,
    (state, { error }) => ({
      ...state,
      modifyAppointmentStatus: { pending: false, error },
    })
  )
);
