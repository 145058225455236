import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig } from '@nai-libs/data-access';

@Injectable({ providedIn: 'root' })
export class ReloadOnEnterService {
  prevSelectedUserId?: string;
  viewWillEnterTriggered = false;
  viewDidEnterTriggered = false;
  constructor(@Inject(APP_CONFIG) public env: AppConfig) {}
  //TODO: Aquí lo ideal sería pasarle un user-id, y chequear si ha habido cambio
  //para devolver TRUE solo en ese caso
  reloadOnWillEnter(selectedUserId?: string) {
    let reloadBrandSpecific = this.env.appName === 'etxetic';
    if (this.prevSelectedUserId && this.prevSelectedUserId === selectedUserId) {
      reloadBrandSpecific = false;
    }
    const reload = !this.viewWillEnterTriggered || reloadBrandSpecific;
    this.viewWillEnterTriggered = true;
    return reload;
  }
  reloadOnDidEnter(selectedUserId?: string) {
    let reloadBrandSpecific = this.env.appName === 'etxetic';
    if (this.prevSelectedUserId && this.prevSelectedUserId === selectedUserId) {
      reloadBrandSpecific = false;
    }
    const reload = !this.viewDidEnterTriggered || reloadBrandSpecific;
    this.viewDidEnterTriggered = true;
    return reload;
  }
}
