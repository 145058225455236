export const ALL_ALERT_TYPES = ['error', 'success', 'warning', 'info'];

export type AlertTypeTuple = typeof ALL_ALERT_TYPES;
export type AlertType = AlertTypeTuple[number];

export const AlertTypeIcons = {
  error: '/assets/icons/alert-error.svg',
  success: '/assets/icons/alert-success.svg',
  warning: '/assets/icons/alert-warning.svg',
  info: '/assets/icons/alert-info.svg',
};
