import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  DGIDocumentResponse,
  DGIReadResponse,
  DGIResponse,
  DGIResult,
  DGIResultResponse,
  LastDGIResponse,
  SelectedUser,
  ServiceReceiver,
  User,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DGIService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  /**
   * Método para obtener las fechas del último DGIs de un cliente (pantalla dashboard)
   * @returns Observable<string | undefined>
   */
  getDGI(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string | undefined> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<DGIResponse>(
        `${this.env.apiUrl}health/notifications/fetch-dgi?${params}`
      )
      .pipe(
        map((response) => {
          if (response.DGI && response.DGI.length > 0) {
            return response.DGI[0]['answers-date'];
          }
          return undefined;
        }),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Método para obtener las fechas del último DGIs de un cliente (apartado cuidados)
   * @returns Observable<string | undefined>
   */
  getLastDGI(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<LastDGIResponse>(`${this.env.apiUrl}health/fetch-last-dgi?${params}`)
      .pipe(
        map((response) => {
          return response['last-DGI-date'];
        }),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Método para obtener el link del PDF del DGI de un cliente en una fecha determinada
   * @param date
   * @returns Observable<string>
   */
  getDGILink(
    date: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'dgi-date': date,
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<DGIDocumentResponse>(
        `${this.env.apiUrl}documentation/fetch-dgi-pdf?${params}`
      )
      .pipe(
        map((response) => response['pdf-link']),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Método para descargar el PDF DGI de un cliente dado un link de descarga
   * @param link
   * @returns string
   */
  getDGIFile(link: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.env.baseUrl}${link}`, {
      responseType: 'blob' as 'json',
    });
  }

  /**
   * Método para marcar un DGI como leído
   * @returns Observable<boolean>
   */
  markDGIAsRead(
    dgiDate: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<boolean> {
    const body = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'dgi-date': dgiDate,
      'familiar-email': user['logged-user']['e-mail'],
    };

    return this.http
      .post<DGIReadResponse>(
        `${this.env.apiUrl}documentation/save-read-dgi-pdf`,
        body
      )
      .pipe(
        map((response) => response.status === 200),
        catchError((err) => throwError(() => err))
      );
  }

  getDGIResults(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    date: string
  ): Observable<DGIResult[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'dgi-date': date,
    };
    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<DGIResultResponse>(
        `${this.env.apiUrl}health/fetch-dgi-results?${params}`
      )
      .pipe(
        map((res) => {
          // Filtrar sin areas
          // Filtrar sin títulos
          // Filtrar sin slugs
          const DGIResults = res['DGI-results'].filter(
            (result) => result.title && result.slug && result.areas.length > 0
          );
          // Filtrar area sin nombre
          // Filtrar area sin problema
          for (let i = 0; i < DGIResults.length; i++) {
            DGIResults[i].areas = DGIResults[i].areas.filter((area) => {
              if (Array.isArray(area.problem)) {
                return area.area && area.problem && area.problem.length > 0;
              } else {
                return area.area && area.problem;
              }
            });

            for (let j = 0; j < DGIResults[i].areas.length; j++) {
              // Filtrar problema array nulo o vacío
              if (Array.isArray(DGIResults[i].areas[j].problem)) {
                DGIResults[i].areas[j].problem = (
                  DGIResults[i].areas[j].problem as string[]
                ).filter((problem) => problem);
              }

              // Filtrar sub cap sin nombre
              // Filtrar sub cap sin problema
              const subcaps = DGIResults[i].areas[j]['sub-caps'];
              if (subcaps && subcaps.length > 0) {
                DGIResults[i].areas[j]['sub-caps'] = subcaps.filter(
                  (subcap) => subcap.area && subcap.problem
                );
              }
            }
          }
          return DGIResults;
        }),
        catchError((err) => throwError(() => err))
      );
  }
}
