import { FamiliarMember, NewMember } from '@nai-libs/shared/data-access/src';
import { createAction, props } from '@ngrx/store';

export const loadMembers = createAction('[Members] Load members');

export const loadMembersSuccess = createAction(
  '[Members] Load members success',
  props<{ members: FamiliarMember[] }>()
);

export const loadMembersFailure = createAction('[Members] Load members error');

export const createMember = createAction('[Members] Create member', 
props<{ member: NewMember }>());

export const createMemberSuccess = createAction(
  '[Members] Create member success',
  props<{ member: NewMember }>()
)
export const createMemberFailure = createAction('[Members] Create member error',  props<{ error: string }>());

export const deleteMember = createAction('[Members] Delete member', 
props<{ member: FamiliarMember }>());

export const deleteMemberSuccess = createAction(
  '[Members] Delete member success',
  props<{ member: FamiliarMember }>()
)
export const deleteMemberFailure = createAction('[Members] Delete member error',  props<{ error: string }>());