import {
  Activity,
  Task,
  TaskStatsItem,
  TaskStatsRange,
} from '@nai-libs/data-access';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TASK_REDUCER_KEY, TaskState } from './task.reducer';

export const selectTaskState =
  createFeatureSelector<TaskState>(TASK_REDUCER_KEY);

export const selectAllTasks = createSelector(
  selectTaskState,
  (state) => state.tasks
);

export const selectTasks = (phone: string) =>
  createSelector(selectTaskState, (state) => state.tasks?.[phone]);

export const selectTodaysTasks = (phone: string) =>
  createSelector(selectTaskState, (state) =>
    state.tasks?.[phone]?.find(
      (taskMap) => taskMap.date.date === new Date().toISOString().split('T')[0]
    )
  );

export const selectDailyTasks = (phone: string, day: Date) =>
  createSelector(selectTaskState, (state) =>
    state.tasks?.[phone]?.find(
      (taskMap) => taskMap.date.date === day.toISOString().split('T')[0]
    )
  );

export const selectTask = (phone: string, taskId: string) =>
  createSelector(selectTaskState, (state) => {
    let selectedActivity: Task | undefined;
    state.tasks?.[phone]?.forEach((taskMap) =>
      taskMap['momentos-dia'].forEach((taskMoment) =>
        taskMoment.tareas.forEach((task) => {
          if (task.id === taskId) {
            selectedActivity = task;
          }
        })
      )
    );

    return selectedActivity;
  });

export const selectActivity = (phone: string, activityId: string) =>
  createSelector(selectTaskState, (state) => {
    let selectedActivity = undefined;
    state.tasks?.[phone]?.forEach((taskMap) =>
      taskMap['momentos-dia'].forEach((taskMoment) =>
        taskMoment.tareas.forEach((task) =>
          task.actividades.forEach((activity) => {
            if (activity['raw-id'] === activityId) {
              selectedActivity = activity;
            }
          })
        )
      )
    );
    return selectedActivity ? (selectedActivity as Activity) : undefined;
  });

export const selectTechnicalSheetsByActivity = (activityCode: string) =>
  createSelector(selectTaskState, (state) => {
    return state.technicalSheets?.[activityCode];
  });

export const selectDailyTasksStatusPending = createSelector(
  selectTaskState,
  (state) => state.dailyTasksStatus.pending
);

export const selectMonthlyTasksStatusPending = createSelector(
  selectTaskState,
  (state) => state.monthlyTasksStatus.pending
);

export const selectThreeMonthTasksStatusPending = createSelector(
  selectTaskState,
  (state) => state.threeMonthTasksStatus.pending
);

export const selectMarkTaskStatusPending = createSelector(
  selectTaskState,
  (state) => state.markTaskStatus.pending
);

// Select all task stats
export const selectTaskStats = (range: TaskStatsRange) =>
  createSelector(selectTaskState, (state): TaskStatsItem[] => {
    switch (range) {
      case 'week':
        return state.taskStatsWeek || [];
      case 'month':
        return state.taskStatsMonth || [];
      case 'day':
        return state.taskStatsDay || [];
      default:
        return state.taskStatsDay || [];
    }
  });
export const selectTaskStatsPending = createSelector(
  selectTaskState,
  (state) => state.tasksStatsStatus.pending
);
export const selectStatsRange = createSelector(
  selectTaskState,
  (state) => state.taskStatsSelectedRange
);

export const selectTaskFormNotification = createSelector(
  selectTaskState,
  (state) => state.taskFormNotification
);
