import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { DGI_REDUCER_KEY, dgiReducer } from './+state/dgi.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DGIEffects } from './+state/dgi.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(DGI_REDUCER_KEY, dgiReducer),
    EffectsModule.forFeature([DGIEffects]),
  ]
})
export class DgiDataAccessModule {}
