import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewMember } from '@nai-libs/shared/data-access/src';

@Component({
  selector: 'nai-create-member-success-dialog',
  template: `
    <button [mat-dialog-close] class="button button--icon-close">
      <span naiDynIcon [path]="'assets/icons/close.svg'"></span>
    </button>

    <div mat-dialog-content class="dialog-content">
      <figure>
        <img [src]="'assets/icons/ok-check.svg'" />
      </figure>
      <div class="text-wrapper">
        <h1 class="font-headline-3">
          <strong>
            {{ this.data.member['first-name'] }}
            {{ this.data.member['last-name'] }}</strong
          ><br>ha sido añadido/a como nuevo miembro
        </h1>
      </div>
    </div>
  `,
  styleUrls: ['./create-member-success-dialog.component.scss'],
})
export class CreateMemberSuccessDialogComponent {
  // TODO refactorizar dialogs BORRAR este
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      member: NewMember;
    }
  ) {}
}
