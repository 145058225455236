import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FORM_REDUCER_KEY, FormState } from './form.reducer';

export const selectFormState =
  createFeatureSelector<FormState>(FORM_REDUCER_KEY);

export const selectForm = (id: string) =>
  createSelector(selectFormState, (state) => {
    return state.forms?.find((form) => form.id == id);
  });

  // Informa sobre el estado de completitud del rosco
export const selectFormSummary = (id: string) =>
  createSelector(selectFormState, (state) => {
    return state.formSummaries?.find((form) => form.id == id);
  });

// Da info sobre el progreso del formulario
  export const selectFormStatus = (id: string) =>
  createSelector(selectFormState, (state) => {
    return state.formStatuses?.find((status) => status.id == id);
  });
