import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FORUM_REDUCER_KEY, ForumState } from './forum-surveys.reducer';

export const selectForumState =
  createFeatureSelector<ForumState>(FORUM_REDUCER_KEY);

export const selectSurveys = createSelector(
  selectForumState,
  (state) => state.surveys
);

export const selectSurveysStatusPending = createSelector(
  selectForumState,
  (state) => state.surveysStatus.pending
);

export const selectMarkAnswerStatusPending = createSelector(
  selectForumState,
  (state) => state.markAnswerStatus.pending
);

export const selectZaintzaQuiz = createSelector(
  selectForumState,
  (state) => state.zaintzaQuiz
);

export const selectZaintzaQuizStatusPending = createSelector(
  selectForumState,
  (state) => state.zaintzaQuizStatus.pending
);

export const selectMarkZaintzaQuizAnswerStatusPending = createSelector(
  selectForumState,
  (state) => state.markZaintzaQuizAnswerStatus.pending
);
