import {
  NotificationHistory,
  Notifications,
} from '@nai-libs/shared/data-access/src';

import { Notification } from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

export const loadNotifications = createAction(
  '[Notification] Load notifications'
);
export const loadNotificationsSuccess = createAction(
  '[Notification] Load notifications success',
  props<{ notifications: Notifications }>()
);
export const loadNotificationsFailure = createAction(
  '[Notification] Load notifications error'
);

export const saveReadNotification = createAction(
  '[Notification] Save read notification',
  props<{ id: string }>()
);
export const saveReadNotificationSuccess = createAction(
  '[Notification] Save read notification success'
);
export const saveReadNotificationFailure = createAction(
  '[Notification] Save read notification error'
);

export const loadNotificationHistory = createAction(
  '[Notification] Load notification history'
);
export const loadNotificationHistorySuccess = createAction(
  '[Notification] Load notification history success',
  props<{ notifications: NotificationHistory }>()
);
export const loadNotificationHistoryFailure = createAction(
  '[Notification] Load notification history error'
);

export const setNotificationsPageTypeFilter = createAction(
  '[Notification] Set notification page type filter',
  props<{ filter?: string }>()
);
export const setNotificationsPageTypeFilterSuccess = createAction(
  '[Notification] Set notification page type filter success'
);

export const setNotificationsPageDateFilter = createAction(
  '[Notification] Set notification page date filter',
  props<{ filter?: Date }>()
);
export const setNotificationsPageDateFilterSuccess = createAction(
  '[Notification] Set notification page date filter success'
);

export const setNotificationsPagePagination = createAction(
  '[Notification] Set notification page pagination',
  props<{ page: number }>()
);

export const setNotificationsPagePaginationSuccess = createAction(
  '[Notification] Set notification page pagination success'
);

export const modifyNotificationAttendance = createAction(
  '[Notification] Modify notification attendance',
  props<{ accept: boolean; notification: Notification }>()
);

export const modifyNotificationAttendanceSuccess = createAction(
  '[Notification] Modify notification attendance success'
);

export const modifyNotificationAttendanceFailure = createAction(
  '[Notification] Modify notification attendance failure',
  props<{ error: string }>()
);
