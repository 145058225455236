import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NoticeBoardState, NOTICE_BOARD_REDUCER_KEY } from './notice-board.reducer';

export const selectNoticeBoardState = createFeatureSelector<NoticeBoardState>(
  NOTICE_BOARD_REDUCER_KEY
);

export const selectNoticeBoardPreviewsPending = createSelector(
  selectNoticeBoardState,
  (state) => state.noticeBoardArticlePreviewStatus.pending
);

export const selectNoticeBoardPreviews = createSelector(
  selectNoticeBoardState,
  (state) => state.noticeBoardArticlePreviews
);


export const selectNoticeBoardArticle = (id: string) =>  createSelector(
  selectNoticeBoardState,
  (state) => state.noticeBoardArticles?.[id]
);

export const selectNoticeBoardArticleMap =  createSelector(
  selectNoticeBoardState,
  (state) => state.noticeBoardArticles
);


export const selectNoticeBoardArticleStatusPending = createSelector(
  selectNoticeBoardState,
  (state) => state.noticeBoardArticleStatus.pending
)
