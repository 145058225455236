/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelectors } from '@nai-libs/user/data-access';

@Component({
  selector: 'nai-new-consultation-success',
  template: `
    <ion-content>
      <div class="page">
        <div class="wrapper-fluid">
          <figure>
            <img src="assets/icons/ok-check.svg" />
          </figure>
          <h1 class="font-headline-4">Genial</h1>
          <p class="font-body-1">Hemos recibido tu consulta. Te responderemos lo antes posible.</p>
          <a [routerLink]="['/ayuda']" [mat-dialog-close] class="button button--outline button--primary">Salir</a>
        </div>
      </div>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./new-consultation-success-dialog.component.scss'],
})
export class NewConsultationSuccessDialogComponent {
  user$ = this.store.select(UserSelectors.selectUser);
  constructor(private store: Store) {}
}
