import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MarketplaceState,
  MARKETPLACE_REDUCER_KEY,
} from './marketplace.reducer';

export const selectMarketplaceState = createFeatureSelector<MarketplaceState>(
  MARKETPLACE_REDUCER_KEY
);

export const selectProducts = createSelector(
  selectMarketplaceState,
  (state) => state.products
);

export const selectProduct = (id?: string) =>
  createSelector(selectMarketplaceState, (state) => {
    return state.products?.find((loopProduct) => loopProduct.id == id);
  });
