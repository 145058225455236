import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AnyDocument,
  AppConfig,
  Budget,
  BudgetsResponse,
  Contract,
  ContractsResponse,
  DocumentCategory,
  Invoice,
  InvoicesResponse,
  NewService,
  NewServiceDocumentResponse,
  NewServicesResponse,
  NumServicesResponse,
  PAECH,
  PAECHResponse,
  Payroll,
  PayrollsResponse,
  PDFLinkResponse,
  PDFReadResponse,
  SelectedUser,
  ServiceReceiver,
  User,
} from '@nai-libs/data-access';

import { APP_CONFIG } from '@nai-libs/app-config';
import { DocumentUtilsService } from '@nai-libs/shared/utility/src';
import { catchError, map, Observable, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    private http: HttpClient,
    private documentUtils: DocumentUtilsService,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  /**
   * Método para obtener los últimos servicios disponibles para un cliente
   * @returns Observable<NewService[]>
   */
  getNewServices(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<NewService[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<NewServicesResponse>(
        `${this.env.apiUrl}services/notifications/fetch-new-accepted-proposals?${params}`
      )
      .pipe(
        map((response) =>
          response['accepted-offers'].length > 0
            ? response['accepted-offers']
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Método para obtener en enlace PDF del presupuesto relacionados con un servicio
   * @returns Observable<string | undefined>
   */
  getNewServiceDocuments(
    offerNumber: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string | undefined> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'offer-number': offerNumber,
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<NewServiceDocumentResponse>(
        `${this.env.apiUrl}documentation/fetch-offer-pdf?${params}`
      )
      .pipe(
        map((response) => response['pdf-link']),
        catchError((err) => throwError(() => err))
      );
  }

  countServices(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<number> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<NumServicesResponse>(
        `${this.env.apiUrl}services/fetch-number-of-active-services?${params}`
      )
      .pipe(
        map((res) =>
          res['number-of-active-services']
            ? parseInt(res['number-of-active-services']) || 0
            : 0
        ),
        catchError((err) => throwError(() => err))
      );
  }

  fetchBudgets(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Budget[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<BudgetsResponse>(
        `${this.env.apiUrl}services/fetch-all-accepted-proposals?${params}`
      )
      .pipe(
        map((res) =>
          res['accepted-offers']
            ? res['accepted-offers'].map(this.documentUtils.renameBackBudget)
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  fetchBudgetLink(
    id: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string | undefined> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'offer-number': id.toString(),
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<PDFLinkResponse>(
        `${this.env.apiUrl}documentation/fetch-offer-pdf?${params}`
      )
      .pipe(
        map((res) => {
          if (res['pdf-link']) {
            return res['pdf-link'].startsWith('http')
              ? res['pdf-link']
              : this.env.baseUrl + res['pdf-link'];
          } else {
            return undefined;
          }
        }),
        catchError((err) => throwError(() => err))
      );
  }

  markBudgetAsRead(
    id: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<boolean> {
    const params = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'offer-number': id.toString(),
      'familiar-email': user['logged-user']['e-mail'],
    };

    return this.http
      .post<PDFReadResponse>(
        `${this.env.apiUrl}documentation/save-read-offer-pdf`,
        params
      )
      .pipe(
        map((_) => true),
        catchError((err) => throwError(() => err))
      );
  }

  fetchInvoices(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Invoice[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<InvoicesResponse>(
        `${this.env.apiUrl}services/fetch-all-invoices?${params}`
      )
      .pipe(
        map((res) =>
          res.invoices
            ? res.invoices.map(this.documentUtils.renameBackInvoice)
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  fetchInvoiceLink(
    id: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string | undefined> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'invoice-eid': id.toString(),
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<PDFLinkResponse>(
        `${this.env.apiUrl}documentation/fetch-invoice-pdf?${params}`
      )
      .pipe(
        map((res) => {
          if (res['pdf-link']) {
            return res['pdf-link'].startsWith('http')
              ? res['pdf-link']
              : this.env.baseUrl + res['pdf-link'];
          } else {
            return undefined;
          }
        }),
        catchError((err) => throwError(() => err))
      );
  }

  markInvoiceAsRead(
    id: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<boolean> {
    const params = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'invoice-eid': id.toString(),
      'familiar-email': user['logged-user']['e-mail'],
    };

    return this.http
      .post<PDFReadResponse>(
        `${this.env.apiUrl}documentation/save-read-invoice-pdf`,
        params
      )
      .pipe(
        map((_) => true),
        catchError((err) => throwError(() => err))
      );
  }

  fetchPayrolls(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Payroll[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<PayrollsResponse>(
        `${this.env.apiUrl}services/fetch-all-payrolls?${params}`
      )
      .pipe(
        map((res) =>
          res.payrolls
            ? res.payrolls.map(this.documentUtils.renameBackPayroll)
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  fetchSpecificCarerPayrolls(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Payroll[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': serviceReceiver['e-mail'],
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<PayrollsResponse>(
        `${this.env.apiUrl}services/fetch-specific-carer-payrolls?${params}`
      )
      .pipe(
        map((res) =>
          res.payrolls
            ? res.payrolls.map(this.documentUtils.renameBackPayroll)
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  fetchPayrollLink(
    id: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string | undefined> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'payroll-eid': id.toString(),
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<PDFLinkResponse>(
        `${this.env.apiUrl}documentation/fetch-payroll-pdf?${params}`
      )
      .pipe(
        map((res) => {
          if (res['pdf-link']) {
            return res['pdf-link'].startsWith('http')
              ? res['pdf-link']
              : this.env.baseUrl + res['pdf-link'];
          } else {
            return undefined;
          }
        }),
        catchError((err) => throwError(() => err))
      );
  }

  markPayrollAsRead(
    id: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<boolean> {
    const params = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'payroll-eid': id.toString(),
      'familiar-email': user['logged-user']['e-mail'],
    };

    return this.http
      .post<PDFReadResponse>(
        `${this.env.apiUrl}documentation/save-read-payroll-pdf`,
        params
      )
      .pipe(
        map((_) => true),
        catchError((err) => throwError(() => err))
      );
  }

  fetchContracts(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Contract[]> {
    // TEST '63e2661d-2e9c-4f54-be60-47216de7ad85'
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<ContractsResponse>(
        `${this.env.apiUrl}services/fetch-all-contracts?${params}`
      )
      .pipe(
        map((res) =>
          res.contracts
            ? res.contracts.map(this.documentUtils.renameBackContract)
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  fetchContractLink(
    id: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string | undefined> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'contract-eid': id.toString(),
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<PDFLinkResponse>(
        `${this.env.apiUrl}documentation/fetch-contract-pdf?${params}`
      )
      .pipe(
        map((res) => {
          if (res['pdf-link']) {
            return res['pdf-link'].startsWith('http')
              ? res['pdf-link']
              : this.env.baseUrl + res['pdf-link'];
          } else {
            return undefined;
          }
        }),
        catchError((err) => throwError(() => err))
      );
  }

  fetchContractURL(
    contractType: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string | undefined> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'contract-type': contractType,
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<PDFLinkResponse>(
        `${this.env.apiUrl}familiar-profile/clock-in?${params}`
      )
      .pipe(
        map((res) => {
          if (res['pdf-link']) {
            return res['pdf-link'].startsWith('http')
              ? res['pdf-link']
              : this.env.baseUrl + res['pdf-link'];
          } else {
            return undefined;
          }
        }),
        catchError((err) => throwError(() => err))
      );
  }

  markContractAsRead(
    id: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<boolean> {
    const params = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'contract-eid': id.toString(),
      'familiar-email': user['logged-user']['e-mail'],
    };

    return this.http
      .post<PDFReadResponse>(
        `${this.env.apiUrl}documentation/save-read-contract-pdf`,
        params
      )
      .pipe(
        map((_) => true),
        catchError((err) => throwError(() => err))
      );
  }

  fetchPAECHs(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<PAECH[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<PAECHResponse>(
        `${this.env.apiUrl}services/fetch-all-documents?${params}`
      )
      .pipe(
        map((res) =>
          res.documents
            ? res.documents.map(this.documentUtils.renameBackPAECH)
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  fetchPAECHLink(
    date: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string | undefined> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      date,
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<PDFLinkResponse>(
        `${this.env.apiUrl}documentation/fetch-paech-pdf?${params}`
      )
      .pipe(
        map((res) => {
          if (res['pdf-link']) {
            return res['pdf-link'].startsWith('http')
              ? res['pdf-link']
              : this.env.baseUrl + res['pdf-link'];
          } else {
            return undefined;
          }
        }),
        catchError((err) => throwError(() => err))
      );
  }

  markPAECHAsRead(
    date: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<boolean> {
    const params = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      date,
      'familiar-email': user['logged-user']['e-mail'],
    };

    return this.http
      .post<PDFReadResponse>(
        `${this.env.apiUrl}documentation/save-read-paech-pdf`,
        params
      )
      .pipe(
        map((_) => true),
        catchError((err) => throwError(() => err))
      );
  }

  signDocument(
    id: string,
    signature: string,
    documentType: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<PDFLinkResponse> {
    const params = {
      eid: id,
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      signature,
      accepted: true,
      'doc-type': documentType,
    };

    return this.http
      .post<PDFLinkResponse>(
        `${this.env.apiUrl}documentation/sign-document`,
        params
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  fetchDocuments(
    category: DocumentCategory,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<AnyDocument[]> {
    switch (category) {
      case 'budgets':
        return this.fetchBudgets(serviceReceiver, selectedUser);
      case 'invoices':
        return this.fetchInvoices(serviceReceiver, selectedUser);
      case 'payrolls':
        return this.fetchPayrolls(serviceReceiver, selectedUser);
      case 'contracts':
        return this.fetchContracts(serviceReceiver, selectedUser);
      case 'paech':
        return this.fetchPAECHs(serviceReceiver, selectedUser);
      default: {
        throw new Error(`Incorrect document type: ${category}`);
      }
    }
  }

  fetchDocumentLink(
    id: string,
    category: DocumentCategory,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ) {
    switch (category) {
      case 'budgets':
        return this.fetchBudgetLink(id, serviceReceiver, selectedUser);
      case 'invoices':
        return this.fetchInvoiceLink(id, serviceReceiver, selectedUser);
      case 'payrolls':
        return this.fetchPayrollLink(id, serviceReceiver, selectedUser);
      case 'contracts':
        return this.fetchContractLink(id, serviceReceiver, selectedUser);
      case 'paech':
        return this.fetchPAECHLink(
          id.split('_')[1],
          serviceReceiver,
          selectedUser
        );
      default: {
        throw new Error(`Incorrect document type: ${category}`);
      }
    }
  }
}
