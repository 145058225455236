import { Appointment, AppointmentsByMonth } from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

// Appointments
export const loadVideocalls = createAction('[Appointments] Load videocalls');
export const loadVideocallsSuccess = createAction(
  '[Appointments] Load videocalls success',
  props<{ videocalls: Appointment[] }>()
);
export const loadVideocallsFailure = createAction(
  '[Appointments] Load videocalls error'
);

export const loadUpcomingAppointments = createAction(
  '[Appointments] Load appointments'
);
export const loadUpcomingAppointmentsSuccess = createAction(
  '[Appointments] Load appointments success',
  props<{ appointments: Appointment[] }>()
);
export const loadUpcomingAppointmentsFailure = createAction(
  '[Appointments] Load appointments error'
);

export const loadThreeMonthAppointments = createAction(
  '[Appointments] Load three month appointments'
);
export const loadThreeMonthAppointmentsSuccess = createAction(
  '[Appointments] Load three month appointments success',
  props<{ appointmentsByMonth: AppointmentsByMonth[] }>()
);
export const loadThreeMonthAppointmentsFailure = createAction(
  '[Appointments] Load three month appointments failure'
);

export const checkAndFetchMonth = createAction(
  '[Appointments] Check and fetch month',
  props<{ month: Date }>()
);
export const monthAlreadyFetched = createAction(
  '[Appointments] Month already fetched'
);

export const loadAppointmentsByMonth = createAction(
  '[Appointments] Load appointments by month',
  props<{ month: Date }>()
);
export const loadAppointmentsByMonthSuccess = createAction(
  '[Appointments] Load appointments by month success',
  props<{ appointmentsByMonth: AppointmentsByMonth }>()
);
export const loadAppointmentsByMonthFailure = createAction(
  '[Appointments] Load appointments by month failure'
);

export const loadAppointmentsByDay = createAction(
  '[Appointments] Load appointments by day',
  props<{ date: Date }>()
);
export const loadAppointmentsByDaySuccess = createAction(
  '[Appointments] Load appointments by day success',
  props<{ appointments: Appointment[] }>()
);
export const loadAppointmentsByDayFailure = createAction(
  '[Appointments] Load appointments by day failure'
);

export const modifyAppointmentAttendance = createAction(
  '[Appointments] Modify appointment attendance',
  props<{ accept: boolean; appointment: Appointment }>()
);

export const modifyAppointmentAttendanceSuccess = createAction(
  '[Appointments] Modify appointment attendance success',
  props<{ appointment: Appointment }>()
);

export const modifyAppointmentAttendanceFailure = createAction(
  '[Appointments] Modify appointment attendance failure',
  props<{ error: string }>()
);

export const modifyNotificationAppointmentAttendance = createAction(
  '[Appointments] Modify notification appointment attendance',
  props<{ state: Notification }>()
);
