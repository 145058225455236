// Tasks
import {
  TaskMap,
  TaskStatsItem,
  TaskStatsRange,
  TechnicalSheetByActivity,
} from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

export const loadDailyTasks = createAction(
  '[Task] Load daily tasks',
  props<{ date: Date; checkTaskCompletion?: true }>()
);
export const loadDailyTasksSuccess = createAction(
  '[Task] Load daily tasks success',
  props<{ tasks: TaskMap; checkTaskCompletion?: true }>()
);
export const loadDailyTasksFailure = createAction(
  '[Task] Load daily tasks error'
);

export const loadMonthlyTasks = createAction(
  '[Task] Load monthly tasks',
  props<{ date: Date; forceFetch?: boolean }>()
);
export const loadMonthlyTasksSuccess = createAction(
  '[Task] Load monthly tasks success',
  props<{ tasks: TaskMap[] }>()
);
export const loadMonthlyTasksAlreadyFetched = createAction(
  '[Task] Load monthly tasks already fetched'
);
export const loadMonthlyTasksFailure = createAction(
  '[Task] Load monthly tasks error'
);

export const markTaskAsDone = createAction(
  '[Task] Mark task as done',
  props<{
    id: string;
    code: string;
    done: boolean;
    moment: string;
    hour?: string;
  }>()
);
export const markTaskAsDoneSuccess = createAction(
  '[Task] Mark task as done success',
  props<{ id: string }>()
);
export const markTaskAsDoneFailure = createAction(
  '[Task] Mark task as done error'
);

export const loadThreeMonthTasks = createAction(
  '[Tasks] Load three month tasks'
);
export const loadThreeMonthTasksSuccess = createAction(
  '[Tasks] Load three month tasks success',
  props<{ tasks: TaskMap[] }>()
);
export const loadThreeMonthTasksFailure = createAction(
  '[Tasks] Load three month tasks failure'
);

export const loadTaskStats = createAction(
  '[Task] Load Tasks Stats',
  props<{
    date: Date;
    range: TaskStatsRange;
    carerEmail?: string;
    forceFetch?: boolean;
  }>()
);
export const loadTaskStatsSuccess = createAction(
  '[Task] Load Tasks Stats Success',
  props<{ tasksStats: TaskStatsItem[]; range: TaskStatsRange }>()
);
export const loadTaskStatsFailure = createAction(
  '[Task] Load Tasks Stats Error'
);

export const setTaskStatsRange = createAction(
  '[Task] Set Task stats range',
  props<{ taskStatsSelectedRange: TaskStatsRange }>()
);

export const loadTechnicalSheets = createAction(
  '[Task] Load Technical sheets',
  props<{ activityCode: string }>()
);
export const loadTechnicalSheetsSuccess = createAction(
  '[Task] Load Technical sheets Success',
  props<{ technicalSheetByActivity: TechnicalSheetByActivity }>()
);
export const loadTechnicalSheetsFailure = createAction(
  '[Task] Load Technical sheets Error'
);

export const loadTaskFormNotification = createAction(
  '[Task] Load task form notification'
);
export const loadTaskFormNotificationSuccess = createAction(
  '[Task] Load task form notification success',
  props<{ taskFormNotification: string }>()
);
export const loadTaskFormNotificationFailure = createAction(
  '[Task] Load task form notification failure'
);

export const saveReadTaskFormNotification = createAction(
  '[Task] Save read task form notification',
  props<{ date: string }>()
);
export const saveReadTaskFormNotificationSuccess = createAction(
  '[Task] Save read task form notification success'
);
export const saveReadTaskFormNotificationFailure = createAction(
  '[Task] Save read task form notification failure'
);
