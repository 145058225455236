import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { UserSelectors, UserService } from '@nai-libs/user/data-access';
import { catchError, map, of, switchMap } from "rxjs";

@Injectable({providedIn: 'root'})
export class LoginGuard implements CanActivate {

  userLoaded$ = this.store.select(UserSelectors.isUserLoaded);

  constructor(
    private store: Store,
    private router: Router,
    private user: UserService
  ) {}


  canActivate() {
    return this.user.getToken().pipe(
      map(token => token ? this.router.createUrlTree(['/']) : true),
      catchError(() => of(true)),
    );
  }
}