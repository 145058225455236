import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  FamiliarMember,
  FamiliarMembersResponse,
  NewMember,
  SelectedUser,
  ServiceReceiver,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig } from '@nai-libs/data-access';

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  getFamiliarMembers(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<FamiliarMember[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<FamiliarMembersResponse>(
        `${this.env.apiUrl}familiar-profile/get-familiars?${params}`
      )
      .pipe(
        map((response) => response?.familiars ?? []),
        catchError((err) => throwError(() => err))
      );
  }

  createMember(serviceReceiver: ServiceReceiver, member: NewMember) {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'new-familiar': member,
    };

    return this.http
      .post(
        `${this.env.apiUrl}familiar-profile/create-assign-familiar`,
        paramsObj
      )
      .pipe(
        catchError((error) =>
          throwError(() => {
            return error.error.body;
          })
        )
      );
  }

  deleteMember(serviceReceiver: ServiceReceiver, member: FamiliarMember) {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'familiar-eid': member['familiar-eid'].toString(),
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .delete(`${this.env.apiUrl}familiar-profile/remove-familiar?${params}`)
      .pipe(catchError((error) => error.error.body));
  }
}
