import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import { Store } from '@ngrx/store';
import * as MarketplaceActions from './marketplace.actions';

import * as MarketplaceSelectors from './marketplace.selectors';
import { MarketplaceService } from './marketplace.service';

@Injectable()
export class MarketplaceEffects {
  constructor(
    private actions$: Actions,
    private marketplaceService: MarketplaceService,
    private store: Store
  ) {}
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketplaceActions.loadProducts),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(MarketplaceSelectors.selectProducts)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([{ forceFetch }, products]) => {
        if (forceFetch || !products) {
          return this.marketplaceService.fetchProducts().pipe(
            map((products) =>
              MarketplaceActions.loadProductsSuccess({
                products,
              })
            ),
            catchError(() => of(MarketplaceActions.loadProductsFailure()))
          );
        } else {
          return of(
            MarketplaceActions.loadProductsSuccess({
              products,
            })
          );
        }
      })
    )
  );

  loadProductInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketplaceActions.loadProductInfo),
      switchMap(({ id }) => {
        return this.marketplaceService.fetchProduct(id).pipe(
          map((product) =>
            MarketplaceActions.loadProductInfoSuccess({
              product,
            })
          ),
          catchError(() => of(MarketplaceActions.loadProductInfoFailure()))
        );
      })
    )
  );
}
