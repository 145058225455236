import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map, Observable, tap } from 'rxjs';

import { ConsultationActions, ConsultationSelectors } from '@nai-libs/consultation/data-access';
import { VideoconsultationAvailability } from '@nai-libs/data-access';

@Injectable({ providedIn: 'root' })
export class SEPAGuard implements CanActivate {
  videoconsultationAvailability$ = this.store.select(
    ConsultationSelectors.selectVideoconsultationAvailability
  );

  constructor(private store: Store, private router: Router) {}

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.videoconsultationAvailability$.pipe(
      tap((availability) => {
        if (!availability) {
          this.store.dispatch(ConsultationActions.getVideoconsultationAvailability({}));
        }
      }),
      map((availability: VideoconsultationAvailability | undefined) => {
        if (!availability) return false;
        if (!availability['SEPA?']) return false;
        return true;
      }),
      first()
    );
  }
}
