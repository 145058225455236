import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MEMBERS_REDUCER_KEY, membersReducer } from './+state/members.reducer';
import { MembersEffects } from './+state/members.effects';
import { CreateMemberSuccessDialogModule } from '@nai-libs/standalone/create-member-success-dialog';
import { DeleteMemberSuccessDialogModule } from '@nai-libs/standalone/delete-member-success-dialog';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(MEMBERS_REDUCER_KEY, membersReducer),
    EffectsModule.forFeature([MembersEffects]),
    CreateMemberSuccessDialogModule,
    DeleteMemberSuccessDialogModule,
    MatDialogModule
  ],
})
export class MembersDataAccessModule {}
