import { createAction, props } from '@ngrx/store';
import {
  NoticeBoardArticle,
  NoticeBoardArticlePreview,
} from '@nai-libs/data-access';

// NoticeBoard

export const loadNoticeBoardPreviews = createAction(
  '[NoticeBoard] Load notice board previews',
  props<{ forceFetch?: boolean }>()
);
export const loadNoticeBoardPreviewsSuccess = createAction(
  '[NoticeBoard] Load notice board previews success',
  props<{ noticeBoardArticlePreviews: NoticeBoardArticlePreview[] }>()
);
export const loadNoticeBoardPreviewsFailure = createAction(
  '[NoticeBoard] Load notice board previews error'
);

export const loadNoticeBoardArticle = createAction(
  '[NoticeBoard] Load notice board article',
  props<{ forceFetch?: boolean; id: string }>()
);
export const loadNoticeBoardArticleSuccess = createAction(
  '[NoticeBoard] Load notice board article success',
  props<{ noticeBoardArticle: NoticeBoardArticle }>()
);
export const loadNoticeBoardArticleFailure = createAction(
  '[NoticeBoard] Load notice board article error'
);
