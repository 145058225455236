import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { documentReducer, DOCUMENT_REDUCER_KEY } from './+state/document.reducer';
import { DocumentEffects } from './+state/document.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(DOCUMENT_REDUCER_KEY, documentReducer),
    EffectsModule.forFeature([DocumentEffects])
  ],
})
export class DocumentDataAccessModule {}
