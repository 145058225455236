import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TaskCompletedDialogModule } from '@nai-libs/standalone/task-completed-dialog';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TaskEffects } from './+state/task.effects';
import { TASK_REDUCER_KEY, taskReducer } from './+state/task.reducer';

@NgModule({
  imports: [
    CommonModule,
    TaskCompletedDialogModule,
    StoreModule.forFeature(TASK_REDUCER_KEY, taskReducer),
    EffectsModule.forFeature([TaskEffects]),
  ],
})
export class TaskDataAccessModule {}
