import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DateUtilsService } from '@nai-libs/utility';

import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  MarkArticleResponse,
  SelectedUser,
  ServiceReceiver,
  TaskMap,
  TaskMapMonthResponse,
  TaskMapResponse,
  TaskStatsByCarer,
  TaskStatsItem,
  TaskStatsResponse,
  TechnicalSheetByActivity,
  TechnicalSheetsResponse,
  User,
} from '@nai-libs/data-access';
import { Observable, catchError, map, of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig,
    private dateUtils: DateUtilsService
  ) {}

  getDailyTasks(
    date: Date,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<TaskMap> {
    const paramsObj: {
      'client-phone': string;
      date: string;
      'carer-email'?: string;
      'user-id': string;
    } = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      date: this.dateUtils.dateToYearMonthDayHyphen(date),
    };

    if (user['logged-user']['naiha-fam-access'] === 'Cuidadora formal') {
      paramsObj['carer-email'] = user['logged-user']['e-mail'];
    }

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<TaskMapResponse>(
        `${this.env.apiUrl}health/fetch-abvd-calendar-for-specific-date?${params}`
      )
      .pipe(
        map((response) => response.calendario),
        catchError((err) => throwError(() => err))
      );
  }

  getMonthlyTasks(
    date: Date,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<TaskMap[]> {
    const paramsObj: {
      'client-phone': string;
      date: string;
      'carer-email'?: string;
      'user-id': string;
    } = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      date: this.dateUtils.dateToYearMonthDayHyphen(date),
    };

    if (user['logged-user']['naiha-fam-access'] === 'Cuidadora formal') {
      paramsObj['carer-email'] = user['logged-user']['e-mail'];
    }

    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<TaskMapMonthResponse>(
        `${this.env.apiUrl}health/fetch-abvd-calendar-for-specific-month?${params}`
      )
      .pipe(
        map((response) => response.calendario),
        catchError((err) => throwError(() => err))
      );
  }
  fetchTaskStats(
    date: Date,
    type: string,
    carerEmail: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<TaskStatsItem[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      date: this.dateUtils.dateToYearMonthDayHyphen(date),
      type,
      'carer-email': carerEmail,
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<TaskStatsResponse>(
        `${this.env.apiUrl}health/fetch-abvd-history?${params}`
      )
      .pipe(
        map((response) => response.history),
        catchError((err) => throwError(() => err))
      );
  }

  fetchTaskStatsByCarer(
    date: Date,
    type: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<TaskStatsByCarer[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      date: this.dateUtils.dateToYearMonthDayHyphen(date),
      type,
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<TaskStatsByCarer[]>(
        `${this.env.apiUrl}health/fetch-abvd-history?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  markTaskAsDone(
    id: string,
    code: string,
    done: boolean,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    moment: string,
    hour?: string
  ): Observable<MarkArticleResponse | null> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'activity-code': [code],
      email: user['logged-user']['e-mail'],
      date: id.split('_')[0],
      hour: hour ?? null,
      'daily-identifier': id,
      'done?': done,
      momento: moment ?? null,
      'user-id': selectedUser['user-id'],
    };

    return this.http
      .post(`${this.env.apiUrl}health/save-abvd-activity-as-done`, paramsObj, {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'json',
      })
      .pipe(
        map((response) => {
          if (response === null) {
            return response;
          }

          if ((response as MarkArticleResponse)['success?']) {
            return response as MarkArticleResponse;
          } else {
            throw (response as MarkArticleResponse)['message'];
          }
        }),
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  countTasks(taskMap: TaskMap) {
    return taskMap['momentos-dia'].reduce<number>(
      (acc, current) => acc + current.tareas.length,
      0
    );
  }

  loadTechnicalSheets(
    activityCode: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<TechnicalSheetByActivity> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'activity-code': activityCode,
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<TechnicalSheetsResponse>(
        `${this.env.apiUrl}health/fetch-abvd-specific-activity-cards?${params}`
      )
      .pipe(
        map((response) => ({ [activityCode]: response['activity-cards'] })),
        catchError((err) => throwError(() => err))
      );
  }

  // TODO
  fetchTaskFormNotification(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ) {
    return of('1');
  }

  // TODO
  saveReadTaskFormNotification(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    date: string
  ) {
    return of('success');
  }
}
