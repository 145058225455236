import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { DynamicIconDirectiveModule } from '@nai-libs/directives/dynamic-icon';
import { PasswordRecoverySuccessPageComponent } from './password-recovery-success-page.component';

@NgModule({
  imports: [CommonModule, RouterModule, DynamicIconDirectiveModule, MatDialogModule],
  declarations: [PasswordRecoverySuccessPageComponent],
  exports: [PasswordRecoverySuccessPageComponent],
})
export class PasswordRecoverySuccessPageModule {}
