import {
  NoticeBoardArticleMap,
  NoticeBoardArticlePreview,
  RequestStatus,
} from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';
import * as NoticeBoardActions from './notice-board.actions';

export const NOTICE_BOARD_REDUCER_KEY = 'notice-board';
export interface NoticeBoardState {
  noticeBoardArticlePreviews?: NoticeBoardArticlePreview[];
  noticeBoardArticlePreviewStatus: RequestStatus;

  noticeBoardArticles?: NoticeBoardArticleMap;
  noticeBoardArticleStatus: RequestStatus;
}

const initalState: NoticeBoardState = {
  noticeBoardArticlePreviewStatus: { pending: false },
  noticeBoardArticleStatus: { pending: false },
};

export const noticeBoardReducer = createReducer(
  initalState,

  on(NoticeBoardActions.loadNoticeBoardPreviews, (state, { forceFetch }) => ({
    ...state,
    noticeBoardArticlePreviewStatus: { pending: true },
  })),
  on(
    NoticeBoardActions.loadNoticeBoardPreviewsSuccess,
    (state, { noticeBoardArticlePreviews }) => ({
      ...state,
      noticeBoardArticlePreviews,
      noticeBoardArticlePreviewStatus: { pending: false },
    })
  ),
  on(NoticeBoardActions.loadNoticeBoardPreviewsFailure, (state) => ({
    ...state,
    noticeBoardArticlePreviews: undefined,
    noticeBoardArticlePreviewStatus: { pending: false },
  })),

  on(NoticeBoardActions.loadNoticeBoardArticle, (state, { forceFetch }) => ({
    ...state,
    noticeBoardArticleStatus: { pending: true },
  })),
  on(
    NoticeBoardActions.loadNoticeBoardArticleSuccess,
    (state, { noticeBoardArticle }) => {
      let newNoticeBoardArticles: NoticeBoardArticleMap;
      if (state.noticeBoardArticles) {
        newNoticeBoardArticles = { ...state.noticeBoardArticles };
        newNoticeBoardArticles[noticeBoardArticle.id] = noticeBoardArticle;
      } else {
        newNoticeBoardArticles = {
          [noticeBoardArticle.id]: noticeBoardArticle,
        };
      }

      return {
        ...state,
        noticeBoardArticles: newNoticeBoardArticles,
        noticeBoardArticleStatus: { pending: false },
      };
    }
  ),
  on(NoticeBoardActions.loadNoticeBoardArticleFailure, (state) => ({
    ...state,
    noticeBoardArticleStatus: { pending: false },
  }))
);
