import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScheduleState, SCHEDULE_REDUCER_KEY } from './schedule.reducer';

export const selectScheduleState =
  createFeatureSelector<ScheduleState>(SCHEDULE_REDUCER_KEY);

export const selectSelectedDay = createSelector(
  selectScheduleState,
  (state) => state.selectedDay
);


export const selectFilter = createSelector(
  selectScheduleState,
  (state) => state.filter
);

