import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  ConsultHistory,
  ConsultOpeningRulesResponse,
  CustomerSupportProfessional,
  FaqResponse,
  FaqTopic,
  NewVideoconsultation,
  SelectedUser,
  ServiceReceiver,
  User,
  VideoconsultationAvailability,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsultationService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  getCustomerSupportProfessionals(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<CustomerSupportProfessional[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'naiha-fam-access': user['logged-user']['naiha-fam-access'],
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<CustomerSupportProfessional[]>(
        `${this.env.apiUrl}help/fetch-customer-support-professionals?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  getFaq(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<FaqTopic[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'naiha-fam-access': user['logged-user']['naiha-fam-access'],
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<FaqResponse>(`${this.env.apiUrl}help/fetch-FAQ?${params}`)
      .pipe(
        map((response) => response['FAQ']),
        catchError((err) => throwError(() => err))
      );
  }

  getConsultRules(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<ConsultOpeningRulesResponse> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'naiha-fam-access': user['logged-user']['naiha-fam-access'],
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<ConsultOpeningRulesResponse>(
        `${this.env.apiUrl}help/fetch-consult-opening-rules?${params}`
      )
      .pipe(
        map((response) => response),
        catchError((err) => throwError(() => err))
      );
  }

  getConsultHistory(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<ConsultHistory> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<ConsultHistory>(
        `${this.env.apiUrl}help/fetch-familiar-professional-consultations?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  newConsultation(
    consultArea: string,
    consultSubarea: string,
    description: string,
    professionalEID: number,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
      'professional-eid': professionalEID.toString(),
      'consult?': true,
      'consult-area': consultArea,
      'consult-subarea': consultSubarea,
      'consult-description': description,
    };

    return this.http
      .post(
        `${this.env.apiUrl}help/save-new-familiar-professional-consultation`,
        paramsObj,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(
        map((response) => response.toString()),
        catchError((err) => throwError(() => err))
      );
  }

  newResponse(
    consultEID: string,
    consultComment: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
      'consult-eid': consultEID,
      'consult-comment': consultComment,
    };

    return this.http
      .post(
        `${this.env.apiUrl}help/save-new-familiar-professional-consultation-comment`,
        paramsObj,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(
        map((response) => response.toString()),
        catchError((err) => throwError(() => err))
      );
  }

  markConsultationAsRead(
    consultEID: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
      'consult-eid': consultEID,
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .post(
        `${this.env.apiUrl}help/save-read-familiar-professional-consultation?${params}`,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(
        map((response) => response.toString()),
        catchError((err) => throwError(() => err))
      );
  }

  getVideoconsultationAvailability(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    lastAvailableDate?: Date
  ): Observable<VideoconsultationAvailability> {
    let paramsObj = {};
    if (lastAvailableDate) {
      paramsObj = {
        'client-phone': serviceReceiver['client-phone'],
        'user-id': selectedUser['user-id'],
        'familiar-email': user['logged-user']['e-mail'],
        'last-available-date': lastAvailableDate,
      };
    } else {
      paramsObj = {
        'client-phone': serviceReceiver['client-phone'],
        'familiar-email': user['logged-user']['e-mail'],
      };
    }
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<VideoconsultationAvailability>(
        `${this.env.apiUrl}help/fetch-videoconsult-initialization-data?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  newVideoconsultation(
    intervention: NewVideoconsultation,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
      intervention: {
        reason: intervention.reason,
        'start-date': intervention['start-date'],
        'attendee-email': intervention['attendee-email'],
        'attendee-phone': intervention['attendee-phone'],
        price: intervention.price?.toString(),
      },
    };

    return this.http
      .post(`${this.env.apiUrl}help/save-videoconsult`, paramsObj, {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text',
      })
      .pipe(catchError((err) => throwError(() => err)));
  }

  sendConsultationEmail(
    receiverEmail: string,
    receiverName: string,
    message: string,
    user: User,
    selectedUser: SelectedUser
  ) {
    const paramsObj = {
      receiver_email: receiverEmail,
      receiver_name: receiverName,
      from: `${user['logged-user'].identifier.name} ${user['logged-user'].identifier['first-surname']} ${user['logged-user'].identifier['second-surname']}`,
      body: message,
      'user-id': selectedUser['user-id'],
    };

    return this.http
      .post(`${this.env.apiUrl}help/send-info-request-email`, paramsObj, {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text',
      })
      .pipe(catchError((err) => throwError(() => err)));
  }
}
