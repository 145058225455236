import { Injectable } from "@angular/core";
import { GetResult, Preferences } from '@capacitor/preferences';
import { from, map, Observable } from "rxjs";

@Injectable({ 
  providedIn: 'root' 
})
export class StorageService {
  setItem<T>(key: string, _value: T): Observable<T> {
    const value = this.serialize(_value);
    return from(Preferences.set({ key, value })).pipe(map(() => _value));
  }

  getItem<T>(key: string): Observable<T | null> {
    return from(Preferences.get({ key })).pipe(
      map(({ value }: GetResult) => {
        if (value == null) {
          return null;
        }
        return this.unserialize<T>(value);
      })
    );
  }

  removeItem(key: string): Observable<void> {
    return from(Preferences.remove({ key })).pipe(map(() => {}));
  }

  private serialize(data: unknown): string {
    return JSON.stringify(data);
  }

  private unserialize<T>(data: string): T {
    return JSON.parse(data) as T;
  }

}