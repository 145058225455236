import { Injectable } from '@angular/core';
import { CustomTranslateService } from '../services/custom-translate.service';


@Injectable({
  providedIn: 'root'
})
export class CustomLocaleId extends String {
  constructor(private translateService: CustomTranslateService) {
    super();
  }

  override toString(): string {
    return this.translateService.currentLocale;
  }

  override valueOf(): string {
    return this.toString();
  }
}