import { Component, HostBinding, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'nai-alert-snackbar',
  template: `
    <ng-container *ngIf="data.message">
      <button class="alert-button-close" (click)="snackBarRef.dismiss()">
        <span naiDynIcon [path]="'assets/icons/close.svg'"></span>
      </button>
      <figure>
        <img [src]="data.imageUrl" />
      </figure>
      <div class="alert-message-container">
        <p>{{ data.message }}</p>
      </div>
    </ng-container>
  `,
  styleUrls: ['./alert-snackbar.component.scss'],
})
export class AlertSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      alertType: 'info' | 'error' | 'warning' | 'success';
      message: string;
      imageUrl: string;
    },
    public snackBarRef: MatSnackBarRef<AlertSnackbarComponent>
  ) {}

  @HostBinding('class') get alertTypeClass() {
    return `alert-snackbar--${this.data.alertType}`;
  }
}
