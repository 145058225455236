import { createAction, props } from '@ngrx/store';
import { MarketplaceProduct } from '@nai-libs/data-access';

export const loadProducts = createAction(
  '[Marketplace] Load products',
  props<{ forceFetch?: boolean }>()
);
export const loadProductsSuccess = createAction(
  '[Marketplace] Load products success',
  props<{ products: MarketplaceProduct[] }>()
);
export const loadProductsFailure = createAction(
  '[Marketplace] Load products error'
);

export const loadProductInfo = createAction(
  '[Marketplace] Load product info',
  props<{ id: string }>()
);
export const loadProductInfoSuccess = createAction(
  '[Marketplace] Load product info success',
  props<{ product: MarketplaceProduct }>()
);
export const loadProductInfoFailure = createAction(
  '[Marketplace] Load product info error'
);
