import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  Form,
  FormStatus,
  FormSubmissionAnswer,
  FormSummary,
  SelectedUser,
  ServiceReceiver,
  User,
} from '@nai-libs/data-access';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}
  // TODO
  fetchFormNewComplete(
    user: User,
    serviceReceiver: ServiceReceiver,
    id: string
  ): Observable<Form> {
    //TODO Observable<Form> : use the correct interface
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'familiar-email': user['logged-user']['e-mail'],
      id,
      version: 'new-complete',
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http.get<Form>(
      `${this.env.apiUrl}health/fetch-survey-model?${params}`
    );

    // return of(TASK_FORM_NEW);
  }

  saveForm(
    user: User,
    serviceReceiver: ServiceReceiver,
    formAnswers: FormSubmissionAnswer[],
    id: string
  ): Observable<string> {
    const currentDate = new Date(); //TODO/NOTA: esto no tendría la zona horaria correcta
    const timestamp = currentDate.getTime();
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'familiar-email': user['logged-user']['e-mail'],
      id,
      timestamp,
      answers: formAnswers,
    };
    return this.http.post(
      `${this.env.apiUrl}health/save-survey-answers`,
      paramsObj,
      {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text',
      }
    );
  }

  fetchFormSummary(
    id: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<FormSummary> {
    const paramsObj = {
      id,
      'client-phone': serviceReceiver['client-phone'],
      'familiar-email': user['logged-user']['e-mail'],
      selectedUser: selectedUser['user-id'],
      version: 'new-complete',
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http.get<FormSummary>(
      `${this.env.apiUrl}health/fetch-survey-results?${params}`
    );
  }

  fetchFormStatus(
    id: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<FormStatus> {
    const paramsObj = {
      id,
      'client-phone': serviceReceiver['client-phone'],
      'familiar-email': user['logged-user']['e-mail'],
      selectedUser: selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http.get<FormStatus>(
      `${this.env.apiUrl}health/fetch-survey-status?${params}`
    );
  }
}
