import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nai-dialog-success',
  template: `
    <div class="dialog">
      <figure>
        <img src="assets/icons/ok-check.svg" />
      </figure>
      <h1>{{ data.title }}</h1>
      <p>
        {{ data.subtitle }}
      </p>
      <button [mat-dialog-close] class="button button--outline button--primary">
        {{ data.buttonText }}
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./dialog-success.component.scss'],
})
export class DialogSuccessComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; subtitle: string; buttonText: string }
  ) {}
}
