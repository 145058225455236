import { Intervention } from '@nai-libs/shared/data-access/src';
import { createReducer, on } from '@ngrx/store';
import * as ClockInActions from './clock-in.actions';

export const CLOCK_IN_FEATURE_KEY = 'clockIn';

export interface ClockInState {
  scannerLastNFC?: string;
  activeIntervention?: Intervention;
}

const initialState: ClockInState = {
  scannerLastNFC: undefined,
};

export const clockInReducer = createReducer(
  initialState,

  on(
    ClockInActions.loadActiveInterventionSuccess,
    (state, { intervention, status }) => ({
      ...state,
      activeIntervention:
        intervention && status ? { ...intervention, status } : undefined,
    })
  ),
  on(ClockInActions.loadActiveInterventionFailure, (state) => ({
    ...state,
    activeIntervention: undefined,
  })),
  on(ClockInActions.sendInterventionClockIn, ClockInActions.sendInterventionClockOut, (state, { NFC_id }) => ({
    ...state,
    scannerLastNFC: NFC_id,
  }))
);
