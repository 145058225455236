import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';
import * as HomeAccessibilityActions from './home-accessibility.actions';
import * as HomeAccessibilitySelectors from './home-accessibility.selectors';

import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { HomeAccessibilityService } from './home-accessibility.service';

@Injectable()
export class HomeAccessibilityEffects {
  constructor(
    private actions$: Actions,
    private homeAccessibilityService: HomeAccessibilityService,
    private store: Store,
    private navCtrl: NavController,
    private route: ActivatedRoute
  ) {}
  loadHomeAccessibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeAccessibilityActions.loadHomeAccessibility),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(
                HomeAccessibilitySelectors.selectHomeAccessibility
              )
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(
        ([
          { forceFetch },
          serviceReceiver,
          selectedUser,
          homeAccessibilityResults,
        ]) => {
          if (!serviceReceiver || !selectedUser) {
            return of(UserActions.logout());
          }
          if (forceFetch || !homeAccessibilityResults) {
            return this.homeAccessibilityService
              .loadHomeAccessibility(serviceReceiver, selectedUser)
              .pipe(
                map((homeAccessibilityResults) =>
                  HomeAccessibilityActions.loadHomeAccessibilitySuccess({
                    homeAccessibilityResults,
                  })
                ),
                catchError(() =>
                  of(HomeAccessibilityActions.loadHomeAccessibilityFailure())
                )
              );
          } else {
            return of(
              HomeAccessibilityActions.loadHomeAccessibilitySuccess({
                homeAccessibilityResults,
              })
            );
          }
        }
      )
    )
  );

  loadHomeAccessibilityLast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeAccessibilityActions.loadHomeAccessibilityLast),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(
                HomeAccessibilitySelectors.selectHomeAccessibilityLast
              )
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        return this.homeAccessibilityService
          .loadHomeAccessibilityLast(serviceReceiver, selectedUser)
          .pipe(
            map((homeAccessibilityLast) =>
              HomeAccessibilityActions.loadHomeAccessibilityLastSuccess({
                homeAccessibilityLast,
              })
            ),
            catchError(() =>
              of(HomeAccessibilityActions.loadHomeAccessibilityLastFailure())
            )
          );
      })
    )
  );

  loadHomeAccessibilityNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeAccessibilityActions.loadHomeAccessibilityNotification),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectUser),
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(
                HomeAccessibilitySelectors.selectHomeAccessibilityNotification
              )
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(
        ([
          ,
          user,
          serviceReceiver,
          selectedUser,
          homeAccessibilityNotification,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser) {
            return of(UserActions.logout());
          }

          if (homeAccessibilityNotification)
            return of(
              HomeAccessibilityActions.loadHomeAccessibilityNotificationSuccess(
                {
                  homeAccessibilityNotification,
                }
              )
            );
          return this.homeAccessibilityService
            .loadHomeAccessibilityNotification(
              user,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((homeAccessibilityNotification) =>
                HomeAccessibilityActions.loadHomeAccessibilityNotificationSuccess(
                  { homeAccessibilityNotification }
                )
              ),
              catchError(() =>
                of(
                  HomeAccessibilityActions.loadHomeAccessibilityNotificationFailure()
                )
              )
            );
        }
      )
    )
  );

  saveReadHomeAccessibilityNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeAccessibilityActions.saveReadHomeAccessibilityNotification),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectUser),
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([{ date }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }

        return this.homeAccessibilityService
          .saveReadHomeAccessibilityNotification(
            user,
            serviceReceiver,
            selectedUser,
            date
          )
          .pipe(
            map(() =>
              HomeAccessibilityActions.saveReadHomeAccessibilityNotificationSuccess()
            ),
            catchError(() =>
              of(
                HomeAccessibilityActions.loadHomeAccessibilityNotificationFailure()
              )
            )
          );
      })
    )
  );
  saveReadHomeAccessibilityNotificationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          HomeAccessibilityActions.saveReadHomeAccessibilityNotificationSuccess
        ),
        tap(() => {
          this.navCtrl.navigateForward([
            'dashboard',
            'vivienda-accesible',
            'resultados',
          ]);
        })
      ),
    { dispatch: false }
  );
}
