import { Injectable } from '@angular/core';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import { Caregiver, Professional } from '@nai-libs/data-access';
import * as ProfessionalActions from './professional.actions';
import * as ProfessionalSelectors from './professional.selectors';
import { ProfessionalService } from './professional.service';

@Injectable()
export class ProfessionalEffects {
  constructor(
    private actions$: Actions,
    private professionalService: ProfessionalService,
    private store: Store
  ) {}

  loadProfessionals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfessionalActions.loadProfessionals),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(ProfessionalSelectors.selectProfessionals)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, serviceReceiver, selectedUser, professionals]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        if (professionals)
          return of(
            ProfessionalActions.loadProfessionalsSuccess({ professionals })
          );
        return this.professionalService
          .fetchProfessionals(serviceReceiver, selectedUser)
          .pipe(
            map((professionals: Professional[]) =>
              ProfessionalActions.loadProfessionalsSuccess({ professionals })
            ),
            catchError(() =>
              of(
                ProfessionalActions.loadProfessionalsFailure({
                  error: 'No se han podido recoger los profesionales',
                })
              )
            )
          );
      })
    )
  );

  loadCaregivers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfessionalActions.loadCaregivers),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(ProfessionalSelectors.selectCaregivers)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, serviceReceiver, selectedUser, caregivers]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        if (caregivers)
          return of(ProfessionalActions.loadCaregiversSuccess({ caregivers }));
        return this.professionalService
          .fetchCaregivers(serviceReceiver, selectedUser)
          .pipe(
            map((caregivers: Caregiver[]) =>
              ProfessionalActions.loadCaregiversSuccess({ caregivers })
            ),
            catchError(() =>
              of(
                ProfessionalActions.loadCaregiversFailure({
                  error: 'No se han podido recoger los profesionales',
                })
              )
            )
          );
      })
    )
  );
}
