import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class ResetService {
  constructor(private store: Store) {}
  restoreInitialState() {
    // TODO
  }
}
