import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HomeAccessibilityState, HOME_ACCESSIBILITY_REDUCER_KEY } from './home-accessibility.reducer';

export const selectHomeAccessibilityState = createFeatureSelector<HomeAccessibilityState>(
  HOME_ACCESSIBILITY_REDUCER_KEY
);

export const selectHomeAccessibilityPending = createSelector(
  selectHomeAccessibilityState,
  (state) => state.homeAccessibilityStatus.pending
);

export const selectHomeAccessibility = createSelector(
  selectHomeAccessibilityState,
  (state) => state.homeAccessibilityResults
)

export const selectHomeAccessibilityLast = createSelector(
  selectHomeAccessibilityState,
  (state) => state.homeAccessibilityLast
)

export const selectHomeAccessibilityLastPending = createSelector(
  selectHomeAccessibilityState,
  (state) => state.homeAccessibilityLastStatus.pending
)

export const selectHomeAccessibilityNotification = createSelector(
  selectHomeAccessibilityState,
  (state) => state.homeAccessibilityNotification
)

export const selectHomeAccessibilityNotificationPending = createSelector(
  selectHomeAccessibilityState,
  (state) => state.homeAccessibilityNotificationStatus.pending
)

export const selectHomeAccessibilityDetail = (slug?: string) => createSelector(selectHomeAccessibility, state => {
  if(state){
    return state.results.find((result) => result.slug === slug);
   }else{
     return undefined
   }
});

export const selectHomeAccessibilityGuidelines = createSelector(
  selectHomeAccessibilityState,
  (state) => state.homeAccessibilityResults?.['formative-article']
)
