import { Caregiver, Professional, RequestStatus } from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';
import * as ProfessionalActions from './professional.actions';

export const PROFESSIONAL_REDUCER_KEY = 'professional';

export interface ProfessionalState {
  professionals?: Professional[];
  professionalsStatus: RequestStatus;
  caregivers?: Caregiver[];
  caregiversStatus: RequestStatus;
}

const initialState: ProfessionalState = {
  professionalsStatus: { pending: false },
  caregiversStatus: { pending: false },
};

export const professionalReducer = createReducer(
  initialState,

  on(ProfessionalActions.loadProfessionals, (state) => ({
    ...state,
    professionals: undefined,
    professionalsStatus: { pending: true },
  })),
  on(
    ProfessionalActions.loadProfessionalsSuccess,
    (state, { professionals }) => ({
      ...state,
      professionals,
      professionalsStatus: { pending: false },
    })
  ),
  on(ProfessionalActions.loadProfessionalsFailure, (state) => ({
    ...state,
    professionals: undefined,
    professionalsStatus: { pending: false },
  })),

  on(ProfessionalActions.loadCaregivers, (state) => ({
    ...state,
    caregivers: undefined,
    caregiversStatus: { pending: true },
  })),
  on(ProfessionalActions.loadCaregiversSuccess, (state, { caregivers }) => ({
    ...state,
    caregivers,
    caregiversStatus: { pending: false },
  })),
  on(ProfessionalActions.loadCaregiversFailure, (state) => ({
    ...state,
    caregivers: undefined,
    caregiversStatus: { pending: false },
  }))
);
