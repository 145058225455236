import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewPasswordPageComponent } from './containers/new-password-page.component';

const routes: Routes = [
  {
    path: '',
    component: NewPasswordPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewPasswordFeatureRoutingModule {}
