import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { professionalReducer, PROFESSIONAL_REDUCER_KEY } from './+state/professional.reducer';
import { ProfessionalEffects } from './+state/professional.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PROFESSIONAL_REDUCER_KEY, professionalReducer),
    EffectsModule.forFeature([ProfessionalEffects])
  ]
})
export class ProfessionalDataAccessModule {}
