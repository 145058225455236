import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {
  CustomLocaleId,
  CustomMatDateAdapter,
  CustomTranslateLoader,
} from '@nai-libs/shared/utility/src';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppointmentDataAccessModule } from '@nai-libs/appointment/data-access/src';
import { ArticleDataAccessModule } from '@nai-libs/article/data-access/src';
import { ConsultationDataAccessModule } from '@nai-libs/consultation/data-access/src';
import { DgiDataAccessModule } from '@nai-libs/dgi/data-access/src';
import { DocumentDataAccessModule } from '@nai-libs/document/data-access/src';
import { FormDataAccessModule } from '@nai-libs/form/data-access/src';
import { HomeAccessibilityDataAccessModule } from '@nai-libs/home-accessibility/data-access/src';
import { NotificationDataAccessModule } from '@nai-libs/notification/data-access/src';
import { ProfessionalDataAccessModule } from '@nai-libs/professional/data-access/src';
import { PushNotificationsDataAccessModule } from '@nai-libs/push-notifications/data-access/src';
import { ScheduleDataAccessModule } from '@nai-libs/schedule/data-access/src';
import { TaskDataAccessModule } from '@nai-libs/task/data-access/src';
import {
  AuthInterceptorService,
  UserDataAccessModule,
} from '@nai-libs/user/data-access/src';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ROOT_REDUCERS, metaReducers } from './reducers';
import { ForumSurveysDataAccessModule } from '@nai-libs/survey/data-access/src';
import { NoticeBoardDataAccessModule } from '@nai-libs/notice-board/data-access';

import { RespiroDataAccessModule } from '@nai-libs/respiro/data-access/src';
import { MarketplaceDataAccessModule } from '@nai-libs/marketplace/data-access/src';
import { MembersDataAccessModule } from '@nai-libs/members/data-access';

import { NewPasswordFeatureModule } from '@nai-libs/user/features/new-password-feature';
import { ClockInDataAccessModule } from '@nai-libs/clock-in/data-access';

export function loadTranslate(translateService: TranslateService) {
  return () => {
    translateService.use('etxetic-es');
  };
}

export function loadCustomTranslateLoader() {
  return new CustomTranslateLoader(environment);
}

registerLocaleData(localeEs, 'es');

export const CUSTOM_DATE_FORMATS = {
  parse: { dateInput: 'DD/MM/YYYY' },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UserDataAccessModule,
    DgiDataAccessModule,
    AppointmentDataAccessModule,
    TaskDataAccessModule,
    ScheduleDataAccessModule,
    DocumentDataAccessModule,
    ArticleDataAccessModule,
    ProfessionalDataAccessModule,
    ConsultationDataAccessModule,
    HomeAccessibilityDataAccessModule,
    PushNotificationsDataAccessModule,
    FormDataAccessModule,
    ForumSurveysDataAccessModule,
    NotificationDataAccessModule,
    NoticeBoardDataAccessModule,
    MarketplaceDataAccessModule,
    RespiroDataAccessModule,
    MembersDataAccessModule,
    NewPasswordFeatureModule,
    ClockInDataAccessModule,
    EffectsModule.forRoot(),
    IonicModule.forRoot(),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: loadCustomTranslateLoader
      },
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: LOCALE_ID,
      useClass: CustomLocaleId,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: APP_CONFIG, useValue: environment },
    ScreenOrientation,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslateService],
      useFactory: loadTranslate,
    },

    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: CustomMatDateAdapter,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
