import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Token } from '@capacitor/push-notifications';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, User } from '@nai-libs/shared/data-access/src';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(
    @Inject(APP_CONFIG) private env: AppConfig,
    private http: HttpClient
  ) {}

  registerToken(token: Token, user: User): Observable<Token> {
    return this.http
      .post(
        `${this.env.apiUrl}familiar-profile/FMCTokenRegistration`,
        {
          uuid: user['logged-user'].id,
          token: token.value,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(
        map((_) => token),
        catchError((err) => throwError(() => err))
      );
  }
}
