import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DynamicIconDirectiveModule } from '@nai-libs/shared/utility/src/lib/directives/dynicon/dynicon.module';
import { TranslateModule } from '@ngx-translate/core';
import { TaskCompletedDialogComponent } from './task-completed-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    DynamicIconDirectiveModule,
  ],
  declarations: [TaskCompletedDialogComponent],
  exports: [TaskCompletedDialogComponent],
})
export class TaskCompletedDialogModule {}
