import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  HOME_ACCESSIBILITY_REDUCER_KEY,
  homeAccessibilityReducer,
} from './+state/home-accessibility.reducer';
import { HomeAccessibilityEffects } from './+state/home-accessibility.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      HOME_ACCESSIBILITY_REDUCER_KEY,
      homeAccessibilityReducer
    ),
    EffectsModule.forFeature([HomeAccessibilityEffects]),
  ],
})
export class HomeAccessibilityDataAccessModule {}


