import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ClockInSuccessDialogComponent } from "./clock-in-success.dialog";

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [ClockInSuccessDialogComponent],
  exports: [ClockInSuccessDialogComponent]
})
export class ClockInSuccessModule {

}