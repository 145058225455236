import { NotificationHistory, Notifications } from '@nai-libs/data-access';
import { RequestStatus } from '@nai-libs/shared/data-access/src';
import { createReducer, on } from '@ngrx/store';
import * as NotificationActions from './notification.actions';

export const NOTIFICATION_REDUCER_KEY = 'notification';
export interface NotificationState {
  notifications?: Notifications;
  notificationsStatus: RequestStatus;

  notificationHistory?: NotificationHistory;
  notificationHistoryStatus: RequestStatus;
  saveReadNotificationStatus: RequestStatus;

  notificationPageFilters?: { type?: string; date?: Date; page?: number };
}

const initalState: NotificationState = {
  notificationsStatus: { pending: false },
  notificationHistoryStatus: { pending: false },
  saveReadNotificationStatus: { pending: false },
};

export const notificationReducer = createReducer(
  initalState,

  on(NotificationActions.loadNotifications, (state) => ({
    ...state,
    notificationsStatus: { pending: true },
  })),
  on(
    NotificationActions.loadNotificationsSuccess,
    (state, { notifications }) => ({
      ...state,
      notifications,
      notificationsStatus: { pending: false },
    })
  ),
  on(NotificationActions.loadNotificationsFailure, (state) => ({
    ...state,
    notificationsStatus: { pending: false },
  })),

  on(NotificationActions.saveReadNotification, (state) => ({
    ...state,
    saveReadNotificationStatus: { pending: true },
  })),
  on(NotificationActions.saveReadNotificationSuccess, (state) => ({
    ...state,
    saveReadNotificationStatus: { pending: false },
  })),
  on(NotificationActions.saveReadNotificationFailure, (state) => ({
    ...state,
    saveReadNotificationStatus: { pending: false },
  })),

  on(NotificationActions.loadNotificationHistory, (state) => ({
    ...state,
    notificationsStatus: { pending: true },
  })),
  on(
    NotificationActions.loadNotificationHistorySuccess,
    (state, { notifications }) => ({
      ...state,
      notificationHistory: notifications,
      notificationsStatus: { pending: false },
    })
  ),
  on(NotificationActions.loadNotificationHistoryFailure, (state) => ({
    ...state,
    notificationsStatus: { pending: false },
  })),

  on(
    NotificationActions.setNotificationsPageTypeFilter,
    (state, { filter }) => ({
      ...state,
      notificationPageFilters: {
        ...state.notificationPageFilters,
        type: filter,
        page: 0,
      },
    })
  ),
  on(
    NotificationActions.setNotificationsPageDateFilter,
    (state, { filter }) => ({
      ...state,
      notificationPageFilters: {
        ...state.notificationPageFilters,
        date: filter,
        page: 0,
      },
    })
  ),
  on(NotificationActions.setNotificationsPagePagination, (state, { page }) => ({
    ...state,
    notificationPageFilters: {
      ...state.notificationPageFilters,
      page,
    },
  }))
);
