import {
  RequestStatus,
  RespiroAppointment,
  RespiroAvailableAppointmentsByMonth,
  RespiroConsumedHours,
  RespiroContactData,
} from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';

import * as RespiroActions from './respiro.actions';

export const RESPIRO_REDUCER_KEY = 'respiro';

export interface RespiroState {
  availableAppointments?: RespiroAvailableAppointmentsByMonth;
  availableAppointmentsStatus: RequestStatus;
  consumedHours: RespiroConsumedHours;
  consumedHoursStatus: RequestStatus;
  formAppointments?: RespiroAppointment[];
  formMessages?: string[];
  saveRespiroRequestStatus: RequestStatus;
  loadCounter: number;
  contactData?: RespiroContactData;
  contactDataRequestStatus: RequestStatus;
}

const initalState: RespiroState = {
  availableAppointmentsStatus: { pending: false },
  consumedHours: {},
  consumedHoursStatus: { pending: false },
  saveRespiroRequestStatus: { pending: false },
  loadCounter: 0,
  contactDataRequestStatus: { pending: false },
};

export const respiroReducer = createReducer(
  initalState,
  on(RespiroActions.loadAvailableAppointments, (state) => ({
    ...state,
    availableAppointmentsStatus: { pending: true },
  })),
  on(
    RespiroActions.loadAvailableAppointmentsSuccess,
    (state, { availableAppointmentsByDay }) => {
      let availableAppointmentsByMonth: RespiroAvailableAppointmentsByMonth =
        {};
      if (state.availableAppointments) {
        availableAppointmentsByMonth = JSON.parse(
          JSON.stringify(state.availableAppointments)
        );
      }

      for (const key of Object.keys(availableAppointmentsByDay)) {
        if (!availableAppointmentsByMonth[parseInt(key.split('-')[1])]) {
          availableAppointmentsByMonth[parseInt(key.split('-')[1])] = {};
        }
        availableAppointmentsByMonth[parseInt(key.split('-')[1])][key] =
          availableAppointmentsByDay[key];
      }
      return {
        ...state,
        availableAppointments: availableAppointmentsByMonth,
        availableAppointmentsStatus: { pending: false },
      };
    }
  ),
  on(RespiroActions.loadAvailableAppointmentsError, (state) => ({
    ...state,
    avaialableAppointmentsStatus: { pending: false },
  })),
  on(RespiroActions.loadConsumedHours, (state) => ({
    ...state,
    consumedHoursStatus: { pending: true },
  })),
  on(RespiroActions.loadConsumedHoursSuccess, (state, { consumedHours, reset }) => {
    const newConsumedHours = JSON.parse(JSON.stringify(state.consumedHours));
  
    for (const month of Object.keys(consumedHours)) {
      newConsumedHours[month] = {
        selectedHours: reset ? 0 : newConsumedHours[month]?.selectedHours || 0,
        availableHours: consumedHours[month].availableHours
      }
    }
    return {
      ...state,
      consumedHours: newConsumedHours,
      consumedHoursStatus: { pending: false },
    };
  }),
  on(RespiroActions.loadConsumedHoursError, (state) => ({
    ...state,
    consumedHoursStatus: { pending: false },
  })),
  on(RespiroActions.selectAppointment, (state, { appointment, selected }) => {
    const consumedHours = JSON.parse(JSON.stringify(state.consumedHours));
    const newAvailableAppointments = JSON.parse(
      JSON.stringify(state.availableAppointments)
    );
    for (const month in state.availableAppointments) {
      for (const loopDay in state.availableAppointments[month]) {
        for (const [index, storedAppointment] of state.availableAppointments[
          month
        ][loopDay].entries()) {
          if (
            appointment.start.toString().split('T')[0] === loopDay &&
            storedAppointment.start === appointment.start &&
            storedAppointment.end === appointment.end
          ) {
            newAvailableAppointments[month][loopDay][index].selected = selected;
        
            const hours = new Date(appointment.end).getHours() -
            new Date(appointment.start).getHours();
            const selectedHours = consumedHours[month].selectedHours;
            consumedHours[month].selectedHours = (selected ? selectedHours + hours : selectedHours - hours);            
          }
        }
      }
    }
    return {
      ...state,
      availableAppointments: newAvailableAppointments,
      consumedHours
    };
  }),
  on(RespiroActions.resetAvailableAppointments, (state) => ({
    ...state,
    availableAppointments: undefined,
  })),
  on(RespiroActions.setFormAppointments, (state, { appointments }) => ({
    ...state,
    formAppointments: appointments,
  })),
  on(RespiroActions.setFormMessage, (state, { messages }) => ({
    ...state,
    formMessages: messages,
  })),
  on(RespiroActions.resetRespiroForm, (state) => ({
    ...state,
    formMessages: undefined,
    formAppointments: undefined,
  })),
  on(RespiroActions.incrementLoadCounter, (state) => ({
    ...state,
    loadCounter: state.loadCounter + 1,
  })),
  on(RespiroActions.resetLoadCounter, (state) => ({
    ...state,
    loadCounter: 0,
  })),
  on(RespiroActions.loadContactData, (state) => ({
    ...state,
    contactDataRequestStatus: { pending: true },
  })),
  on(RespiroActions.loadContactDataSuccess, (state, { contactData }) => ({
    ...state,
    contactData,
    contactDataRequestStatus: { pending: false },
  })),
  on(RespiroActions.loadContactDataError, (state) => ({
    ...state,
    contactDataRequestStatus: { pending: false },
  }))
);
