import { DGIResult } from "@nai-libs/data-access";
import { createAction, props } from "@ngrx/store";
export const loadDGI = createAction('[DGI] Load DGI');
export const loadDGISuccess = createAction('[DGI] Load DGIs Success', props<{ dgiDate?: string }>());
export const loadDGIError = createAction('[DGI] Load DGIs Error');

export const loadLastDGI = createAction('[DGI] Load last DGI');
export const loadLastDGISuccess = createAction('[DGI] Load last DGIs Success', props<{ dgiDate?: string }>());
export const loadLastDGIError = createAction('[DGI] Load last DGIs Error');

export const loadDGIResults = createAction('[DGI] Load DGI results', props<{ dgiDate: string }>());
export const loadDGIResultsSuccess = createAction('[DGI] Load DGI results success', props<{ dgiResults: DGIResult[] }>());
export const loadDGIResultsFailure = createAction('[DGI] Load DGI results failure', props<{ error: string}>());

export const openDGI = createAction('[DGI] Open DGI', props<{ dgiDate: string }>());
export const openDGISuccess = createAction('[DGI] Open DGI success', props<{ dgiLink: string, dgiDate: string }>());
export const openDGIFailure = createAction('[DGI] Open DGI failure');

export const downloadDGI = createAction('[DGI] Download DGI', props<{ dgiDate: string }>());
export const downloadDGISuccess = createAction('[DGI] Download DGI success', props<{ dgiLink: string, dgiDate: string }>());
export const downloadDGIFailure = createAction('[DGI] Download DGI failure');
export const getDGIFileFailure = createAction('[DGI] Get DGI file failure', props<{ error: string }>());

export const markDGIAsRead = createAction('[DGI] Mark DGI as read', props<{ dgiDate: string }>());
export const markDGIAsReadSuccess = createAction('[DGI] Mark DGI as read success');
export const markDGIAsReadFailure = createAction('[DGI] Mark DGI as read failure');