import {
  RequestStatus,
  TaskMapByServiceReceiver,
  TaskStatsItem,
  TaskStatsRange,
} from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';

import { TechnicalSheetByActivity } from '@nai-libs/data-access';

import * as TaskActions from './task.actions';

export const TASK_REDUCER_KEY = 'task';

export interface TaskState {
  tasks?: TaskMapByServiceReceiver;

  taskStatsDay?: TaskStatsItem[];
  taskStatsWeek?: TaskStatsItem[];
  taskStatsMonth?: TaskStatsItem[];

  taskStatsSelectedRange: TaskStatsRange;
  tasksStatsStatus: RequestStatus;

  dailyTasksStatus: RequestStatus;
  monthlyTasksStatus: RequestStatus;
  threeMonthTasksStatus: RequestStatus;
  markTaskStatus: RequestStatus;

  technicalSheets?: TechnicalSheetByActivity;
  technicalSheetsStatus: RequestStatus;

  taskFormNotification?: string;
  taskFormNotificationStatus: RequestStatus;
}

const initalState: TaskState = {
  taskStatsSelectedRange: 'day',
  dailyTasksStatus: { pending: false },
  monthlyTasksStatus: { pending: false },
  threeMonthTasksStatus: { pending: false },
  markTaskStatus: { pending: false },
  tasksStatsStatus: { pending: false },
  technicalSheetsStatus: { pending: false },
  taskFormNotificationStatus: { pending: false },
};

export const taskReducer = createReducer(
  initalState,
  on(TaskActions.loadDailyTasks, (state) => ({
    ...state,
    dailyTasksStatus: { pending: true },
  })),
  on(TaskActions.loadDailyTasksSuccess, (state, { tasks }) => {
    const newTasks: TaskMapByServiceReceiver = state.tasks
      ? JSON.parse(JSON.stringify(state.tasks))
      : {};

    if (!newTasks[tasks['client-phone']]) {
      newTasks[tasks['client-phone']] = [tasks];
    } else {
      const index = newTasks[tasks['client-phone']].findIndex(
        (taskMap) => taskMap.date.date === tasks.date.date
      );
      if (index >= 0) {
        newTasks[tasks['client-phone']][index] = tasks;
      } else {
        newTasks[tasks['client-phone']].push(tasks);
      }
    }

    return {
      ...state,
      tasks: newTasks,
      dailyTasksStatus: { pending: false },
    };
  }),
  on(TaskActions.loadDailyTasksFailure, (state) => ({
    ...state,
    dailyTasksStatus: { pending: false },
  })),

  on(TaskActions.loadThreeMonthTasks, (state) => ({
    ...state,
    threeMonthTasksStatus: { pending: true },
  })),

  on(TaskActions.loadMonthlyTasksSuccess, (state, { tasks }) => {
    const newTasks: TaskMapByServiceReceiver = state.tasks
      ? JSON.parse(JSON.stringify(state.tasks))
      : {};

    tasks.forEach((taskMapIn) => {
      if (!newTasks[taskMapIn['client-phone']]) {
        newTasks[taskMapIn['client-phone']] = [taskMapIn];
      } else {
        const index = newTasks[taskMapIn['client-phone']].findIndex(
          (taskMap) => taskMap.date.date === taskMapIn.date.date
        );
        if (index >= 0) {
          newTasks[taskMapIn['client-phone']][index] = taskMapIn;
        } else {
          newTasks[taskMapIn['client-phone']].push(taskMapIn);
        }
      }
    });

    return {
      ...state,
      tasks: newTasks,
      monthlyTasksStatus: { pending: false },
    };
  }),

  on(TaskActions.loadThreeMonthTasksSuccess, (state, { tasks }) => {
    const newTasks: TaskMapByServiceReceiver = state.tasks
      ? JSON.parse(JSON.stringify(state.tasks))
      : {};

    tasks.forEach((taskMapIn) => {
      if (!newTasks[taskMapIn['client-phone']]) {
        newTasks[taskMapIn['client-phone']] = [taskMapIn];
      } else {
        const index = newTasks[taskMapIn['client-phone']].findIndex(
          (taskMap) => taskMap.date.date === taskMapIn.date.date
        );
        if (index >= 0) {
          newTasks[taskMapIn['client-phone']][index] = taskMapIn;
        } else {
          newTasks[taskMapIn['client-phone']].push(taskMapIn);
        }
      }
    });

    return {
      ...state,
      tasks: newTasks,
      threeMonthTasksStatus: { pending: false },
    };
  }),
  on(TaskActions.loadThreeMonthTasksFailure, (state) => ({
    ...state,
    threeMonthTasksStatus: { pending: false },
  })),
  on(TaskActions.markTaskAsDone, (state) => ({
    ...state,
    markTaskStatus: { pending: true },
  })),
  on(TaskActions.markTaskAsDoneSuccess, (state) => ({
    ...state,
    markTaskStatus: { pending: false },
  })),
  on(TaskActions.markTaskAsDoneFailure, (state) => ({
    ...state,
    markTaskStatus: { pending: false },
  })),
  on(TaskActions.loadTechnicalSheets, (state) => ({
    ...state,
    technicalSheetsStatus: { pending: true },
  })),
  on(
    TaskActions.loadTechnicalSheetsSuccess,
    (state, { technicalSheetByActivity }) => {
      let newTechnicalSheets: TechnicalSheetByActivity = {};
      if (state.technicalSheets) {
        newTechnicalSheets = JSON.parse(JSON.stringify(state.technicalSheets));
      }

      for (const activityCode of Object.keys(technicalSheetByActivity)) {
        newTechnicalSheets[activityCode] =
          technicalSheetByActivity[activityCode];
      }

      return {
        ...state,
        technicalSheets: newTechnicalSheets,
        technicalSheetsStatus: { pending: false },
      };
    }
  ),
  on(TaskActions.loadTechnicalSheets, (state) => ({
    ...state,
    technicalSheetsStatus: { pending: false },
  })),

  // Task stats
  on(TaskActions.loadTaskStats, (state) => ({
    ...state,
    tasksStatsStatus: { pending: true },
  })),
  on(TaskActions.loadTaskStatsSuccess, (state, { tasksStats, range }) => {
    return {
      ...state,
      taskStatsDay: range === 'day' ? tasksStats : state.taskStatsDay,
      taskStatsWeek: range === 'week' ? tasksStats : state.taskStatsWeek,
      taskStatsMonth: range === 'month' ? tasksStats : state.taskStatsMonth,
      tasksStatsStatus: { pending: false },
    };
  }),
  on(TaskActions.loadTaskStatsFailure, (state) => ({
    ...state,
    tasksStatsStatus: { pending: false },
  })),
  on(TaskActions.setTaskStatsRange, (state, { taskStatsSelectedRange }) => ({
    ...state,
    taskStatsSelectedRange,
  })),

  on(TaskActions.loadTaskFormNotification, (state) => ({
    ...state,
    taskFormNotificationStatus: { pending: true },
  })),

  on(
    TaskActions.loadTaskFormNotificationSuccess,
    (state, { taskFormNotification }) => ({
      ...state,
      taskFormNotification,
      taskFormNotificationStatus: { pending: false },
    })
  ),
  on(TaskActions.loadTaskFormNotificationFailure, (state) => ({
    ...state,
    taskFormNotificationStatus: { pending: false },
  }))
);
