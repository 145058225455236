import { ForumSurvey, RequestStatus } from '@nai-libs/shared/data-access/src';
import { createReducer, on } from '@ngrx/store';

import * as ForumSurveyActions from './forum-surveys.actions';

export const FORUM_REDUCER_KEY = 'forum';

export interface ForumState {
  surveys?: ForumSurvey[];
  surveysStatus: RequestStatus;
  markAnswerStatus: RequestStatus;
  zaintzaQuiz?: ForumSurvey[];
  zaintzaQuizStatus: RequestStatus;
  markZaintzaQuizAnswerStatus: RequestStatus;
}

const initalState: ForumState = {
  surveysStatus: { pending: false },
  markAnswerStatus: { pending: false },
  zaintzaQuizStatus: { pending: false },
  markZaintzaQuizAnswerStatus: { pending: false },
};

export const ForumReducer = createReducer(
  initalState,
  on(ForumSurveyActions.loadSurveys, (state) => ({
    ...state,
    surveysStatus: { pending: true },
  })),
  on(ForumSurveyActions.loadSurveysSuccess, (state, { surveys }) => ({
    ...state,
    surveys,
    surveysStatus: { pending: false },
  })),
  on(ForumSurveyActions.loadSurveysFailure, (state) => ({
    ...state,
    surveysStatus: { pending: false },
  })),
  on(ForumSurveyActions.markAnswer, (state) => ({
    ...state,
    markAnswerStatus: { pending: true },
  })),
  on(ForumSurveyActions.markAnswerSuccess, (state) => ({
    ...state,
    markAnswerStatus: { pending: false },
  })),
  on(ForumSurveyActions.markAnswerFailure, (state) => ({
    ...state,
    markAnswerStatus: { pending: false },
  })),
  // ZaintzaQuiz

  on(ForumSurveyActions.loadZaintzaQuiz, (state) => ({
    ...state,
    zaintzaQuizStatus: { pending: true },
  })),
  on(ForumSurveyActions.loadZaintzaQuizSuccess, (state, { zaintzaQuiz }) => ({
    ...state,
    zaintzaQuiz,
    zaintzaQuizStatus: { pending: false },
  })),
  on(ForumSurveyActions.loadZaintzaQuizFailure, (state) => ({
    ...state,
    zaintzaQuizStatus: { pending: false },
  })),
  on(ForumSurveyActions.markZaintzaQuizAnswer, (state) => ({
    ...state,
    markZaintzaQuizAnswerStatus: { pending: true },
  })),
  on(ForumSurveyActions.markZaintzaQuizAnswerSuccess, (state) => ({
    ...state,
    markZaintzaQuizAnswerStatus: { pending: false },
  })),
  on(ForumSurveyActions.markZaintzaQuizAnswerFailure, (state) => ({
    ...state,
    markZaintzaQuizAnswerStatus: { pending: false },
  }))
);
