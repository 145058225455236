import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ConsultationState,
  CONSULTATION_REDUCER_KEY,
} from './consultation.reducer';

export const selectConsultationState = createFeatureSelector<ConsultationState>(
  CONSULTATION_REDUCER_KEY
);

export const selectCustomerSupportProfessionals = createSelector(
  selectConsultationState,
  (state) => state.customerSupportProfessionals
);
export const selectCustomerSupportProfessionalsStatusPending = createSelector(
  selectConsultationState,
  (state) => state.customerSupportProfessionalsStatus.pending
);

export const selectFaq = createSelector(
  selectConsultationState,
  (state) => state.faq
);

export const selectFaqPending = createSelector(
  selectConsultationState,
  (state) => state.faqStatus.pending
);

export const selectConsultRules = createSelector(
  selectConsultationState,
  (state) => state.consultRules
);

export const selectConsultHistory = createSelector(
  selectConsultationState,
  (state) => state.consultHistory
);
export const selectConsultHistoryPending = createSelector(
  selectConsultationState,
  (state) => state.consultHistoryStatus.pending
);

export const selectNewConsultationStatusPending = createSelector(
  selectConsultationState,
  (state) => state.newConsultationStatus.pending
);

export const selectNewResponseStatusPending = createSelector(
  selectConsultationState,
  (state) => state.newResponseStatus.pending
);

export const selectMarkConsultationAsReadPending = createSelector(
  selectConsultationState,
  (state) => state.newResponseStatus.pending
);

export const selectVideoconsultationAvailability = createSelector(
  selectConsultationState,
  (state) => state.videoconsultationAvailability
);
export const selectVideoconsultationAvailabilityPending = createSelector(
  selectConsultationState,
  (state) => state.videoconsultationAvailabilityStatus.pending
);

export const selectNewVideoconsultation = createSelector(
  selectConsultationState,
  (state) => state.newVideoconsultation
);
export const selectNewVideoconsultationPending = createSelector(
  selectConsultationState,
  (state) => state.newVideoconsultationStatus.pending
);
