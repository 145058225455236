import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  RespiroAppointment,
  RespiroAvailableAppointmentsByDay,
  RespiroAvailableAppointmentsResponse,
  RespiroConsumedHours,
  RespiroConsumedHoursResponse,
  RespiroContactData,
  SelectedUser,
  ServiceReceiver,
} from '@nai-libs/data-access';
import { DateUtilsService } from '@nai-libs/utility';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RespiroService {
  constructor(
    private http: HttpClient,
    private dateUtils: DateUtilsService,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  fetchAvailableAppointments(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    date: Date
  ): Observable<RespiroAvailableAppointmentsByDay> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      date: this.dateUtils.dateToYearMonthDayHyphen(date),
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<RespiroAvailableAppointmentsResponse>(
        `${this.env.apiUrl}services/respiro/available-spaces-7-days?${params}`
      )
      .pipe(
        map((response) => response['available-spaces']),
        catchError((err) => throwError(() => err))
      );
  }

  fetchConsumedHours(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    date: Date
  ): Observable<RespiroConsumedHours> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      year: date.getFullYear().toString(),
      month: this.dateUtils.getNumericMonthString(date),
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<RespiroConsumedHoursResponse>(
        `${this.env.apiUrl}services/respiro/used-hours-in-month?${params}`
      )
      .pipe(
        map((response) => ({
          [date.getMonth() + 1]: {
            selectedHours: 0,
            availableHours: 4 - response['used-hours'],
          },
        })),
        catchError((err) => throwError(() => err))
      );
  }

  saveRespiroRequest(
    appointment: RespiroAppointment[],
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    messages?: string[]
  ): Observable<string> {
    const interventions = appointment.map((appointment, index) => {
      const dateStart = new Date(appointment.start);
      const dateEnd = new Date(appointment.end);

      return {
        date: this.dateUtils.dateToYearMonthDayHyphen(dateStart),
        'hour-start': this.dateUtils.dateToHourMinuteColon(dateStart),
        'hour-end': this.dateUtils.dateToHourMinuteColon(dateEnd),
        comment: messages?.[index],
      };
    });

    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      interventions,
    };

    return this.http
      .post(
        `${this.env.apiUrl}services/respiro/save-respiro-interventions`,
        paramsObj,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  fetchContactData(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<RespiroContactData> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<RespiroContactData>(
        `${this.env.apiUrl}services/respiro/service-data?${params}`
      )
      .pipe(
        map((response) => response),
        catchError((err) => throwError(() => err))
      );
  }
}
