import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomTranslateService } from './custom-translate.service';

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService {
  // Miliseconds
  static ONE_HOUR = 1000 * 60 * 60;
  static ONE_DAY = DateUtilsService.ONE_HOUR * 24;

  static ISO_8601_DATE_TIME_REGEX =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/;
  static DD_MM_YYYY_DATE_REGEX =
    /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;

  constructor(
    private customTranslateService: CustomTranslateService,
    private translateService: TranslateService
  ) {}

  // DATE CONVERSIONS
  hyphenToWrittenDate(date: string) {
    const dateParts = date.split('-');
    const dateUTC = new Date(
      Date.UTC(
        parseInt(dateParts[0]),
        parseInt(dateParts[1]) - 1,
        parseInt(dateParts[2])
      )
    );

    const formatter = new Intl.DateTimeFormat(
      this.customTranslateService.currentLocale,
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
      }
    );
    return formatter.format(dateUTC);
  }

  UTCtoWrittenDate(utcDate: string) {
    const date = new Date(utcDate);
    const formatter = new Intl.DateTimeFormat(
      this.customTranslateService.currentLocale,
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
      }
    );
    return formatter.format(date);
  }

  UTCtoTimeslot(start: string, end: string) {
    const hoursStart = new Date(start).getHours().toString().padStart(2, '0');
    const minutesStart = new Date(start)
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const hoursEnd = new Date(end).getHours().toString().padStart(2, '0');
    const minutesEnd = new Date(end).getMinutes().toString().padStart(2, '0');
    return `${hoursStart}:${minutesStart} - ${hoursEnd}:${minutesEnd}`;
  }

  getWrittenTime(date: Date): string {
    return `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  }

  dateToMonthYear(date: Date) {
    const formatter = new Intl.DateTimeFormat(
      this.customTranslateService.currentLocale,
      {
        year: 'numeric',
        month: 'long',
      }
    );
    return formatter.format(date);
  }

  dateToDayMonthYear(date: Date) {
    const formatter = new Intl.DateTimeFormat(
      this.customTranslateService.currentLocale,
      {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }
    );
    return formatter.format(new Date(date.setHours(0, 0, 0, 0)));
  }

  dateToDayMonth(date: Date) {
    const formatter = new Intl.DateTimeFormat(
      this.customTranslateService.currentLocale,
      {
        day: 'numeric',
        month: 'long',
      }
    );
    return formatter.format(new Date(date.setHours(0, 0, 0, 0)));
  }

  dateToYearMonthDayHyphen(date: Date) {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return year + '-' + month + '-' + day;
  }
  dateToHourMinuteColon(date: Date) {
    return (
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2)
    );
  }

  /**
   * Input a text with this format "dd-mm-yyyy hh:mm", returns a Date object
   * @param text
   * @returns Date
   */
  formatDate(text: string): Date {
    if (
      text.match(
        /^([1-9]|([012][0-9])|(3[01]))-([0]{0,1}[1-9]|1[012])-\d\d\d\d [012]{0,1}[0-9]:[0-6][0-9]$/
      )
    ) {
      const splittedText = text.split(' ');
      const splittedDate = splittedText[0].split('-');
      const hour = splittedText[1];

      return new Date(
        splittedDate[2] +
          '-' +
          splittedDate[1] +
          '-' +
          splittedDate[0] +
          'T' +
          hour +
          ':00Z'
      );
    } else {
      throw '[FormatDate] Invalid input text format';
    }
  }

  /**
   * Input a text with this format "yyyy-mm-ddThh:mm:ss.sss+hh:mm", returns a Date object
   * @param text
   * @returns Date
   */
  formatDateTime(text: string): Date {
    try {
      return new Date(text.split('.')[0]);
    } catch {
      throw '[FormatDate] Invalid input text format';
    }
  }

  createDateFromDDMMYYYY(dateString: string) {
    const parts = dateString.split('/');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Months are 0-indexed (0 = January, 1 = February, ...)
      const year = parseInt(parts[2], 10);

      // Validate if the parts represent a valid date
      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        return new Date(year, month, day);
      }
    }
    return null; // Return null for invalid date strings
  }

  // VALIDATE DATES
  isValidISO8601String(dateTimeStr: string) {
    return DateUtilsService.ISO_8601_DATE_TIME_REGEX.test(dateTimeStr);
  }

  isValidDDMMYYYYString(dateStr: string) {
    return DateUtilsService.DD_MM_YYYY_DATE_REGEX.test(dateStr);
  }

  // OPERATE WITH DATES
  addMinutes(date: Date, minutes: number) {
    // * 60000 converts minutes to miliseconds
    return new Date(date.getTime() + minutes * 60000);
  }
  addDays(date: Date, days: number) {
    date.setDate(date.getDate() + days);
    return date;
  }

  // GET DATA FROM DATES
  getAgeByDOB(date: string) {
    const dobParts = date.split('-');
    const dob = new Date(
      `${parseInt(dobParts[1])}/${dobParts[2]}/${dobParts[0]}`
    );
    const diff = Date.now() - dob.getTime();
    const diffDate = new Date(diff);
    const year = diffDate.getUTCFullYear();
    return Math.abs(year - 1970);
  }
  getNumericMonthString(date: Date) {
    return ('0' + (date.getMonth() + 1)).slice(-2);
  }
  getDateFromMonth(monthNumber: number | string) {
    const date = new Date();
    date.setMonth(parseInt(monthNumber.toString()) - 1);
    return date;
  }
  getDatesInRange(startDate: Date, endDate: Date) {
    const dateArray = new Array();
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate = this.addDays(currentDate, 1);
    }
    return dateArray;
  }
  getNext30DaysDates() {
    const today = new Date();
    const lastDay = new Date();
    lastDay.setDate(today.getDate() + 29);
    return this.getDatesInRange(today, lastDay);
  }

  isInUTCTimeslot(start: string, end: string, date: Date) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return (
      date.getTime() >= startDate.getTime() &&
      date.getTime() <= endDate.getTime()
    );
  }

  getAppointmentSchedule(dateUTC: string, mode: 'short' | 'long'): string {
    const date = new Date(dateUTC);
    const hoursStart = date.getHours().toString().padStart(2, '0');
    const minutesStart = date.getMinutes().toString().padStart(2, '0');
    const startTimeFormatted = `${hoursStart}:${minutesStart} h`;
    if (this.isTodayOrTomorrow(dateUTC) === 'today') {
      return (
        this.translateService.instant('DATE_UTILS.TODAY') +
        ', ' +
        startTimeFormatted
      );
    } else if (this.isTodayOrTomorrow(dateUTC) === 'tomorrow') {
      return (
        this.translateService.instant('DATE_UTILS.TOMORROW') +
        ', ' +
        startTimeFormatted
      );
    }
    let weekday =
      mode === 'long'
        ? new Intl.DateTimeFormat(this.customTranslateService.currentLocale, {
            weekday: 'long',
          }).format(date)
        : new Intl.DateTimeFormat(this.customTranslateService.currentLocale, {
            weekday: 'short',
          })
            .format(date)
            .substring(0, 2) + '.';
    weekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
    const day = Intl.DateTimeFormat(this.customTranslateService.currentLocale, {
      day: 'numeric',
    }).format(date);
    return `${weekday} ${day}, ${startTimeFormatted}`;
  }

  nightOrDay(dateStartUTC: string): 'night' | 'day' {
    const dateStart = new Date(dateStartUTC);
    if (dateStart.getHours() < 6 || dateStart.getHours() > 20) {
      return 'night';
    }
    return 'day';
  }

  UTCTodayTomorrowOrWeekday(dateUTC: string): string {
    if (this.isTodayOrTomorrow(dateUTC) === 'today') {
      return this.translateService.instant('DATE_UTILS.TODAY');
    } else if (this.isTodayOrTomorrow(dateUTC) === 'tomorrow') {
      return this.translateService.instant('DATE_UTILS.TOMORROW');
    }
    const date = new Date(dateUTC).setHours(0, 0, 0, 0);
    const weekday = new Intl.DateTimeFormat(
      this.customTranslateService.currentLocale,
      {
        weekday: 'long',
      }
    ).format(date);

    return weekday.charAt(0).toUpperCase() + weekday.slice(1);
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  }

  isTomorrow(date: Date): boolean {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + 1);

    return (
      date.getDate() == newDate.getDate() &&
      date.getMonth() == newDate.getMonth() &&
      date.getFullYear() == newDate.getFullYear()
    );
  }

  isTodayOrTomorrow(dateUTC: string): 'today' | 'tomorrow' | null {
    const date: Date = new Date(dateUTC);

    if (this.isToday(date)) {
      return 'today';
    } else if (this.isTomorrow(date)) {
      return 'tomorrow';
    }
    return null;
  }

  getDifferenceInHours(date1: Date, date2: Date): number {
    return (
      Math.abs(date1.getTime() - date2.getTime()) / DateUtilsService.ONE_HOUR
    );
  }

  getDifferenceInDays(date1: Date, date2: Date): number {
    return (
      Math.abs(date1.getTime() - date2.getTime()) / DateUtilsService.ONE_DAY
    );
  }


  decimalHoursToHoursMinutes(hoursDecimal: number) {
    const hours = Math.floor(hoursDecimal);
    const minutes = Math.round(((hoursDecimal - hours) * 6000) / 100);
    return { hours, minutes };
  }
}
