import {
  ConsultHistory,
  ConsultOpeningRulesResponse,
  CustomerSupportProfessional,
  FaqTopic,
  NewVideoconsultation,
  VideoconsultationAvailability,
} from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

export const getCustomerSupportProfessionals = createAction(
  '[Consultation] Get customer support professionals'
);
export const getCustomerSupportProfessionalsSuccess = createAction(
  '[Consultation] Get customer support professionals Success',
  props<{ customerSupportProfessionals: CustomerSupportProfessional[] }>()
);
export const getCustomerSupportProfessionalsFailure = createAction(
  '[Consultation] Get customer support professionals Failure'
);

// FAQS

export const loadFaq = createAction('[Help] Load FAQ');
export const loadFaqSuccess = createAction(
  '[Help] Load FAQ Success',
  props<{ faq: FaqTopic[] }>()
);
export const loadFaqFailure = createAction('[Help] Load FAQ Failure');

// Consultations

export const loadConsultOpeningRules = createAction(
  '[Help] Load consult opening rules'
);
export const loadConsultOpeningRulesSuccess = createAction(
  '[Help] Load consult opening rules Success',
  props<{ consultRules: ConsultOpeningRulesResponse }>()
);
export const loadConsultOpeningRulesFailure = createAction(
  '[Help] Load consult opening rules Failure'
);

export const loadConsultHistory = createAction(
  '[Help] Load familiar professional consultations'
);
export const loadConsultHistorySuccess = createAction(
  '[Help] Load familiar professional consultations Success',
  props<{ consultHistory: ConsultHistory }>()
);
export const loadConsultHistoryFailure = createAction(
  '[Help] Load familiar professional consultations Failure'
);

export const newConsultation = createAction(
  '[Help] New consultation',
  props<{
    consultArea: string;
    consultSubarea: string;
    description: string;
    professionalEID: number;
  }>()
);
export const newConsultationSuccess = createAction(
  '[Help] New consultation Success'
);
export const newConsultationFailure = createAction(
  '[Help] New consultation Failure'
);

export const newResponse = createAction(
  '[Help] New response',
  props<{ consultEID: string; consultComment: string }>()
);
export const newResponseSuccess = createAction(
  '[Help] New response Success',
  props<{ consultEID: string }>()
);
export const newResponseFailure = createAction('[Help] New response Failure');

export const markConsultationAsRead = createAction(
  '[Help] Mark consultation as read',
  props<{ consultEID: string }>()
);
export const markConsultationAsReadSuccess = createAction(
  '[Help] Mark consultation as read Success'
);
export const markConsultationAsReadFailure = createAction(
  '[Help] Mark consultation as read Failure'
);

// Videoconsultations

export const getVideoconsultationAvailability = createAction(
  '[Help] Get videoconsultation availability',
  props<{ lastAvailableDate?: Date }>()
);
export const getVideoconsultationAvailabilitySuccess = createAction(
  '[Help] Get videoconsultation availability Success',
  props<{ availability: VideoconsultationAvailability }>()
);
export const getVideoconsultationAvailabilityFailure = createAction(
  '[Help] Get videoconsultation availability Failure'
);

export const newVideoconsultation = createAction(
  '[Help] New videoconsultation ',
  props<{ intervention: NewVideoconsultation }>()
);
export const newVideoconsultationSuccess = createAction(
  '[Help] New videoconsultation Success'
);
export const newVideoconsultationFailure = createAction(
  '[Help] New videoconsultation Failure'
);

export const resetNewVideoconsultation = createAction(
  '[Help] Reset new videoconsultation'
);
export const setNewVideoconsultationDate = createAction(
  '[Help] Set new videoconsultation date',
  props<{ date: Date }>()
);
export const setNewVideoconsultationPrice = createAction(
  '[Help] Set new videoconsultation price',
  props<{ price: number }>()
);
export const setNewVideoconsultationReason = createAction(
  '[Help] Set new videoconsultation reason',
  props<{ reason: string }>()
);
export const setNewVideoconsultationData = createAction(
  '[Help] Set new videoconsultation data',
  props<{ attendeeEmail: string; attendeePhone: string }>()
);

export const sendConsultationEmail = createAction(
  '[Help] Send consultation email',
  props<{ receiverEmail: string; receiverName: string; message: string }>()
);

export const sendConsultationEmailSuccess = createAction(
  '[Help] Send consultation email success'
);

export const sendConsultationEmailFailure = createAction(
  '[Help] Send consultation email failure'
);

export const restoreInitialState = createAction('[Help] Restore initial state');
