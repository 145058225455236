import {
  RespiroAppointment,
  RespiroAvailableAppointmentsByDay,
  RespiroConsumedHours,
  RespiroContactData,
} from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

export const loadAvailableAppointments = createAction(
  '[Respiro] Load available appointments',
  props<{ date: Date }>()
);
export const loadAvailableAppointmentsSuccess = createAction(
  '[Respiro] Load available appointments Success',
  props<{ availableAppointmentsByDay: RespiroAvailableAppointmentsByDay }>()
);
export const loadAvailableAppointmentsError = createAction(
  '[Respiro] Load available appointments Error'
);

export const loadConsumedHoursThisMonth = createAction(
  '[Respiro] Load consumed hours this month'
);

export const loadConsumedHours = createAction(
  '[Respiro] Load consumed hours',
  props<{ dates: Date[], reset?: boolean }>()
);
export const loadConsumedHoursSuccess = createAction(
  '[Respiro] Load consumed hours Success',
  props<{ consumedHours: RespiroConsumedHours, reset?: boolean }>()
);
export const loadConsumedHoursError = createAction(
  '[Respiro] Load consumed hours Error'
);

export const selectAppointment = createAction(
  '[Respiro] Select appointment',
  props<{ appointment: RespiroAppointment; selected: boolean }>()
);

export const setFormAppointments = createAction(
  '[Respiro] Set form appointments',
  props<{ appointments: RespiroAppointment[] }>()
);

export const setFormMessage = createAction(
  '[Respiro] Set form message',
  props<{ messages: string[] }>()
);

export const saveRespiroRequest = createAction(
  '[Respiro] Save respiro request'
);

export const saveRespiroRequestSuccess = createAction(
  '[Respiro] Save respiro request success'
);

export const saveRespiroRequestError = createAction(
  '[Respiro] Save respiro request error'
);

export const resetRespiroForm = createAction('[Respiro] Reset respiro form');

export const incrementLoadCounter = createAction(
  '[Respiro] Increment load counter'
);

export const resetLoadCounter = createAction('[Respiro] Reset load counter');

export const loadContactData = createAction('[Respiro] Load contact data');

export const loadContactDataSuccess = createAction(
  '[Respiro] Load contact data success',
  props<{ contactData: RespiroContactData }>()
);

export const loadContactDataError = createAction(
  '[Respiro] Load contact data error'
);

export const resetAvailableAppointments = createAction(
  '[Respiro] Reset available appointments'
);
