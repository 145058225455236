import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {NoticeBoardEffects } from './+state/notice-board.effects';
import { NOTICE_BOARD_REDUCER_KEY, noticeBoardReducer } from './+state/notice-board.reducer';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(NOTICE_BOARD_REDUCER_KEY, noticeBoardReducer),
    EffectsModule.forFeature([NoticeBoardEffects])
  ],
})
export class NoticeBoardDataAccessModule {}
