import { Token } from "@capacitor/push-notifications";
import { createReducer, on } from "@ngrx/store";
import * as PushNotificationActions from "./push-notification.actions";

export const PUSH_NOTIFICATIONS_REDUCER_KEY = 'push-notifications';

export interface PushNotificationState {
  currentDeviceToken?: Token;
}

const initialState: PushNotificationState = {
  currentDeviceToken: undefined,
}

export const pushNotificationReducer = createReducer(
  initialState,
  on(PushNotificationActions.registerToken, (state, {token}) => ({ ...state, currentDeviceToken: token}))
)