import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NOTIFICATION_REDUCER_KEY,
  NotificationState,
} from './notification.reducer';

export const selectNotificationsState =
  createFeatureSelector<NotificationState>(NOTIFICATION_REDUCER_KEY);

export const selectNotificationsPending = createSelector(
  selectNotificationsState,
  (state) => state.notificationsStatus.pending
);

export const selectNotifications = createSelector(
  selectNotificationsState,
  (state) => state.notifications
);

export const saveReadNotificationsPending = createSelector(
  selectNotificationsState,
  (state) => state.saveReadNotificationStatus.pending
);

export const selectNotificationHistory = createSelector(
  selectNotificationsState,
  (state) => state.notificationHistory
);

export const selectNotificationHistoryStatusPending = createSelector(
  selectNotificationsState,
  (state) => state.notificationHistoryStatus.pending
);

export const selectNotificationPageTypeFilter = createSelector(
  selectNotificationsState,
  (state) => state.notificationPageFilters?.type
);

export const selectNotificationPageDateFilter = createSelector(
  selectNotificationsState,
  (state) => state.notificationPageFilters?.date
);

export const selectNotificationPagePagination = createSelector(
  selectNotificationsState,
  (state) => state.notificationPageFilters?.page
);
