import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'nai-password-reset-success',
  template: `
    <div class="page">
      <div class="wrapper-fluid">
        <span
          naiDynIcon
          [overrideColor]="false"
          [path]="'assets/icons/ok-check.svg'"
        ></span>
        <h1>¡Genial!</h1>
        <p>Contraseña cambiada correctamente.</p>
        <div class="actions-wrapper">
          <a
            [routerLink]="['/auth/login']"
            class="button button--outline button--primary"
            [mat-dialog-close]
            >Volver a iniciar sesión</a
          >
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./password-reset-success-page.component.scss'],
})
export class PasswordResetSuccessPageComponent {}
