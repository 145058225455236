import { Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { _isTestEnvironment } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class CustomOverlayContainer extends OverlayContainer {
  appOverlayContainerClass = 'app-cdk-overlay-container';

  /**
   * Append the OverlayContainer to the custom wrapper element
   */
  public appendToCustomWrapper(wrapperElement: HTMLElement): void {
    if (wrapperElement === null) {
      return;
    }
    
    // this._containerElement is 'cdk-overlay-container'
    if (!this._containerElement) {
      super._createContainer();
    }

    // add a custom css class to the 'cdk-overlay-container' for styling purposes
  this._containerElement.classList.add(this.appOverlayContainerClass);
   
    // attach the 'cdk-overlay-container' to our custom wrapper
    wrapperElement.appendChild(this._containerElement);
  }

  /**
   * Remove the OverlayContainer from the custom element and append it to body
   */
  public appendToBody(): void {
    if (!this._containerElement) {
     return;
    }

    // remove the custom css class from the 'cdk-overlay-container'
    this._containerElement.classList.remove(this.appOverlayContainerClass);

    // re-attach the 'cdk-overlay-container' to body
    this._document.body.appendChild(this._containerElement);
  }

  protected override _createContainer(): void {
    const containerClass = 'cdk-overlay-container';

    if (this._platform.isBrowser || _isTestEnvironment()) {
      const oppositePlatformContainers = this._document.querySelectorAll(
        `.${containerClass}[platform="server"], ` +
          `.${containerClass}[platform="test"]`
      );

      // Remove any old containers from the opposite platform.
      // This can happen when transitioning from the server to the client.
      for (let i = 0; i < oppositePlatformContainers.length; i++) {
        oppositePlatformContainers[i].remove();
      }
    }

    const container = this._document.createElement('div');
    container.classList.add(containerClass);

    if (_isTestEnvironment()) {
      container.setAttribute('platform', 'test');
    } else if (!this._platform.isBrowser) {
      container.setAttribute('platform', 'server');
    }
    const customWrapper = document.getElementById('custom-overlay-container');
    if (customWrapper) {
      customWrapper.appendChild(container);
    } else {
      this._document.body.appendChild(container);
    }

    this._containerElement = container;
  }
}
