import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomLocaleId } from './custom-locale-id.provider';

@Injectable({
  providedIn: 'root',
})
export class CustomMatDateAdapter extends NativeDateAdapter {
  constructor(
    matDateLocale: CustomLocaleId,
    public translateService: TranslateService
  ) {
    super(matDateLocale.toString());
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }

  override getDayOfWeekNames(): string[] {
    return [
      this.translateService.instant('SCHEDULE.NAV_WEEK.WEEK_LABELS.SUNDAY'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.WEEK_LABELS.MONDAY'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.WEEK_LABELS.TUESDAY'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.WEEK_LABELS.WEDNESDAY'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.WEEK_LABELS.THURSDAY'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.WEEK_LABELS.FRIDAY'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.WEEK_LABELS.SATURDAY'),
    ];
  }

  override getMonthNames(): string[] {
    return [
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.JANUARY'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.FEBRUARY'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.MARCH'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.APRIL'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.MAY'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.JUNE'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.JULY'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.AUGUST'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.SEPTEMBER'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.OCTOBER'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.NOVEMBER'),
      this.translateService.instant('SCHEDULE.NAV_WEEK.MONTH_LABELS.DECEMBER'),
    ];
  }
}
