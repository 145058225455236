import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '@nai-libs/utility';

@Component({
  selector: 'nai-new-password-form',
  template: `
    <form [formGroup]="changePassForm" (submit)="handleSubmit($event)">
      <mat-form-field appearance="fill" class="input-condition">
        <input
          matInput
          placeholder="Nueva contraseña"
          formControlName="newPassword"
          [type]="hide ? 'password' : 'text'"
        />
        <button
          type="button"
          class="button button--clear-neutral button--icon button--suffix"
          matSuffix
          (click)="hide = !hide"
        >
          <span *ngIf="hide" naiDynIcon [path]="'assets/icons/view.svg'"></span>
          <span
            *ngIf="!hide"
            naiDynIcon
            [path]="'assets/icons/hide.svg'"
          ></span>
        </button>
      </mat-form-field>
      <div class="password-requirements">
        <p>La contraseña debe tener mínimo:</p>
        <ul>
          <li
            [ngClass]="{
              check: isRequiredLength(
                changePassForm.controls['newPassword'].value ?? ''
              )
            }"
          >
            <span naiDynIcon path="assets/icons/shield.svg"></span>4 caracteres
          </li>
          <li
            [ngClass]="{
              check: hasRequiredChar(
                changePassForm.controls['newPassword'].value ?? ''
              )
            }"
          >
            <span naiDynIcon path="assets/icons/shield.svg"></span>Letras
          </li>
          <li
            [ngClass]="{
              check: hasRequiredDigit(
                changePassForm.controls['newPassword'].value ?? ''
              )
            }"
          >
            <span naiDynIcon path="assets/icons/shield.svg"></span>Números
          </li>
        </ul>
      </div>

      <mat-form-field appearance="fill">
        <input
          matInput
          placeholder="Repite la nueva contraseña"
          formControlName="newPasswordRepeat"
          [type]="hideRepeat ? 'password' : 'text'"
        />
        <button
          type="button"
          class="button button--clear-neutral button--icon button--suffix"
          matSuffix
          (click)="hideRepeat = !hideRepeat"
        >
          <span
            *ngIf="hideRepeat"
            naiDynIcon
            [path]="'assets/icons/view.svg'"
          ></span>
          <span
            *ngIf="!hideRepeat"
            naiDynIcon
            [path]="'assets/icons/hide.svg'"
          ></span>
        </button>
        <mat-error
          *ngIf="
            changePassForm.controls['newPasswordRepeat'].hasError('required')
          "
        >
          El campo es obligatorio
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="newPasswordError">
        {{ newPasswordError }}
      </mat-error>
      <mat-error
        *ngIf="
          changePassForm.errors && changePassForm.errors['matches'] === false
        "
      >
        Las contraseñas no coinciden
      </mat-error>

      <div class="actions-wrapper">
        <button
          [disabled]="!changePassForm.valid && newPasswordPending"
          type="submit"
          class="button"
        >
          Guardar
        </button>
      </div>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./new-password-form.component.scss'],
})
export class NewPasswordFormComponent {
  @Input() newPasswordPending: boolean | null = false;
  @Input() newPasswordError: string | null = '';

  @Output() formSubmit = new EventEmitter<string>();

  hide = true;
  hideRepeat = true;

  changePassForm = new FormGroup(
    {
      newPassword: new FormControl('', [
        Validators.required,
        CustomValidators.hasRequirements(),
      ]),
      newPasswordRepeat: new FormControl('', [Validators.required]),
    },
    {
      validators: CustomValidators.mustMatch(
        'newPassword',
        'newPasswordRepeat'
      ),
    }
  );

  handleSubmit(ev: SubmitEvent) {
    ev.preventDefault();
    if (this.changePassForm.valid) {
      this.formSubmit.emit(this.changePassForm['controls'].newPassword.value ?? '');
    }
  }

  isRequiredLength(value: string) {
    return CustomValidators.isRequiredLength(value);
  }

  hasRequiredChar(value: string) {
    return CustomValidators.hasRequiredChar(value);
  }

  hasRequiredDigit(value: string) {
    return CustomValidators.hasRequiredDigit(value);
  }
}
