import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  APPOINTMENT_REDUCER_KEY,
  AppointmentState,
} from './appointment.reducer';

export const selectAppointmentState = createFeatureSelector<AppointmentState>(
  APPOINTMENT_REDUCER_KEY
);

export const selectUpcomingAppointmentsPending = createSelector(
  selectAppointmentState,
  (state) => state.upcomingAppointmentsStatus.pending
);
export const selectUpcomingAppointments = createSelector(
  selectAppointmentState,
  (state) => state.upcomingAppointments
);

export const selectAppointmentsByMonth = createSelector(
  selectAppointmentState,
  (state) => state.appointmentsByMonth
);

export const selectAppointmentsByMonthStatusPending = createSelector(
  selectAppointmentState,
  (state) => state.appointmentsByMonthStatus.pending
);

export const selectNextVideocalls = createSelector(
  selectAppointmentState,
  (state) => state.videocalls
);

export const selectVideoCallsPending = createSelector(
  selectAppointmentState,
  (state) => state.videocallsStatus.pending
);

export const selectThreeMonthAppointmentsStatusPending = createSelector(
  selectAppointmentState,
  (state) => state.threeMonthAppointmentsStatus.pending
);

export const selectModifyAppointmentAttendanceStatusPending = createSelector(
  selectAppointmentState,
  (state) => state.modifyAppointmentStatus.pending
);
