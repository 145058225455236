import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AppConfig,
  HomeAccessibilityLastResponse,
  HomeAccessibilityNotificationResponse,
  HomeAccessibilityNotificationSaveReadResponse,
  HomeAccessibilityResponse,
  SelectedUser,
  ServiceReceiver,
  User,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

import { APP_CONFIG } from '@nai-libs/app-config';

@Injectable({ providedIn: 'root' })
export class HomeAccessibilityService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  /**
   * Método para obtener las notificaciones de adecuación de vivienda
   * @returns Observable<string>
   */
  loadHomeAccessibilityNotification(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<HomeAccessibilityNotificationResponse>(
        `${this.env.apiUrl}health/notifications/adecuacion-vivienda?${params}`
      )
      .pipe(
        map((response) => response['VV'][0]['answers-date']),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Método para marcar la notificación como leída (la notificación que se ha obtenido previamente mediante loadHomeAccessibilityNotification())
   * @returns Observable<boolean>
   */
  saveReadHomeAccessibilityNotification(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    date: string
  ): Observable<boolean> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
      'vv-date': date,
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<HomeAccessibilityNotificationSaveReadResponse>(
        `${this.env.apiUrl}health/adecuacion-vivienda?${params}`
      )
      .pipe(
        map((response) => response['status'] === 200),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Método para obtener la fecha del último informe de adecuación de vivienda
   * @returns Observable<Date>
   */
  loadHomeAccessibilityLast(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Date> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<HomeAccessibilityLastResponse>(
        `${this.env.apiUrl}health/last-adecuacion-vivienda?${params}`
      )
      .pipe(
        map((response) => response['last-vv-date']),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Método para obtener las el informe de adecuación de vivienda
   * @returns Observable<HomeAccessibilityResponse>
   */
  loadHomeAccessibility(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<HomeAccessibilityResponse> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<HomeAccessibilityResponse>(
        `${this.env.apiUrl}health/adecuacion-vivienda?${params}`
      )
      .pipe(
        map(
          (response) =>
            //response.results.length > 0 ? response.results : []
            response
        ),
        catchError((err) => throwError(() => err))
      );
  }
}
