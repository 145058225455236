import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SummaryItemComponent } from './summary-item.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SummaryItemComponent],
  exports: [SummaryItemComponent],
})
export class SummaryItemModule {}
