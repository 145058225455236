import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ArticleResource,
  ArticleResourceResponse,
  FormativeArea,
  FormativeAreaResponse,
  FormativeArticle,
  FormativeArticlesResponse,
  GlossaryWord,
  GlossaryWordResponse,
  SelectedUser,
  ServiceReceiver,
  SpecificArticle,
  SpecificFormativeArea,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, User } from '@nai-libs/data-access';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  /**
   * Método para obtener los artículos de interés de un cliente
   * @return Observable<FormativeArticle[]>
   */
  getFormativeArticles(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<FormativeArticle[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<FormativeArticlesResponse>(
        `${this.env.apiUrl}health/fetch-three-formative-articles?${params}`
      )
      .pipe(
        map((response) =>
          response['formative-articles'].length > 0
            ? response['formative-articles']
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  getFormativeAreas(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<FormativeArea[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };
    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<FormativeAreaResponse>(
        `${this.env.apiUrl}health/fetch-applicable-formative-areas?${params}`
      )
      .pipe(
        map((res) => (res['formative-areas'] ? res['formative-areas'] : [])),
        catchError((err) => throwError(() => err))
      );
  }

  getArea(
    id: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<SpecificFormativeArea> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': id,
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<SpecificFormativeArea>(
        `${this.env.apiUrl}health/fetch-specific-formative-area?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  getArticle(
    id: string,
    area: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<SpecificArticle> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': area,
      'article-code': id,
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<SpecificArticle>(
        `${this.env.apiUrl}health/fetch-specific-formative-area-article?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  getArticleResources(
    id: string,
    area: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<ArticleResource[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': area,
      'article-code': id,
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<ArticleResourceResponse>(
        `${this.env.apiUrl}health/fetch-specific-formative-area-article-resources?${params}`
      )
      .pipe(
        map((res) => res['article-resources']),
        catchError((err) => throwError(() => err))
      );
  }

  getGlossaryWord(
    NANDACode: string,
    articleCode: string,
    word: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<GlossaryWord> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': NANDACode,
      'article-code': articleCode,
      word,
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<GlossaryWordResponse>(
        `${this.env.apiUrl}health/fetch-formative-area-glossary-word?${params}`
      )
      .pipe(
        map((res) => res['glossary-word-requested']),
        catchError((err) => throwError(() => err))
      );
  }

  markArticleAsRead(
    NANDACode: string,
    articleCode: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': NANDACode,
      'article-code': articleCode,
      'familiar-email': user['logged-user']['e-mail'],
    };

    return this.http
      .post(
        `${this.env.apiUrl}documentation/save-read-formative-article`,
        paramsObj,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }
}
