import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { Appointment, AppointmentsByMonth } from '@nai-libs/data-access';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';
import * as AppointmentActions from './appointment.actions';
import * as AppointmentSelectors from './appointment.selectors';

import { AppointmentService } from './appointment.service';

@Injectable()
export class AppointmentEffects {
  constructor(
    private actions$: Actions,
    private appointmentService: AppointmentService,
    private store: Store
  ) {}

  loadUpcomingAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.loadUpcomingAppointments),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        return this.appointmentService
          .getUpcomingAppointments(serviceReceiver, selectedUser)
          .pipe(
            map((appointments: Appointment[]) =>
              AppointmentActions.loadUpcomingAppointmentsSuccess({
                appointments,
              })
            ),
            catchError(() =>
              of(AppointmentActions.loadUpcomingAppointmentsFailure())
            )
          );
      })
    )
  );
  loadVideoCalls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.loadVideocalls),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(AppointmentSelectors.selectNextVideocalls)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, serviceReceiver, selectedUser, videocalls]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        if (videocalls)
          return of(AppointmentActions.loadVideocallsSuccess({ videocalls }));
        return this.appointmentService
          .getNextVideoCalls(serviceReceiver, selectedUser)
          .pipe(
            map((videocalls: Appointment[]) =>
              AppointmentActions.loadVideocallsSuccess({ videocalls })
            ),
            catchError(() => of(AppointmentActions.loadVideocallsFailure()))
          );
      })
    )
  );

  loadThreeMonthAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.loadThreeMonthAppointments),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());

        const current = new Date();
        current.setDate(1); // Avoid wrong dates in last month days
        const past = new Date(current);
        past.setMonth(past.getMonth() - 1);
        const next = new Date(current);
        next.setMonth(next.getMonth() + 1);

        return forkJoin([
          this.appointmentService.getAppointmentsByMonth(
            current,
            serviceReceiver,
            selectedUser
          ),
          this.appointmentService.getAppointmentsByMonth(
            past,
            serviceReceiver,
            selectedUser
          ),
          this.appointmentService.getAppointmentsByMonth(
            next,
            serviceReceiver,
            selectedUser
          ),
        ]).pipe(
          map((appointmentsByMonth: AppointmentsByMonth[]) =>
            AppointmentActions.loadThreeMonthAppointmentsSuccess({
              appointmentsByMonth,
            })
          ),
          catchError(() =>
            of(AppointmentActions.loadThreeMonthAppointmentsFailure())
          )
        );
      })
    )
  );

  loadAppointmentsByMonth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.loadAppointmentsByMonth),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ month }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        return this.appointmentService
          .getAppointmentsByMonth(month, serviceReceiver, selectedUser)
          .pipe(
            map((appointmentsByMonth: AppointmentsByMonth) =>
              AppointmentActions.loadAppointmentsByMonthSuccess({
                appointmentsByMonth,
              })
            ),
            catchError(() =>
              of(AppointmentActions.loadAppointmentsByMonthFailure())
            )
          );
      })
    )
  );

  loadAppointmentsByDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.loadAppointmentsByDay),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ date }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        return this.appointmentService
          .fetchAppointmentsByDay(date, serviceReceiver, selectedUser)
          .pipe(
            map((appointments: Appointment[]) =>
              AppointmentActions.loadAppointmentsByDaySuccess({
                appointments,
              })
            ),
            catchError(() =>
              of(AppointmentActions.loadAppointmentsByDayFailure())
            )
          );
      })
    )
  );

  checkAndFetchMonth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.checkAndFetchMonth),
      withLatestFrom(
        this.store.select(AppointmentSelectors.selectAppointmentsByMonth)
      ),
      map(([{ month }, appointments]) => {
        const isFetched =
          appointments?.find((appointmentsByMonth) => {
            return (
              parseInt(appointmentsByMonth.month) === month.getMonth() + 1 &&
              parseInt(appointmentsByMonth.year) === month.getFullYear()
            );
          }) !== undefined;

        if (!isFetched) {
          return AppointmentActions.loadAppointmentsByMonth({ month });
        }
        return AppointmentActions.monthAlreadyFetched();
      })
    )
  );

  modifyAppointmentAttendance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.modifyAppointmentAttendance),
      withLatestFrom(this.store.select(UserSelectors.selectServiceReceiver)),
      switchMap(([{ accept, appointment }, serviceReceiver]) => {
        if (!serviceReceiver) return of(UserActions.logout());
        return this.appointmentService
          .modifyAppointmentAttendance(
            accept,
            appointment['attendee-eid'] ?? 0,
            serviceReceiver
          )
          .pipe(
            map((response) =>
              response['success?']
                ? AppointmentActions.modifyAppointmentAttendanceSuccess({
                    appointment,
                  })
                : AppointmentActions.modifyAppointmentAttendanceFailure({
                    error: 'The resquest responded unsuccessfully',
                  })
            ),
            catchError((error) =>
              of(
                AppointmentActions.modifyAppointmentAttendanceFailure({ error })
              )
            )
          );
      })
    )
  );

  modifyAppointmentAttendanceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppointmentActions.modifyAppointmentAttendanceSuccess),
      map(({ appointment }) => {
        return AppointmentActions.loadAppointmentsByDay({
          date: new Date(appointment.start),
        });
      })
    )
  );
}
