import {
  DocumentCategory,
  DocumentsByType,
  NewService,
} from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

// New service
export const loadNewServices = createAction('[Document] Load new services');
export const loadNewServicesSuccess = createAction(
  '[Document] Load new services success',
  props<{ newServices: NewService[] }>()
);
export const loadNewServicesFailure = createAction(
  '[Document] Load new services error'
);

export const openNewServiceDoc = createAction(
  '[Document] Open new service doc',
  props<{ offerNumber: string }>()
);
export const openNewServiceDocSuccess = createAction(
  '[Document] Open new service doc success',
  props<{ offerLink?: string }>()
);
export const openNewServiceDocFailure = createAction(
  '[Document] Open new service doc failure'
);

export const countServices = createAction('[Document] Count Services');
export const countServicesSuccess = createAction(
  '[Document] Count Services Success',
  props<{ count: number }>()
);
export const countServicesFailure = createAction(
  '[Document] Count Services failure'
);

// Documents
export const loadDocuments = createAction(
  '[Document] Load Documents',
  props<{ categories: Array<DocumentCategory>; forceFetch?: boolean }>()
);
export const loadDocumentsSuccess = createAction(
  '[Document] Load Documents Success',
  props<{ documents: DocumentsByType }>()
);
export const loadDocumentsFailure = createAction(
  '[Document] Load Documents Failure',
  props<{ error: string }>()
);

export const loadDocumentLink = createAction(
  '[Document] Load document link',
  props<{ id: string }>()
);
export const loadDocumentLinkSuccess = createAction(
  '[Document] Load document link success',
  props<{ link: string; id: string }>()
);
export const loadDocumentLinkFailure = createAction(
  '[Document] Load document link failure'
);

export const openDocument = createAction(
  '[Document] Open document',
  props<{ id: string; category: DocumentCategory }>()
);
export const openDocumentSuccess = createAction(
  '[Document] Open document success',
  props<{ link: string; id: string; category: DocumentCategory }>()
);
export const openDocumentFailure = createAction(
  '[Document] Open document failure'
);

export const downloadDocument = createAction(
  '[Document] Download Document',
  props<{ id: string; category: DocumentCategory }>()
);
export const downloadDocumentSuccess = createAction(
  '[Document] Download Document success',
  props<{ link: string; id: string; category: DocumentCategory }>()
);
export const downloadDocumentFailure = createAction(
  '[Document] Download Document failure'
);

export const markDocumentAsRead = createAction(
  '[Document] Mark document as read',
  props<{ id: string; category: DocumentCategory }>()
);
export const markDocumentAsReadSuccess = createAction(
  '[Document] Mark document as read success'
);
export const markDocumentAsReadFailure = createAction(
  '[Document] Mark document as read failure'
);

export const signDocument = createAction(
  '[Document] Sign document',
  props<{
    id: string;
    signature: string;
    documentType: string;
    category: DocumentCategory;
  }>()
);
export const signDocumentSuccess = createAction(
  '[Document] Sign document success',
  props<{ category: DocumentCategory }>()
);
export const signDocumentFailure = createAction(
  '[Document] Sign document failure'
);

export const restoreInitialState = createAction(
  '[Document] Restore initial state'
);
