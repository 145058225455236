import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { respiroReducer, RESPIRO_REDUCER_KEY } from './+state/respiro.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RespiroEffects } from './+state/respiro.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(RESPIRO_REDUCER_KEY, respiroReducer),
    EffectsModule.forFeature([RespiroEffects]),
  ]
})
export class RespiroDataAccessModule {}


