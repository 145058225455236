import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { scheduleReducer, SCHEDULE_REDUCER_KEY } from './+state/schedule.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(SCHEDULE_REDUCER_KEY, scheduleReducer)
  ]
})

export class ScheduleDataAccessModule {}
