import {
  MarketplaceProduct,
  RequestStatus,
} from '@nai-libs/shared/data-access/src';
import { createReducer, on } from '@ngrx/store';

import * as MarketplaceActions from './marketplace.actions';

export const MARKETPLACE_REDUCER_KEY = 'marketplace';

// IMPROVE status property naming, not easy to understand
export interface MarketplaceState {
  products?: MarketplaceProduct[];
  productsStatus: RequestStatus;
  productStatus: RequestStatus;
}

const initalState: MarketplaceState = {
  productsStatus: { pending: false },
  productStatus: { pending: false },
};

export const marketplaceReducer = createReducer(
  initalState,

  on(MarketplaceActions.loadProducts, (state, { forceFetch }) => ({
    ...state,
    productsStatus: { pending: true },
  })),
  on(MarketplaceActions.loadProductsSuccess, (state, { products }) => ({
    ...state,
    products,
    productsStatus: { pending: false },
  })),
  on(MarketplaceActions.loadProductsFailure, (state) => ({
    ...state,
    productsStatus: { pending: false },
  })),

  on(MarketplaceActions.loadProductInfo, (state) => ({
    ...state,
    productStatus: { pending: true },
  })),
  on(MarketplaceActions.loadProductInfoSuccess, (state, { product }) => {
    let newProducts: MarketplaceProduct[] = [];
    if (state.products) {
      newProducts = JSON.parse(JSON.stringify(state.products));
      const index = newProducts.findIndex(
        (loopProduct) => loopProduct.id === product.id
      );
      if (index > -1) {
        newProducts[index] = product;
      } else {
        newProducts.push(product);
      }
    } else {
      newProducts = [product];
    }
    return {
      ...state,
      products: newProducts,
      productStatus: { pending: false },
    };
  }),
  on(MarketplaceActions.loadProductInfoFailure, (state) => ({
    ...state,
    productStatus: { pending: false },
  }))
);
