import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, FeatureKey } from '@nai-libs/data-access';

@Injectable({ providedIn: 'root' })
export class FeatureGuard implements CanActivate {
  constructor(
    @Inject(APP_CONFIG)
    private _env: AppConfig
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return !!this._env.features[route.data['featureKey'] as FeatureKey];
  }
}
