import {
    createReducer,
    on,
  } from '@ngrx/store';
  import {
    DGIResult,
    RequestStatus,
  } from '@nai-libs/data-access';
  import * as DGIActions from './dgi.actions';

  export const DGI_REDUCER_KEY = 'DGI';

  export interface DGIState {
    DGI?: string;
    DGIStatus: RequestStatus;
    lastDGI?: string;
    lastDGIStatus: RequestStatus;
    dgiLink?: string;
    dgiLinkStatus: RequestStatus;
    dgiResults?: DGIResult[];
    dgiResultsStatus: RequestStatus;
  }
  
  const initalState: DGIState  = {
    DGIStatus: { pending: false },
    dgiResultsStatus: { pending: false },
    lastDGIStatus: { pending: false },
    dgiLinkStatus: { pending: false },
  };
  
  export const dgiReducer = createReducer(
    initalState,
    on(DGIActions.loadDGI, (state) => ({
      ...state,
      DGIStatus: { pending: true },
    })),
    on(DGIActions.loadDGISuccess, (state, { dgiDate }) => ({
      ...state,
      DGI: dgiDate,
      DGIStatus: { pending: false },
    })),
    on(DGIActions.loadDGIError, (state) => ({
      ...state,
      DGIStatus: { pending: false },
    })),
    on(DGIActions.loadLastDGI, (state) => ({
      ...state,
      lastDGIStatus: { pending: true },
    })),
    on(DGIActions.loadLastDGISuccess, (state, { dgiDate }) => ({
      ...state,
      lastDGI: dgiDate,
      lastDGIStatus: { pending: false },
    })),
    on(DGIActions.loadLastDGIError, (state) => ({
      ...state,
      lastDGIStatus: { pending: false },
    })),
  
    on(DGIActions.openDGISuccess, (state, { dgiLink }) => ({
      ...state,
      dgiLink,
      dgiLinkStatus: { pending: false },
    })),
    on(DGIActions.openDGIFailure, (state) => ({
      ...state,
      dgiLinkStatus: { pending: false },
    })),
  
    on(DGIActions.downloadDGISuccess, (state, { dgiLink }) => ({
      ...state,
      dgiLink,
      dgiLinkStatus: { pending: false },
    })),
    on(DGIActions.downloadDGIFailure, (state) => ({
      ...state,
      dgiLinkStatus: { pending: false },
    })),
    on(DGIActions.loadDGIResults, (state) => ({
      ...state,
      dgiResultsStatus: { pending: true },
    })),
    on(DGIActions.loadDGIResultsSuccess, (state, { dgiResults }) => ({
      ...state,
      dgiResults,
      dgiResultsStatus: { pending: false },
    })),
    on(DGIActions.loadDGIResultsFailure, (state) => ({
      ...state,
      dgiResultsStatus: { pending: false },
    }))

  );
  

  