/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ClockInEffects } from './+state/clock-in.effects';
import { StoreModule } from '@ngrx/store';
import { clockInReducer, CLOCK_IN_FEATURE_KEY } from './+state/clock-in.reducer';
import { ClockInSuccessModule } from '@nai-libs/clock-in/features/clock-in-feature/src/lib/standalone/clock-in-success-dialog/clock-in-success.module';

@NgModule({
  imports: [
    CommonModule,
    ClockInSuccessModule,
    StoreModule.forFeature(CLOCK_IN_FEATURE_KEY, clockInReducer),
    EffectsModule.forFeature([ClockInEffects])
  ]
})
export class ClockInDataAccessModule {}
