import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  MARKETPLACE_REDUCER_KEY,
  marketplaceReducer,
} from './+state/marketplace.reducer';
import { MarketplaceEffects } from './+state/marketplace.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      MARKETPLACE_REDUCER_KEY,
      marketplaceReducer
    ),
    EffectsModule.forFeature([MarketplaceEffects]),
  ],
})
export class MarketplaceDataAccessModule {}


