import { RespiroAvailableAppointmentsByMonth } from '@nai-libs/shared/data-access/src';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RESPIRO_REDUCER_KEY, RespiroState } from './respiro.reducer';

export const selectRespiroState =
  createFeatureSelector<RespiroState>(RESPIRO_REDUCER_KEY);

export const selectAvailableAppointments = createSelector(
  selectRespiroState,
  (state) => state.availableAppointments
);
export const selectAvailableAppointmentsStatusPending = createSelector(
  selectRespiroState,
  (state) => state.availableAppointmentsStatus.pending
);

export const selectSelectedAppointments = createSelector(
  selectRespiroState,
  (state) => {
    const selectedAppointments: RespiroAvailableAppointmentsByMonth = {};
    for (const month in state.availableAppointments) {
      for (const loopDay in state.availableAppointments[month]) {
        for (const storedAppointment of state.availableAppointments[month][
          loopDay
        ]) {
          if (storedAppointment.selected) {
            if (!selectedAppointments[month]) {
              selectedAppointments[month] = {};
              selectedAppointments[month][loopDay] = [];
            }
            if (selectedAppointments[month][loopDay]) {
              selectedAppointments[month][loopDay].push(storedAppointment);
            } else {
              selectedAppointments[month][loopDay] = [storedAppointment];
            }
          }
        }
      }
    }
    return selectedAppointments;
  }
);

export const selectConsumedHours = createSelector(
  selectRespiroState,
  (state) => state.consumedHours
);
export const selectConsumedHoursStatusPending = createSelector(
  selectRespiroState,
  (state) => state.consumedHoursStatus.pending
);

export const selectFormAppointments = createSelector(
  selectRespiroState,
  (state) => state.formAppointments
);

export const selectFormMessage = createSelector(
  selectRespiroState,
  (state) => state.formMessages
);

export const selectLoadCounter = createSelector(
  selectRespiroState,
  (state) => state.loadCounter
);

export const selectContactData = createSelector(
  selectRespiroState,
  (state) => state.contactData
);

export const selectContactDataStatusPending = createSelector(
  selectRespiroState,
  (state) => state.contactDataRequestStatus.pending
);
