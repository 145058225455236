import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewPasswordPageComponent } from './containers/new-password-page.component';
import { NewPasswordFormComponent } from './components/new-password-form.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicIconDirectiveModule } from '@nai-libs/shared/utility/src/lib/directives/dynicon/dynicon.module';
import { NewPasswordFeatureRoutingModule } from './new-password-feature-routing.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatInputModule,
    ReactiveFormsModule,
    DynamicIconDirectiveModule,
    NewPasswordFeatureRoutingModule,
  ],
  declarations: [NewPasswordPageComponent, NewPasswordFormComponent],
})
export class NewPasswordFeatureModule {}
