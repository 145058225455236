import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MembersState, MEMBERS_REDUCER_KEY } from "./members.reducer";

export const selectMembersState = createFeatureSelector<MembersState>(
  MEMBERS_REDUCER_KEY
);

export const selectMembersStatusPending = createSelector(
  selectMembersState,
  (state) => state.membersStatus.pending
);
export const selectMembers = createSelector(
  selectMembersState,
  (state) => state.members
);

export const createMemberStatusPending = createSelector(
  selectMembersState,
  (state) => state.createMemberStatus.pending
);