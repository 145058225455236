import { Observable } from 'rxjs';
import { ServiceReceiver, User } from './user.models';

export interface NumServicesResponse {
  'number-of-active-services': string;
}
export interface NewServicesResponse {
  'accepted-offers': NewService[];
}
export interface NewService {
  title: string;
  'offer-number': string;
  'offer-name': string;
  'acceptance-date': string;
}
export interface NewServiceDocumentResponse {
  'pdf-link': string;
}

export const ALL_DOCUMENT_CATEGORIES = [
  'budgets',
  'invoices',
  'payrolls',
  'contracts',
  'paech',
];

export type DocumentCategoryTuple = typeof ALL_DOCUMENT_CATEGORIES;
export type DocumentCategory = DocumentCategoryTuple[number];

export const DocumentCategoryTitles = {
  budgets: 'Presupuestos aceptados',
  invoices: 'Facturas',
  payrolls: 'Nóminas cuidadoras',
  contracts: 'Contratos',
  paech: 'PAECH',
};

export interface BudgetsResponse {
  'accepted-offers': BackBudget[];
}
export interface ContractsResponse {
  contracts: BackContract[];
}
export interface InvoicesResponse {
  invoices: BackInvoice[];
}

export interface PAECHResponse {
  documents: BackPAECH[];
}

export interface PayrollsResponse {
  payrolls: BackPayroll[];
}

export interface BackBudget extends DocumentWithoutId {
  'offer-number': string;
  'offer-name': string;
  'acceptance-date': string;
  category: 'budgets';
}
export interface BackContract extends DocumentWithoutId {
  'contract-eid': string;
  'signed?'?: boolean;
  category: 'contracts';
}
export interface BackInvoice extends DocumentWithoutId {
  'invoice-eid': string;
  year: string;
  month: string;
  category: 'invoices';
}
export interface BackPAECH extends DocumentWithoutId {
  eid: string;
  date: string;
  category: 'paech';
}
export interface BackPayroll extends DocumentWithoutId {
  'payroll-eid': string;
  month: string;
  year: string;
  category: 'payrolls';
}

export type Budget = {
  [Property in keyof BackBudget as Property extends 'offer-number'
    ? 'id'
    : Property]: BackBudget[Property];
};
export type Contract = {
  [Property in keyof BackContract as Property extends 'contract-eid'
    ? 'id'
    : Property]: BackContract[Property];
};
export type Invoice = {
  [Property in keyof BackInvoice as Property extends 'invoice-eid'
    ? 'id'
    : Property]: BackInvoice[Property];
};
export type PAECH = {
  [Property in keyof BackPAECH as Property extends 'eid'
    ? 'id'
    : Property]: BackPAECH[Property];
};
export type Payroll = {
  [Property in keyof BackPayroll as Property extends 'payroll-eid'
    ? 'id'
    : Property]: BackPayroll[Property];
};

// export interface Document {
//   id?: string;
//   icon?: 'doc';
//   download: boolean;
//   'signed?'?:boolean | null;
//   type?:string;
//   view: boolean;
// }
type DocumentWithoutId = Omit<Document, 'id'>;
export interface Document {
  id: string;
  readonly type: string;
  readonly title: string;
  link?: string;
  icon?: string;
}

export type AnyDocument = Budget | Contract | Invoice | PAECH | Payroll;

export type DocumentsByType = {
  [Property in DocumentCategory]: AnyDocument[];
};

export type FetchDocumentFunctions = {
  [Property in DocumentCategory]?: (
    category: DocumentCategory,
    user: User,
    serviceReceiver: ServiceReceiver
  ) => Observable<AnyDocument[]>;
};

export type FetchDocumentFunctionsExecuted = {
  [Property in DocumentCategory]: Observable<AnyDocument[]>;
};
