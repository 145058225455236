import { ForumSurvey } from '@nai-libs/shared/data-access/src';
import { createAction, props } from '@ngrx/store';

export const loadSurveys = createAction('[Forum] Load surveys');
export const loadSurveysSuccess = createAction(
  '[Forum] Load surveys success',
  props<{ surveys: ForumSurvey[] }>()
);
export const loadSurveysFailure = createAction('[Forum] Load surveys success');

export const markAnswer = createAction(
  '[Forum] Mark answer',
  props<{ questionId: string; questionAnswer: string }>()
);
export const markAnswerSuccess = createAction('[Forum] Mark answer success');
export const markAnswerFailure = createAction('[Forum] Mark answer failure');

export const loadZaintzaQuiz = createAction('[Forum] Load ZaintzaQuiz');
export const loadZaintzaQuizSuccess = createAction(
  '[Forum] Load ZaintzaQuiz success',
  props<{ zaintzaQuiz: ForumSurvey[] }>()
);
export const loadZaintzaQuizFailure = createAction(
  '[Forum] Load ZaintzaQuiz failure'
);

export const markZaintzaQuizAnswer = createAction(
  '[Forum] Mark ZaintzaQuiz answer',
  props<{ questionId: string; questionAnswer: string }>()
);
export const markZaintzaQuizAnswerSuccess = createAction(
  '[Forum] Mark ZaintzaQuiz answer success'
);
export const markZaintzaQuizAnswerFailure = createAction(
  '[Forum] Mark ZaintzaQuiz answer failure'
);
