/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable, NgZone } from '@angular/core';
import { enterZone } from '@nai-libs/utility';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  delay,
  exhaustMap,
  fromEventPattern,
  ignoreElements,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { PushNotificationActions } from '../..';

import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { NavController } from '@ionic/angular';
import { NotificationActions } from '@nai-libs/notification/data-access/src';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access/src';
import { Store } from '@ngrx/store';
import { PushNotificationService } from './push-notification.service';

@Injectable()
export class PushNotificationEffects {
  constructor(
    private actions$: Actions,
    private ngZone: NgZone,
    private navController: NavController,
    private pushNotificationService: PushNotificationService,
    private store: Store
  ) {}

  requestPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushNotificationActions.requestPermission),
      exhaustMap(async () => {
        const { receive } = await PushNotifications.requestPermissions();
        if (receive === 'granted') {
          await PushNotifications.register();
          return PushNotificationActions.requestPermissionSuccess();
        } else {
          return PushNotificationActions.requestPermissionRejected();
        }
      })
    )
  );

  addTokenRegsitrationListener$ = createEffect(() => {
    if (Capacitor.getPlatform() === 'web') {
      return of(PushNotificationActions.skipTokenRegistration());
    } else {
      return fromEventPattern<Token>(
        (handler) => PushNotifications.addListener('registration', handler),
        () => PushNotifications.removeAllListeners()
      ).pipe(
        map((token) => {
          return PushNotificationActions.registerToken({ token });
        })
      );
    }
  });

  registerToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushNotificationActions.registerToken),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      switchMap(([{ token }, user]) => {
        if (!user) return of(UserActions.logout());
        return this.pushNotificationService.registerToken(token, user).pipe(
          map((token) => {
            return PushNotificationActions.registerTokenSuccess({ token });
          }),
          catchError(() => of(PushNotificationActions.registerTokenFailure()))
        );
      })
    )
  );

  notificationReceived$ =
    Capacitor.getPlatform() === 'web'
      ? null
      : createEffect(
          () => {
            return fromEventPattern<PushNotificationSchema>((handler) =>
              PushNotifications.addListener('pushNotificationReceived', handler)
            ).pipe(
              enterZone(this.ngZone),
              tap(async (notification: PushNotificationSchema) => {
                // const { value } = await Dialog.confirm({
                //   title: `${notification.title}`,
                //   message: `${notification.body}`,
                //   cancelButtonTitle: 'Cancelar',
                //   okButtonTitle: 'Ver',
                // });

                // if (value && 'target' in notification.data) {
                //   this.navController.navigateRoot(
                //     `${notification.data.target}`
                //   );
                // }

                this.store.dispatch(NotificationActions.loadNotifications());
                if ('target' in notification.data) {
                  this.navController.navigateRoot(
                    `${notification.data.target}`
                  );
                }
              }),
              ignoreElements()
            );
          },
          {
            dispatch: false,
          }
        );

  pushNotificationActionPerformed$ =
    Capacitor.getPlatform() === 'web'
      ? null
      : createEffect(
          () => {
            return fromEventPattern<ActionPerformed>((handler) =>
              PushNotifications.addListener(
                'pushNotificationActionPerformed',
                handler
              )
            ).pipe(
              delay(750),
              enterZone(this.ngZone),
              tap((action: ActionPerformed) => {
                const { notification } = action;
                if ('target' in notification.data) {
                  this.navController.navigateRoot(
                    `${notification.data.target}`
                  );
                }
              }),
              ignoreElements()
            );
          },
          {
            dispatch: false,
          }
        );
}
