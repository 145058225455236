import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import {
  AnyDocument,
  DocumentCategory,
  DocumentsByType,
  FetchDocumentFunctions,
  FetchDocumentFunctionsExecuted,
  NewService,
} from '@nai-libs/data-access';
import { Store } from '@ngrx/store';
import * as DocumentActions from './document.actions';
import { DocumentService } from './document.service';

import { NavController } from '@ionic/angular';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { FileService } from '@nai-libs/utility';
import * as DocumentSelectors from './document.selectors';

@Injectable()
export class DocumentEffects {
  constructor(
    private actions$: Actions,
    private documentService: DocumentService,
    private fileService: FileService,
    private store: Store,
    private navCtrl: NavController
  ) {}

  loadNewServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.loadNewServices),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectUser),
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(DocumentSelectors.selectNewServices)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, user, serviceReceiver, selectedUser, newServices]) => {
        if (!user || !serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        if (newServices)
          return of(DocumentActions.loadNewServicesSuccess({ newServices }));
        return this.documentService
          .getNewServices(user, serviceReceiver, selectedUser)
          .pipe(
            map((newServices: NewService[]) =>
              DocumentActions.loadNewServicesSuccess({ newServices })
            ),
            catchError(() => of(DocumentActions.loadNewServicesFailure()))
          );
      })
    )
  );

  openServiceDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.openNewServiceDoc),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ offerNumber }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        return this.documentService
          .getNewServiceDocuments(offerNumber, serviceReceiver, selectedUser)
          .pipe(
            switchMap((offerLink: string | undefined) => {
              if (offerLink) {
                return this.fileService.openFile(offerLink).pipe(
                  map(() =>
                    DocumentActions.openNewServiceDocSuccess({ offerLink })
                  ),
                  catchError(() => [DocumentActions.openNewServiceDocFailure()])
                );
              }
              return [DocumentActions.openNewServiceDocFailure()];
            }),
            catchError(() => of(DocumentActions.openNewServiceDocFailure()))
          );
      })
    )
  );

  countServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.countServices),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout);
        return this.documentService
          .countServices(serviceReceiver, selectedUser)
          .pipe(
            map((count) => DocumentActions.countServicesSuccess({ count })),
            catchError((_) => of(DocumentActions.countServicesFailure()))
          );
      })
    )
  );

  loadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.loadDocuments),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(DocumentSelectors.selectDocuments)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(
        ([
          { categories, forceFetch },
          serviceReceiver,
          selectedUser,
          documents,
        ]) => {
          if (!serviceReceiver || !selectedUser) return of(UserActions.logout);
          // TODO
          // if (areCarerDocs) {
          //   return [DocumentActions.loadSpecificCarerPayrolls()];
          // }

          const functions: FetchDocumentFunctions = {};
          for (const category of categories) {
            if (
              !forceFetch &&
              documents &&
              documents[category] &&
              documents[category].length > 0
            ) {
              continue;
            }
            functions[category as DocumentCategory] = () =>
              this.documentService.fetchDocuments(
                category as DocumentCategory,
                serviceReceiver,
                selectedUser
              );
          }

          const execute = (fn: () => Observable<AnyDocument[]>) => fn();
          const executeObject = (object: FetchDocumentFunctions) => {
            return Object.keys(object).reduce(
              (
                acc: FetchDocumentFunctionsExecuted,
                current: DocumentCategory
              ) => {
                acc[current] = execute(
                  object[current] as () => Observable<AnyDocument[]>
                ).pipe(
                  catchError((error) => {
                    console.warn(
                      `Documents of type ${current} couldn't be fetched: `,
                      error
                    );
                    return of([]);
                  })
                );
                return acc;
              },
              {}
            );
          };
          return forkJoin(executeObject(functions)).pipe(
            map((fetchedDocuments: DocumentsByType) => {
              return DocumentActions.loadDocumentsSuccess({
                documents: { ...documents, ...fetchedDocuments },
              });
            }),
            catchError((error) => {
              return of(DocumentActions.loadDocumentsFailure({ error }));
            })
          );
        }
      )
    )
  );

  downloadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.downloadDocument),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ id, category }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout);
        return this.documentService
          .fetchDocumentLink(id, category, serviceReceiver, selectedUser)
          .pipe(
            switchMap((link: string | undefined) => {
              if (link) {
                return this.fileService.downloadFile(link).pipe(
                  map(() =>
                    DocumentActions.downloadDocumentSuccess({
                      link,
                      id,
                      category,
                    })
                  ),
                  catchError(() => [DocumentActions.downloadDocumentFailure()])
                );
              }
              return [DocumentActions.downloadDocumentFailure()];
            }),
            catchError((_) => of(DocumentActions.downloadDocumentFailure()))
          );
      })
    )
  );

  markDocumentAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.markDocumentAsRead),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ id }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout);
        return this.documentService
          .markContractAsRead(id, user, serviceReceiver, selectedUser)
          .pipe(
            map((isRead) => {
              if (isRead) return DocumentActions.markDocumentAsReadSuccess();
              return DocumentActions.markDocumentAsReadFailure();
            }),
            catchError((_) => of(DocumentActions.markDocumentAsReadFailure()))
          );
      })
    )
  );

  downloadDocumentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.downloadDocumentSuccess),
      map(({ link, id, category }) => {
        return DocumentActions.markDocumentAsRead({ id, category });
      })
    )
  );

  signDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.signDocument),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { id, signature, documentType, category },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser)
            return of(UserActions.logout);
          return this.documentService
            .signDocument(
              id,
              signature,
              documentType,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((_) => DocumentActions.signDocumentSuccess({ category })),
              catchError((_) => of(DocumentActions.signDocumentFailure()))
            );
        }
      )
    )
  );

  signDocumentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.signDocumentSuccess),
      map(({ category }) => {
        this.navCtrl.navigateBack(['', 'services']);
        return DocumentActions.loadDocuments({
          categories: [category],
          forceFetch: true,
        });
      })
    )
  );

  openDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.openDocument),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ id, category }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout);
        return this.documentService
          .fetchDocumentLink(id, category, serviceReceiver, selectedUser)
          .pipe(
            switchMap((link: string | undefined) => {
              if (link) {
                return of(
                  DocumentActions.openDocumentSuccess({ link, id, category })
                );
              }
              throw new Error('Document link not available');
            }),
            catchError((_) => of(DocumentActions.openDocumentFailure()))
          );
      })
    )
  );

  openDocumentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.openDocumentSuccess),
      map(({ link, id, category }) => {
        //this.navCtrl.navigateForward(['services', 'documentos', category, id]);
        return DocumentActions.markDocumentAsRead({ id, category });
      })
    )
  );

  // openDocumentFailure$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DocumentActions.openDocumentFailure),
  //     tap(() => {
  //       this.location.historyGo(-1);
  //       this.alertService.showAlert(
  //         'error',
  //         this.translateService.instant('DOCUMENT.OPEN_DOCUMENT.ERROR'),
  //         '/assets/icons/alert.png',
  //         10000,
  //         'top'
  //       );
  //     })
  //   )
  // );
}
