import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { DynamicIconDirectiveModule } from '@nai-libs/directives/dynamic-icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';

import { CreateMemberSuccessDialogComponent } from './create-member-success-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DynamicIconDirectiveModule,
    TranslateModule,
    MatDialogModule,
  ],
  declarations: [CreateMemberSuccessDialogComponent],
})
export class CreateMemberSuccessDialogModule {}
