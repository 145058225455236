import {
  Locale,
  SelectedUser,
  ServiceReceiver,
  User,
  UserEditableData,
} from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

export const login = createAction(
  '[User] Login',
  props<{ email: string; password: string }>()
);
export const loginSuccess = createAction('[User] Login Success');
export const loginFailure = createAction('[User] Login Failure');

export const logout = createAction('[User] Logout');

export const loadUserData = createAction(
  '[User] Load user data',
  props<{ isLogin: boolean }>()
);
export const loadUserDataSuccess = createAction(
  '[User] Load user data Success',
  props<{ user: User; redirection?: string | string[] }>()
);
export const loadUserDataFailure = createAction(
  '[User] Load user data Failure'
);

export const setUser = createAction('[User] Set user', props<{ user: User }>());

export const sendPasswordRecoveryEmail = createAction(
  '[User] Send password recovery email',
  props<{ email: string }>()
);
export const sendPasswordRecoveryEmailSuccess = createAction(
  '[User] Send password recovery email Success'
);
export const sendPasswordRecoveryEmailFailure = createAction(
  '[User] Send password recovery email Failure',
  props<{ error: string }>()
);

export const saveNewPassword = createAction(
  '[User] Save new password',
  props<{ email: string; password: string; tempPassword: string }>()
);
export const saveNewPasswordSuccess = createAction(
  '[User] Save new password Success'
);
export const saveNewPasswordFailure = createAction(
  '[User] Save new password Failure',
  props<{ error: string }>()
);

export const changePass = createAction(
  '[User] Change password',
  props<{ oldPass: string; newPass: string }>()
);
export const changePassSuccess = createAction('[User] Change password success');
export const changePassFailure = createAction('[User] Change password failure');

export const acceptUsingTerms = createAction('[User] Accept using terms');
export const acceptUsingTermsSuccess = createAction(
  '[User] Accept using terms Success'
);
export const acceptUsingTermsFailure = createAction(
  '[User] Accept using terms Failure',
  props<{ error: string }>()
);

export const changeLanguage = createAction(
  '[User] Change language',
  props<{ lang: Locale }>()
);
export const changeLanguageSuccess = createAction(
  '[User] Change language success'
);
export const changeLanguageFailure = createAction(
  '[User] Change language failure'
);

// Service receiver
export const setServiceReceiver = createAction(
  '[User] Set service receiver',
  props<{
    serviceReceiver?: ServiceReceiver;
    redirection?: string | string[];
  }>()
);
export const setServiceReceiverSuccess = createAction(
  '[User] Set service receiver success',
  props<{ serviceReceiver: ServiceReceiver; redirection?: string | string[] }>()
);

export const setServiceReceiverFailure = createAction(
  '[User] Set service receiver failure'
);

// Set the selected user on application start
export const setSelectedUser = createAction(
  '[User] Set selected user',
  props<{
    selectedUser?: SelectedUser;
  }>()
);

// Updates the selected user and refreshes the page. Dont use on application start to avoid infinite loops
export const changeSelectedUser = createAction(
  '[User] Change selected user',
  props<{
    selectedUser: SelectedUser;
  }>()
);
export const changeSelectedUserSuccess = createAction(
  '[User] Change selected user success',
  props<{
    selectedUser: SelectedUser;
  }>()
);
export const changeSelectedUserFailure = createAction(
  '[User] Change selected user failure'
);

export const setSelectedUserSuccess = createAction(
  '[User] Set selected user success',
  props<{ selectedUser: SelectedUser }>()
);

export const setSelectedUserFailure = createAction(
  '[User] Set selected user failure'
);

export const changeAvatarColor = createAction(
  '[User] Change avatar color',
  props<{ color: string; mode: 'client' | 'familiar' }>()
);
export const changeAvatarColorSuccess = createAction(
  '[User] Change avatar color success'
);

export const changeAvatarColorFailure = createAction(
  '[User] Change avatar color failure'
);

export const editUser = createAction(
  '[User] Edit user',
  props<{ editedUser: UserEditableData }>()
);

export const editUserSuccess = createAction(
  '[User] Edit user success'
);

export const editUserFailure = createAction(
  '[User] Edit user failure'
);
