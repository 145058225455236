import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DGI_REDUCER_KEY, DGIState } from './dgi.reducer';

export const selectDGIState = createFeatureSelector<DGIState>(DGI_REDUCER_KEY);

export const selectLastDGIPending = createSelector(
  selectDGIState,
  (state) => state.lastDGIStatus.pending
);
export const selectLastDGI = createSelector(
  selectDGIState,
  (state) => state.lastDGI
);

export const isDGIPending = createSelector(
  selectDGIState,
  (state) => state.DGIStatus.pending
);
export const selectDGI = createSelector(selectDGIState, (state) => {
  return state.DGI;
});
export const selectDGILink = createSelector(
  selectDGIState,
  (state) => state.dgiLink
);
export const selectDGIResults = createSelector(
  selectDGIState,
  (state) => state.dgiResults
);
export const selectDGIResultsPending = createSelector(
  selectDGIState,
  (state) => state.dgiResultsStatus.pending
);

export const selectDGIResult = (slug: string) =>
  createSelector(selectDGIState, (state) => {
    if (state.dgiResults) {
      for (const dgiResult of state.dgiResults) {
        if (dgiResult.slug === slug) {
          return dgiResult;
        }
      }
    }
    return undefined;
  });
