import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  ForumReducer,
  FORUM_REDUCER_KEY,
} from './+state/forum-surveys.reducer';
import { ForumEffects } from './+state/forum-surveys.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FORUM_REDUCER_KEY, ForumReducer),
    EffectsModule.forFeature([ForumEffects]),
  ],
})
export class ForumSurveysDataAccessModule {}
