export const ALL_AVATAR_COLORS = [
  '#ffb948',
  '#aba0ff',
  '#00dec9',
  '#87dc44',
  '#f77fea',
  '#ff8b69',
  '#8bcdff',
  '#6fdd7a',
];
