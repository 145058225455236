import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  Appointment,
  AppointmentsByMonth,
  SelectedUser,
  ServiceReceiver,
  UpcomingAppointmentsResponse,
  VideoCallsResponse,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppointmentService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  /**
   * Método para obtener las videollamadas cercanas del cliente
   * @returns Observable<Appointment[]
   */
  getNextVideoCalls(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Appointment[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<VideoCallsResponse>(
        `${this.env.apiUrl}services/notifications/fetch-videoconsultations?${params}`
      )
      .pipe(
        map((response) =>
          response.videoconsultations.length > 0
            ? response.videoconsultations
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Método para obtener las próximas citas de un cliente
   * @return Observable<Appointment[]>
   */
  getUpcomingAppointments(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Appointment[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<UpcomingAppointmentsResponse>(
        `${this.env.apiUrl}services/upcoming-appointments?${params}`
      )
      .pipe(
        map((response) =>
          response['upcoming-appointments'].length > 0
            ? response['upcoming-appointments']
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  getAppointmentsByMonth(
    date: Date,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<AppointmentsByMonth> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      month: (date.getMonth() + 1).toString(),
      year: date.getFullYear().toString(),
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<AppointmentsByMonth>(
        `${this.env.apiUrl}services/fetch-scheduled-appointments-for-specific-month?${params}`
      )
      .pipe(
        map((response) => response),
        catchError((err) => throwError(() => err))
      );
  }

  modifyAppointmentAttendance(
    accept: boolean,
    attendeeId: number,
    serviceReceiver: ServiceReceiver
  ) {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'attendee-eid': attendeeId,
      'attendee-state': accept ? 'accepted' : 'declined',
    };

    return this.http.post<{ 'success?': boolean }>(
      `${this.env.apiUrl}services/groupal-intervention/attendee-state`,
      paramsObj
    );
  }

  fetchAppointmentsByDay(
    date: Date,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Appointment[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      month: (date.getMonth() + 1).toString(),
      year: date.getFullYear().toString(),
      day: date.getDate().toString(),
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<{ 'today-appointments': Appointment[] }>(
        `${this.env.apiUrl}services/fetch-scheduled-appointments-for-specific-day?${params}`
      )
      .pipe(
        map((response) => response['today-appointments']),
        catchError((err) => throwError(() => err))
      );
  }
}
