import { FamiliarMember, NewMember, RequestStatus } from '@nai-libs/data-access'
import { createReducer, on } from '@ngrx/store';
import * as MembersActions from '../+state/members.actions';

export const MEMBERS_REDUCER_KEY = 'members';

export interface MembersState {
  members?: FamiliarMember[];
  membersStatus: RequestStatus;
  createMemberStatus: RequestStatus;
}

const initialState: MembersState = {
  membersStatus: { pending: false },
  createMemberStatus: { pending: false }
}

export const membersReducer = createReducer(
  initialState,
  on(MembersActions.loadMembers, (state) => ({
    ...state,
    membersStatus: { pending: true },
  })),
  on(MembersActions.loadMembersSuccess, (state, { members }) => ({
    ...state,
    members,
    membersStatus: { pending: false },
  })),
  on(MembersActions.loadMembersFailure, (state) => ({
    ...state,
    membersStatus: { pending: false },
  })),

  on(MembersActions.createMember, (state) => ({
    ...state,
    createMemberStatus: { pending: true },
  })),
  on(MembersActions.createMemberSuccess, (state) => ({
    ...state,
    createMemberStatus: { pending: false },
  })),
  on(MembersActions.createMemberFailure, (state) => ({
    ...state,
    createMemberStatus: { pending: false },
  }))
)