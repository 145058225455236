import {
  HomeAccessibility,
  HomeAccessibilityResponse,
} from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

// HomeAccessibility

export const loadHomeAccessibility = createAction(
  '[HomeAccessibility] Load Home Accessibility',
  props<{ forceFetch: boolean }>()
);
export const loadHomeAccessibilitySuccess = createAction(
  '[HomeAccessibility] Load Home Accessibility success',
  props<{ homeAccessibilityResults: HomeAccessibilityResponse }>()
);
export const loadHomeAccessibilityFailure = createAction(
  '[HomeAccessibility] Load Home Accessibility error'
);

export const loadHomeAccessibilityLast = createAction(
  '[HomeAccessibility] Load Home Accessibility Last'
);
export const loadHomeAccessibilityLastSuccess = createAction(
  '[HomeAccessibility] Load Home Accessibility Last success',
  props<{ homeAccessibilityLast: Date }>()
);
export const loadHomeAccessibilityLastFailure = createAction(
  '[HomeAccessibility] Load Home Accessibility Last error'
);

export const loadHomeAccessibilityNotification = createAction(
  '[HomeAccessibility] Load Home Accessibility Notification'
);
export const loadHomeAccessibilityNotificationSuccess = createAction(
  '[HomeAccessibility] Load Home Accessibility Notification success',
  props<{ homeAccessibilityNotification: string }>()
);
export const loadHomeAccessibilityNotificationFailure = createAction(
  '[HomeAccessibility] Load Home Accessibility Notification error'
);

export const saveReadHomeAccessibilityNotification = createAction(
  '[HomeAccessibility] Save Read Accessibility Notification',
  props<{ date: string }>()
);
export const saveReadHomeAccessibilityNotificationSuccess = createAction(
  '[HomeAccessibility] Save Read Accessibility Notification success'
);
export const saveReadHomeAccessibilityNotificationFailure = createAction(
  '[HomeAccessibility] Save Read Accessibility Notification error'
);

export const loadHomeAccessibilityDetail = createAction(
  '[HomeAccessibility] Load Home Accessibility Detail',
  props<{ slug: string }>()
);
export const loadHomeAccessibilityDetailSuccess = createAction(
  '[HomeAccessibility] Load Home Accessibility Detail success',
  props<{ selectedHomeAccessibilityDetail: HomeAccessibility }>()
);
export const loadHomeAccessibilityDetailFailure = createAction(
  '[HomeAccessibility] Load Home Accessibility Detail error'
);
