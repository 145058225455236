import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import * as NoticeBoardActions from './notice-board.actions';
import * as NoticeBoardSelectors from './notice-board.selectors';

import {
  NoticeBoardArticle,
  NoticeBoardArticlePreview,
} from '@nai-libs/data-access';
import { NoticeBoardService } from './notice-board.service';
import { Store } from '@ngrx/store';

@Injectable()
export class NoticeBoardEffects {
  constructor(
    private actions$: Actions,
    private noticeBoardService: NoticeBoardService,
    private store: Store
  ) {}

  loadNoticeBoardPreviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NoticeBoardActions.loadNoticeBoardPreviews),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(NoticeBoardSelectors.selectNoticeBoardPreviews)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, noticeBoardArticlePreviews]) => {
        if (noticeBoardArticlePreviews)
          return of(
            NoticeBoardActions.loadNoticeBoardPreviewsSuccess({
              noticeBoardArticlePreviews,
            })
          );
        return this.noticeBoardService.getNoticeBoardArticlePreviews().pipe(
          map((noticeBoardArticlePreviews: NoticeBoardArticlePreview[]) =>
            NoticeBoardActions.loadNoticeBoardPreviewsSuccess({
              noticeBoardArticlePreviews,
            })
          ),
          catchError(() =>
            of(NoticeBoardActions.loadNoticeBoardPreviewsFailure())
          )
        );
      })
    )
  );

  loadNoticeBoardArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NoticeBoardActions.loadNoticeBoardArticle),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(
                NoticeBoardSelectors.selectNoticeBoardArticleMap
              )
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([{ forceFetch, id }, noticeBoardArticleMap]) => {
        if (!forceFetch && noticeBoardArticleMap && id in noticeBoardArticleMap) {
          return of(
            NoticeBoardActions.loadNoticeBoardArticleSuccess({
              noticeBoardArticle: noticeBoardArticleMap[id],
            })
          );
        }
        return this.noticeBoardService.fetchNoticeBoardArticle(id).pipe(
          map((noticeBoardArticle: NoticeBoardArticle) =>
            NoticeBoardActions.loadNoticeBoardArticleSuccess({
              noticeBoardArticle,
            })
          ),
          catchError(() =>
            of(NoticeBoardActions.loadNoticeBoardArticleFailure())
          )
        );
      })
    )
  );
}
