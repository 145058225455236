/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig } from '@nai-libs/data-access';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access/src';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as ForumSurveyActions from './forum-surveys.actions';
import { ForumSurveysService } from './forum-surveys.service';

@Injectable()
export class ForumEffects {
  constructor(
    private actions$: Actions,
    @Inject(APP_CONFIG) private env: AppConfig,
    private store: Store,
    private forumSurveyService: ForumSurveysService
  ) {}

  loadSurveys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForumSurveyActions.loadSurveys),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, user, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !user || !selectedUser)
          return of(UserActions.logout());
        return this.forumSurveyService
          .fetchSurveys(user, serviceReceiver, selectedUser)
          .pipe(
            map((surveys) =>
              ForumSurveyActions.loadSurveysSuccess({ surveys })
            ),
            catchError(() => of(ForumSurveyActions.loadSurveysFailure()))
          );
      })
    )
  );

  markAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForumSurveyActions.markAnswer),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { questionId, questionAnswer },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!serviceReceiver || !user || !selectedUser)
            return of(UserActions.logout());
          return this.forumSurveyService
            .markAnswer(
              user,
              serviceReceiver,
              selectedUser,
              questionId,
              questionAnswer
            )
            .pipe(
              map((_) => ForumSurveyActions.markAnswerSuccess()),
              catchError(() => of(ForumSurveyActions.markAnswerFailure()))
            );
        }
      )
    )
  );

  markAnswerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForumSurveyActions.markAnswerSuccess),
      map((_) => {
        return ForumSurveyActions.loadSurveys();
      })
    )
  );

  loadZaintzaQuiz$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForumSurveyActions.loadZaintzaQuiz),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, user, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !user || !selectedUser)
          return of(UserActions.logout());
        return this.forumSurveyService
          .fetchZaintzaQuiz(user, serviceReceiver, selectedUser)
          .pipe(
            map((zaintzaQuiz) =>
              ForumSurveyActions.loadZaintzaQuizSuccess({ zaintzaQuiz })
            ),
            catchError(() => of(ForumSurveyActions.loadZaintzaQuizFailure()))
          );
      })
    )
  );

  markZaintzaQuizAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForumSurveyActions.markZaintzaQuizAnswer),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { questionId, questionAnswer },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!serviceReceiver || !user || !selectedUser)
            return of(UserActions.logout());
          return this.forumSurveyService
            .markZaintzaQuizAnswer(
              user,
              serviceReceiver,
              selectedUser,
              questionId,
              questionAnswer
            )
            .pipe(
              map((_) => ForumSurveyActions.markZaintzaQuizAnswerSuccess()),
              catchError(() =>
                of(ForumSurveyActions.markZaintzaQuizAnswerFailure())
              )
            );
        }
      )
    )
  );

  markZaintzaQuizAnswerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForumSurveyActions.markZaintzaQuizAnswerSuccess),
      map((_) => {
        return ForumSurveyActions.loadZaintzaQuiz();
      })
    )
  );
}
