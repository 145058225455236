import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { DynamicIconDirectiveModule } from '@nai-libs/directives/dynamic-icon';
import { SummaryItemModule } from '@nai-libs/standalone/summary-item';
import { NewVideoconsultationSuccessDialogComponent } from './new-videoconsultation-success-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    DynamicIconDirectiveModule,
    SummaryItemModule,
  ],
  declarations: [NewVideoconsultationSuccessDialogComponent],
  exports: [NewVideoconsultationSuccessDialogComponent],
})
export class NewVideoconsultationSuccessDialogModule {}
