import {
  DocumentCategory,
  DocumentsByType,
  NewService,
  RequestStatus,
} from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';
import * as DocumentActions from './document.actions';

export const DOCUMENT_REDUCER_KEY = 'document';
export interface DocumentState {
  newServices?: NewService[];
  newServicesStatus: RequestStatus;
  numServices?: number;

  documents?: DocumentsByType;

  budgetsStatus: RequestStatus;
  invoicesStatus: RequestStatus;
  payrollsStatus: RequestStatus;
  contractsStatus: RequestStatus;
  PAECHStatus: RequestStatus;

  contractURL?: string;
  contractURLStatus: RequestStatus;
  openDocumentStatus: RequestStatus;
  signDocumentStatus: RequestStatus;

  documentLinkReady: boolean;
}

const initalState: DocumentState = {
  newServicesStatus: { pending: false },
  budgetsStatus: { pending: false },
  invoicesStatus: { pending: false },
  payrollsStatus: { pending: false },
  contractsStatus: { pending: false },
  PAECHStatus: { pending: false },
  contractURLStatus: { pending: false },
  openDocumentStatus: { pending: false },
  signDocumentStatus: { pending: false },
  documentLinkReady: false,
};

export const documentReducer = createReducer(
  initalState,
  on(DocumentActions.loadNewServicesSuccess, (state, { newServices }) => ({
    ...state,
    newServices,
    newServicesStatus: { pending: false },
  })),
  on(DocumentActions.loadNewServicesFailure, (state) => ({
    ...state,
    newServicesStatus: { pending: false },
  })),
  on(DocumentActions.countServicesSuccess, (state, { count }) => ({
    ...state,
    numServices: count,
  })),
  on(DocumentActions.loadDocuments, (state) => ({
    ...state,
    documentsStatus: { pending: true },
  })),
  on(DocumentActions.loadDocumentsSuccess, (state, { documents }) => ({
    ...state,
    documents,
    documentsStatus: { pending: false },
  })),
  on(DocumentActions.loadDocumentsFailure, (state) => ({
    ...state,
    documentsStatus: { pending: false },
  })),
  on(DocumentActions.signDocument, (state) => ({
    ...state,
    signDocumentStatus: { pending: true },
  })),
  on(
    DocumentActions.signDocumentSuccess,
    DocumentActions.signDocumentFailure,
    (state) => ({
      ...state,
      signDocumentStatus: { pending: false },
    })
  ),

  on(DocumentActions.openDocument, (state, { id, category }) => {
    return {
      ...state,
      openDocumentStatus: { pending: true },
      documentLinkReady: false,
    };
  }),
  on(DocumentActions.openDocumentSuccess, (state, { id, link }) => {
    if (!state.documents) {
      return state;
    }

    const newDocuments: DocumentsByType = JSON.parse(
      JSON.stringify(state.documents)
    );
    for (const category of Object.keys(state.documents)) {
      const index = state.documents[category as DocumentCategory]?.findIndex(
        (iterationDocument) => iterationDocument.id == id
      );
      if (index !== -1) {
        newDocuments[category as DocumentCategory][index].link = link;
      }
    }
    return {
      ...state,
      documents: newDocuments,
      openDocumentStatus: { pending: false },
      documentLinkReady: true,
    };
  })
);
