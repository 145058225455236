import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormEffects } from './+state/form.effects';
import { FORM_REDUCER_KEY, formReducer } from './+state/form.reducer';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FORM_REDUCER_KEY, formReducer),
    EffectsModule.forFeature([FormEffects]),
  ],
})
export class FormDataAccessModule {}
