import { Injectable } from '@angular/core';
import { Task } from '@nai-libs/data-access';

@Injectable({
  providedIn: 'root',
})
export class TaskUtilsService {
  getActivityDateTime(
    activityHour?: string,
    taskHour?: string,
    taskMomentStart?: string
  ): Date {
    const date = new Date();
    const hour = activityHour ?? taskHour ?? taskMomentStart ?? '00:00';
    date.setHours(parseInt(hour.split(':')[0]));
    date.setMinutes(parseInt(hour.split(':')[1]));
    date.setSeconds(0);
    return date;
  }

  isTaskDone(task: Task) {
    return task.actividades.every((activity) => activity['done?']);
  }

  filterCompletedTasks(tasks: Task[]): Task[] {
    return tasks.filter((task) => this.isTaskDone(task));
  }
}
