import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_REDUCER_KEY, UserState } from './user.reducer';

export const selectUserState =
  createFeatureSelector<UserState>(USER_REDUCER_KEY);

export const isLoginPending = createSelector(
  selectUserState,
  (state) => state.loginStatus.pending
);
export const selectUser = createSelector(
  selectUserState,
  (state) => state.user
);
export const isUserLoaded = createSelector(
  selectUserState,
  (state) => !!state.user
);

export const isServiceReceiverSelected = createSelector(
  selectUserState,
  (state) => !!state.selectedServiceReceiver
);

export const selectAcceptUsingTermsStatusPending = createSelector(
  selectUserState,
  (state) => state.acceptUsingTermsStatus.pending
);

export const selectLoadUserDataStatusPending = createSelector(
  selectUserState,
  (state) => state.loadUserDataStatus.pending
);

export const selectPasswordRecoveryPending = createSelector(
  selectUserState,
  (state) => state.passwordRecoveryStatus.pending
);
export const selectPasswordRecoveryError = createSelector(
  selectUserState,
  (state) => state.passwordRecoveryStatus.error
);

export const selectNewPasswordPending = createSelector(
  selectUserState,
  (state) => state.newPasswordStatus.pending
);
export const selectNewPasswordError = createSelector(
  selectUserState,
  (state) => state.newPasswordStatus.error
);

export const selectLoggedUserRole = createSelector(
  selectUserState,
  (state) => state.user?.['logged-user']['naiha-fam-access']
);

export const selectLoggedUserLanguage = createSelector(
  selectUserState,
  (state) => state.user?.['logged-user'].language
);

export const selectServiceReceiver = createSelector(
  selectUserState,
  (state) => state.selectedServiceReceiver
);

export const selectSelectedUser = createSelector(
  selectUserState,
  (state) => state.selectedUser
);

export const selectServiceReceiverList = createSelector(
  selectUserState,
  (state) => {
    if (Array.isArray(state.user?.['service-receiver'])) {
      return state.user?.['service-receiver'];
    } else {
      return [];
    }
  }
);

export const selectEditUserPending = createSelector(
  selectUserState,
  (state) => state.editUserStatus.pending
);