import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  NoticeBoardArticle,
  NoticeBoardArticlePreview,
  NoticeBoardResponse,
} from '@nai-libs/data-access';
import { catchError, map, Observable, throwError } from 'rxjs';

import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, User } from '@nai-libs/data-access';

import { DateUtilsService } from '@nai-libs/utility';

@Injectable({
  providedIn: 'root',
})
export class NoticeBoardService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig,
    private dateUtils: DateUtilsService
  ) {}

  /**
   * Método para obtener los artículos de interés de un cliente
   * @return Observable<NoticeBoardArticlePreview[]>
   */
  getNoticeBoardArticlePreviews(): Observable<NoticeBoardArticlePreview[]> {
    const paramsObj = {
      date: this.dateUtils.dateToYearMonthDayHyphen(new Date()), //TODO
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<NoticeBoardResponse>(
        `${this.env.apiUrl}home/notifications/information-wall?${params}`
      )
      .pipe(
        map(
          (response) => {
            return response['result'].length > 0 ? response['result'] : [];
          }

          // response['formative-articles'].length > 0
          //   ? response['formative-articles']
          //   : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  fetchNoticeBoardArticle(id: string): Observable<NoticeBoardArticle> {
    const paramsObj = {
      id
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<NoticeBoardArticle>(
        `${this.env.apiUrl}home/information-wall-article?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }
}
