import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PROFESSIONAL_REDUCER_KEY,
  ProfessionalState,
} from './professional.reducer';

export const selectProfessionalState = createFeatureSelector<ProfessionalState>(
  PROFESSIONAL_REDUCER_KEY
);

export const selectProfessionals = createSelector(
  selectProfessionalState,
  (state) => state.professionals
);
export const selectProfessionalsStatusPending = createSelector(
  selectProfessionalState,
  (state) => state.professionalsStatus.pending
);

export const selectCaregivers = createSelector(
  selectProfessionalState,
  (state) => state.caregivers
);
export const selectCaregiversStatusPending = createSelector(
  selectProfessionalState,
  (state) => state.caregiversStatus.pending
);

export const selectCaregiver = (id: string) =>
  createSelector(selectProfessionalState, (state) =>
    state.caregivers?.find((caregiver) => caregiver['caregiver-eid'] == id)
  );
