import { Injectable } from '@angular/core';
import {
  ALL_DOCUMENT_CATEGORIES,
  AnyDocument,
  BackBudget,
  BackContract,
  BackInvoice,
  BackPAECH,
  BackPayroll,
  Budget,
  Contract,
  DocumentCategory,
  DocumentCategoryTitles,
  Invoice,
  PAECH,
  Payroll,
} from '@nai-libs/data-access';

@Injectable({
  providedIn: 'root',
})
export class DocumentUtilsService {


  renameBackBudget(backBudget: BackBudget) : Budget {
    return { id: backBudget['offer-number'], ...backBudget, category: 'budgets' };
  }
  renameBackContract(backContract: BackContract) : Contract {
    return { id: backContract['contract-eid'], ...backContract, category: 'contracts' };
  }
  renameBackInvoice(backInvoice: BackInvoice) : Invoice {
    return { id: backInvoice['invoice-eid'], ...backInvoice, category: 'invoices' };
  }
  renameBackPAECH(backPAECH: BackPAECH) : PAECH {
    return { id: backPAECH.eid, ...backPAECH, category: 'paech' };
  }
  renameBackPayroll(backPayroll: BackPayroll) : Payroll {
    return { id: backPayroll['payroll-eid'], ...backPayroll, category: 'payrolls' };
  }


  isBudget(document: AnyDocument): document is Budget {
    return ((document as Budget).type === 'budgets') !== undefined;
  }
  isContract(document: AnyDocument): document is Contract {
    return ((document as Contract).type === 'contracts') !== undefined;
  }
  isInvoice(document: AnyDocument): document is Invoice {
    return ((document as Invoice).type === 'invoices') !== undefined;
  }
  isPAECH(document: AnyDocument): document is PAECH {
    return ((document as PAECH).type === 'paech') !== undefined;
  }
  isPayroll(document: AnyDocument): document is Payroll {
    return ((document as Payroll).type === 'payrolls') !== undefined;
  }

  isValidCategory = (category: string): category is DocumentCategory => {
    return ALL_DOCUMENT_CATEGORIES.includes(category as DocumentCategory);
  };

  getCategoryTitle(category: DocumentCategory): string {
    type ObjectKey = keyof typeof DocumentCategoryTitles;
    const categoryKey = category as ObjectKey;
    const title = DocumentCategoryTitles[categoryKey];
    return title || 'Documentos';
  }
}
