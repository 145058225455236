import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  ForumSurvey,
  ForumSurveyResponse,
  SelectedUser,
  ServiceReceiver,
  User,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ForumSurveysService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  fetchSurveys(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<ForumSurvey[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<ForumSurveyResponse>(
        `${this.env.apiUrl}help/fetch-virtual-community-quizzes?${params}`
      )
      .pipe(
        map((response) => response.result),
        catchError((err) => throwError(() => err))
      );
  }

  markAnswer(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    questionId: string,
    questionAnswer: string
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
      'question-id': questionId,
      'question-answer': questionAnswer,
    };

    return this.http
      .post(
        `${this.env.apiUrl}help/save-virtual-community-quiz-answer`,
        paramsObj,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  fetchZaintzaQuiz(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<ForumSurvey[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<ForumSurveyResponse>(
        `${this.env.apiUrl}services/fetch-virtual-community-contests?${params}`
      )
      .pipe(
        map((response) => response.result),
        catchError((err) => throwError(() => err))
      );
  }

  markZaintzaQuizAnswer(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    questionId: string,
    questionAnswer: string
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
      'question-id': questionId,
      'question-answer': questionAnswer,
    };

    return this.http
      .post(
        `${this.env.apiUrl}services/save-virtual-community-contest-answer`,
        paramsObj,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }
}
