import { createReducer, on } from '@ngrx/store';
import {
  HomeAccessibilityResponse,
  RequestStatus,
} from '@nai-libs/data-access';
import { HomeAccessibility } from '@nai-libs/data-access';
import * as HomeAccessibilityActions from './home-accessibility.actions';

export const HOME_ACCESSIBILITY_REDUCER_KEY = 'home-accessibility';

// IMPROVE status property naming, not easy to understand
export interface HomeAccessibilityState {
  homeAccessibilityResults?: HomeAccessibilityResponse;
  homeAccessibilityLast?: Date;
  homeAccessibilityNotification?: string;
  saveReadHomeAccessibilityNotification?:boolean;
  selectedHomeAccessibilityDetail?: HomeAccessibility;
  homeAccessibilityStatus: RequestStatus;
  homeAccessibilityLastStatus: RequestStatus;
  homeAccessibilityDetailStatus: RequestStatus;
  homeAccessibilityNotificationStatus: RequestStatus;
}

const initalState: HomeAccessibilityState = {
  homeAccessibilityStatus: { pending: false },
  homeAccessibilityDetailStatus: { pending: false },
  homeAccessibilityLastStatus: { pending: false },
  homeAccessibilityNotificationStatus: { pending: false },
};

export const homeAccessibilityReducer = createReducer(
  initalState,

  on(
    HomeAccessibilityActions.loadHomeAccessibility,
    (state, { forceFetch }) => ({
      ...state,
      homeAccessibilityStatus: { pending: true },
    })
  ),
  on(
    HomeAccessibilityActions.loadHomeAccessibilitySuccess,
    (state, { homeAccessibilityResults }) => ({
      ...state,
      homeAccessibilityResults,
      homeAccessibilityStatus: { pending: false },
    })
  ),
  on(HomeAccessibilityActions.loadHomeAccessibilityFailure, (state) => ({
    ...state,
    homeAccessibilityResults: undefined,
    homeAccessibilityStatus: { pending: false },
  })),

  on(HomeAccessibilityActions.loadHomeAccessibilityLast, (state) => ({
    ...state,
    homeAccessibilityLastStatus: { pending: true },
  })),
  on(
    HomeAccessibilityActions.loadHomeAccessibilityLastSuccess,
    (state, { homeAccessibilityLast }) => ({
      ...state,
      homeAccessibilityLast: homeAccessibilityLast,
      homeAccessibilityLastStatus: { pending: false },
    })
  ),
  on(HomeAccessibilityActions.loadHomeAccessibilityLastFailure, (state) => ({
    ...state,
    homeAccessibilityLast: undefined,
    homeAccessibilityLastStatus: { pending: false },
  })),

  on(HomeAccessibilityActions.loadHomeAccessibilityNotification, (state) => ({
    ...state,
    homeAccessibilityNotificationStatus: { pending: true },
  })),

  on(
    HomeAccessibilityActions.loadHomeAccessibilityNotificationSuccess,
    (state, { homeAccessibilityNotification }) => ({
      ...state,
      homeAccessibilityNotification: homeAccessibilityNotification,
      homeAccessibilityNotificationStatus: { pending: false },
    })
  ),
  on(HomeAccessibilityActions.loadHomeAccessibilityNotificationFailure, (state) => ({
    ...state,
    homeAccessibilityNotification: undefined,
    homeAccessibilityNotificationStatus: { pending: false },
  })),

  on(HomeAccessibilityActions.saveReadHomeAccessibilityNotification, (state) => ({
    ...state,
    saveReadHomeAccessibilityNotificationStatus: { pending: true },
  })
  ),
  on(HomeAccessibilityActions.saveReadHomeAccessibilityNotificationSuccess, (state) => ({
    ...state,
    saveReadHomeAccessibilityNotificationStatus: { pending: false },
  })),
  on(HomeAccessibilityActions.saveReadHomeAccessibilityNotificationFailure, (state) => ({
    ...state,
    saveReadHomeAccessibilityNotificationStatus: { pending: false },
  }))
);
