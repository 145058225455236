
// DGI
export interface DGIResponse {
    DGI: {
        'answers-date': string;
    }[];
}

export interface LastDGIResponse {
    'last-DGI-date': string;
}

export interface DGIDocumentResponse {
    'pdf-link': string;
}

export interface DGIReadResponse {
    status: number;
}
export interface DGIResultArea {
    area: string;
    problem: string | string[];
    'background-color'?: string;
    'sub-caps'?: DGIResultArea[];
  }
  export interface DGIResult {
      title: string,
      slug: string,
      areas: DGIResultArea[]
  }
  export type DGIAreas =
    | 'informacion-administrativa'
    | 'funcional'
    | 'biofisica-sanitaria'
    | 'mental'
    | 'sociofamiliar';
  
  export interface DGIResultResponse {
    'DGI-results': DGIResult[];
  }
  
  
  export const DGIResultsIconsMap = {
    'informacion-administrativa': '/assets/icons/info.svg',
    funcional: '/assets/icons/person.svg',
    'biofisica-sanitaria': '/assets/icons/medical.svg',
    mental: '/assets/icons/psycology.svg',
    sociofamiliar: '/assets/icons/family.svg',
  };

  