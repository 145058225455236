import {
  ConsultHistory,
  ConsultOpeningRulesResponse,
  CustomerSupportProfessional,
  FaqTopic,
  NewVideoconsultation,
  RequestStatus,
  VideoconsultationAvailability,
} from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';

import * as ConsultationActions from './consultation.actions';

export const CONSULTATION_REDUCER_KEY = 'consultation';

export interface ConsultationState {
  customerSupportProfessionals?: CustomerSupportProfessional[];
  customerSupportProfessionalsStatus: RequestStatus;
  faq?: FaqTopic[];
  faqStatus: RequestStatus;
  consultRules?: ConsultOpeningRulesResponse;
  consultRulesStatus: RequestStatus;
  consultHistory?: ConsultHistory;
  consultHistoryStatus: RequestStatus;
  newConsultationStatus: RequestStatus;
  newResponseStatus: RequestStatus;
  markConsultationAsReadStatus: RequestStatus;
  videoconsultationAvailability?: VideoconsultationAvailability;
  videoconsultationAvailabilityStatus: RequestStatus;
  newVideoconsultation?: NewVideoconsultation;
  newVideoconsultationStatus: RequestStatus;
  sendConsultationEmailStatus: RequestStatus;
}

const initialState: ConsultationState = {
  customerSupportProfessionalsStatus: { pending: false },
  faqStatus: { pending: false },
  consultRulesStatus: { pending: false },
  consultHistoryStatus: { pending: false },
  newConsultationStatus: { pending: false },
  newResponseStatus: { pending: false },
  markConsultationAsReadStatus: { pending: false },
  videoconsultationAvailabilityStatus: { pending: false },
  newVideoconsultationStatus: { pending: false },
  sendConsultationEmailStatus: { pending: false },
};

export const consultationReducer = createReducer(
  initialState,
  on(ConsultationActions.getCustomerSupportProfessionals, (state) => ({
    ...state,
    customerSupportProfessionalsStatus: { pending: true },
  })),
  on(
    ConsultationActions.getCustomerSupportProfessionalsSuccess,
    (state, { customerSupportProfessionals }) => ({
      ...state,
      customerSupportProfessionalsStatus: { pending: false },
      customerSupportProfessionals,
    })
  ),
  on(ConsultationActions.getCustomerSupportProfessionalsFailure, (state) => ({
    ...state,
    customerSupportProfessionalsStatus: { pending: false },
  })),
  on(ConsultationActions.loadFaq, (state) => ({
    ...state,
    faqStatus: { pending: true },
  })),
  on(ConsultationActions.loadFaqSuccess, (state, { faq }) => ({
    ...state,
    faq,
    faqStatus: { pending: false },
  })),
  on(ConsultationActions.loadFaqFailure, (state) => ({
    ...state,
    faqStatus: { pending: false },
  })),

  on(ConsultationActions.loadConsultOpeningRules, (state) => ({
    ...state,
    consultRulesStatus: { pending: true },
  })),
  on(
    ConsultationActions.loadConsultOpeningRulesSuccess,
    (state, { consultRules }) => ({
      ...state,
      consultRules,
      consultRulesStatus: { pending: false },
    })
  ),
  on(ConsultationActions.loadConsultOpeningRulesFailure, (state) => ({
    ...state,
    consultRulesStatus: { pending: false },
  })),

  on(ConsultationActions.loadConsultHistory, (state) => ({
    ...state,
    consultHistoryStatus: { pending: true },
  })),
  on(
    ConsultationActions.loadConsultHistorySuccess,
    (state, { consultHistory }) => ({
      ...state,
      consultHistory,
      consultHistoryStatus: { pending: false },
    })
  ),
  on(ConsultationActions.loadConsultHistoryFailure, (state) => ({
    ...state,
    consultHistoryStatus: { pending: false },
  })),

  on(ConsultationActions.newConsultation, (state) => ({
    ...state,
    newConsultationStatus: { pending: true },
  })),
  on(ConsultationActions.newConsultationSuccess, (state) => ({
    ...state,
    newConsultationStatus: { pending: false },
  })),
  on(ConsultationActions.newConsultationFailure, (state) => ({
    ...state,
    newConsultationStatus: { pending: false },
  })),

  on(ConsultationActions.newResponse, (state) => ({
    ...state,
    newResponseStatus: { pending: true },
  })),
  on(ConsultationActions.newResponseSuccess, (state) => ({
    ...state,
    newResponseStatus: { pending: false },
  })),
  on(ConsultationActions.newResponseFailure, (state) => ({
    ...state,
    newResponseStatus: { pending: false },
  })),

  on(ConsultationActions.markConsultationAsRead, (state) => ({
    ...state,
    markConsultationAsReadStatus: { pending: true },
  })),
  on(ConsultationActions.markConsultationAsReadSuccess, (state) => ({
    ...state,
    markConsultationAsReadStatus: { pending: false },
  })),
  on(ConsultationActions.markConsultationAsReadFailure, (state) => ({
    ...state,
    markConsultationAsReadStatus: { pending: false },
  })),

  on(
    ConsultationActions.getVideoconsultationAvailability,
    (state, { lastAvailableDate }) => {
      if (lastAvailableDate) {
        return {
          ...state,
          videoconsultationAvailabilityStatus: { pending: true },
        };
      } else {
        return {
          ...state,
          videoconsultationAvailability: undefined,
          videoconsultationAvailabilityStatus: { pending: true },
        };
      }
    }
  ),
  on(
    ConsultationActions.getVideoconsultationAvailabilitySuccess,
    (state, { availability }) => {
      if (state.videoconsultationAvailability) {
        return {
          ...state,
          videoconsultationAvailability: {
            ...state.videoconsultationAvailability,
            availability:
              state.videoconsultationAvailability.availability.concat(
                availability.availability
              ),
          },
          videoconsultationAvailabilityStatus: { pending: false },
        };
      }
      return {
        ...state,
        videoconsultationAvailability: availability,
        videoconsultationAvailabilityStatus: { pending: false },
      };
    }
  ),
  on(ConsultationActions.getVideoconsultationAvailabilityFailure, (state) => ({
    ...state,
    videoconsultationAvailabilityStatus: { pending: false },
  })),

  on(ConsultationActions.newVideoconsultation, (state) => ({
    ...state,
    newVideoconsultationStatus: { pending: true },
  })),
  on(ConsultationActions.newVideoconsultationSuccess, (state) => ({
    ...state,
    newVideoconsultationStatus: { pending: false },
  })),
  on(ConsultationActions.newVideoconsultationFailure, (state) => ({
    ...state,
    newVideoconsultationStatus: { pending: false },
  })),
  on(ConsultationActions.resetNewVideoconsultation, (state) => ({
    ...state,
    newVideoconsultation: {
      reason: undefined,
      'start-date': undefined,
      'attendee-email': undefined,
      'attendee-phone': undefined,
      price: undefined,
    },
  })),
  on(ConsultationActions.setNewVideoconsultationDate, (state, { date }) => ({
    ...state,
    newVideoconsultation: {
      ...state.newVideoconsultation,
      'start-date': date,
    },
  })),
  on(ConsultationActions.setNewVideoconsultationPrice, (state, { price }) => ({
    ...state,
    newVideoconsultation: {
      ...state.newVideoconsultation,
      price,
    },
  })),
  on(
    ConsultationActions.setNewVideoconsultationReason,
    (state, { reason }) => ({
      ...state,
      newVideoconsultation: {
        ...state.newVideoconsultation,
        reason,
      },
    })
  ),
  on(
    ConsultationActions.setNewVideoconsultationData,
    (state, { attendeeEmail, attendeePhone }) => ({
      ...state,
      newVideoconsultation: {
        ...state.newVideoconsultation,
        'attendee-email': attendeeEmail,
        'attendee-phone': attendeePhone,
      },
    })
  ),

  on(ConsultationActions.sendConsultationEmail, (state) => ({
    ...state,
    sendConsultationEmailStatus: { pending: true },
  })),
  on(ConsultationActions.sendConsultationEmailSuccess, (state) => ({
    ...state,
    sendConsultationEmailStatus: { pending: false },
  })),
  on(ConsultationActions.sendConsultationEmailFailure, (state) => ({
    ...state,
    sendConsultationEmailStatus: { pending: false },
  })),

  on(ConsultationActions.restoreInitialState, () => initialState)
);
