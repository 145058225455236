import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ArticleState, ARTICLE_REDUCER_KEY } from './article.reducer';

export const selectArticleState = createFeatureSelector<ArticleState>(
  ARTICLE_REDUCER_KEY
);

export const selectArticleStatusPending = createSelector(
  selectArticleState,
  (state) => state.articlesStatus.pending
);
export const selectFormativeArticles = createSelector(
  selectArticleState,
  (state) => state.articles
);

export const selectFormativeAreas = createSelector(
  selectArticleState,
  (state) => state.formativeAreas
);
export const selectFormativeAreasPending = createSelector(
  selectArticleState,
  (state) => state.formativeAreasStatus.pending
);

export const getSelectedArea = createSelector(selectArticleState, (state) => state.selectedArea);
export const getSelectedArticle = createSelector(selectArticleState, (state) => state.selectedArticle);
export const getResources = createSelector(selectArticleState, (state) => state.resources);

export const selectGlossaryWordStatus = createSelector(selectArticleState, (state) => state.glossaryWordStatus);
export const selectGlossaryWord = createSelector(selectArticleState, (state) => state.glossaryWord);