import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ArticleEffects } from './+state/article.effects';
import { ARTICLE_REDUCER_KEY, articleReducer } from './+state/article.reducer';

@NgModule({
  imports: [CommonModule,
    StoreModule.forFeature(ARTICLE_REDUCER_KEY, articleReducer),
    EffectsModule.forFeature([ArticleEffects])
  ],
})
export class ArticleDataAccessModule {}
